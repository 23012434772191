import React from "react";
import { Link } from "react-router-dom";

export default function AccountSection() {
  return (
    <>
      <div class="panel panel-default account-list">
        <div class="panel-heading">Account</div>
        <div class="list-group">
          <Link
            to="/login"
            //   href="https://restosleep.in/index.php?route=account/login"
            class="list-group-item"
          >
            Login
          </Link>{" "}
          <Link
            to="/register"
            //   href="https://restosleep.in/index.php?route=account/register"
            class="list-group-item"
          >
            Register
          </Link>{" "}
          <Link to="/forgot-password" class="list-group-item">
            Forgotten Password
          </Link>
          <Link
            to="/accounts"
            // href="https://restosleep.in/index.php?route=account/account"
            class="list-group-item"
          >
            My Account
          </Link>
          <a
            // href="https://restosleep.in/index.php?route=account/address"
            class="list-group-item"
          >
            Address Book
          </a>{" "}
          <a
            // href="https://restosleep.in/index.php?route=account/wishlist"
            class="list-group-item"
          >
            Wish List
          </a>{" "}
          <a
            // href="https://restosleep.in/index.php?route=account/order"
            class="list-group-item"
          >
            Order History
          </a>{" "}
          <a
            // href="https://restosleep.in/index.php?route=account/download"
            class="list-group-item"
          >
            Downloads
          </a>
          <a
            // href="https://restosleep.in/index.php?route=account/recurring"
            class="list-group-item"
          >
            Recurring payments
          </a>{" "}
          <a
            // href="https://restosleep.in/index.php?route=account/reward"
            class="list-group-item"
          >
            Reward Points
          </a>{" "}
          <Link to="/return"
            // href="https://restosleep.in/index.php?route=account/return"
            class="list-group-item"
          >
            Returns
          </Link>{" "}
          <a
            // href="https://restosleep.in/index.php?route=account/transaction"
            class="list-group-item"
          >
            Transactions
          </a>{" "}
          <a
            // href="https://restosleep.in/index.php?route=account/newsletter"
            class="list-group-item"
          >
            Newsletter
          </a>
        </div>
      </div>
    </>
  );
}
