import React from 'react'
import CheckBox from './CheckBox'
import InputOption from './InputOption'
import RadioButtons from './RadioButtons'
import SelectDropDown from './SelectDropDown'
import TextArea from './TextArea'

export default function RenderOptions({ productOptions, selectedOption, setSelectedOption }) {
    const handleCheckbox = (value, optionDetails, options) => {
        const optionIndex = selectedOption.findIndex(option => option.optionId === options.id)
        const arrSelectedOption = [...selectedOption]
        if (optionIndex >= 0) {
            const checkedOptions = arrSelectedOption[optionIndex].productOptionDetailsId
            const changedOptionIndex = checkedOptions.findIndex(checked => checked.id === optionDetails.id)
            if (changedOptionIndex >= 0) {
                // checkedOptions[changedOptionIndex].isChecked = value
                checkedOptions.splice(changedOptionIndex, 1)
            } else {
                checkedOptions.push(optionDetails)
            }
            setSelectedOption(arrSelectedOption)
        } else {
            const data = {
                optionsId: options.id,
                option: options,
                productOptionDetailsId: optionDetails.id,
                optionDetails: [optionDetails],
                value,
                type: 3
            }
            arrSelectedOption.push(data)
            setSelectedOption(arrSelectedOption)
        }
    }

    const handleOptions = (value, optionDetails, options) => {
        const optionIndex = selectedOption.findIndex(option => option.optionsId === options.id)
        const arrSelectedOption = [...selectedOption]
        if (optionIndex >= 0) {
            arrSelectedOption[optionIndex].productOptionDetailsId = optionDetails
            arrSelectedOption[optionIndex].optionDetails = optionDetails
        } else {
            const data = {
                optionsId: options.id,
                option: options,
                productOptionDetailsId: optionDetails.id,
                optionDetails,
                value,
                type: 1
            }
            arrSelectedOption.push(data)
        }
        setSelectedOption(arrSelectedOption)
    }

    // const handleOptions = (value, optionDetails, options) => {
    //     const optionIndex = selectedOption.findIndex(option => option.optionsId === options.id)
    //     const arrSelectedOption = JSON.parse(JSON.stringify(selectedOption))
    //     if (optionIndex >= 0) {
    //         arrSelectedOption[optionIndex].productOptionDetailsId = optionDetails.id
    //         arrSelectedOption[optionIndex].optionDetails = optionDetails
    //     } else {
    //         const data = {
    //             optionsId: options.id,
    //             option: options,
    //             productOptionDetailsId: optionDetails.id,
    //             optionDetails,
    //             value,
    //             type: 1
    //         }
    //         arrSelectedOption.push(data)
    //     }
    //     setSelectedOption(arrSelectedOption)
    // }

    const handleInputs = (value, options) => {
        const optionIndex = selectedOption.findIndex(option => option.optionId === options.id)
        const arrSelectedOption = [...selectedOption]
        if (optionIndex >= 0) {
            arrSelectedOption[optionIndex].value = value
        } else {
            const data = {
                optionsId: options.id,
                option: options,
                // optionDetails,
                productOptionDetailsId: null,
                value,
                type: 2
            }
            arrSelectedOption.push(data)
        }
        setSelectedOption(arrSelectedOption)
    }

    return (
        <>
            {productOptions.map(option => {
                if (option.isDeleted) return null
                let component;
                if (option.options.type === 1) {
                    component = <CheckBox optionDetails={option.productOptionDetails} option={option.options} isRequired={option.isRequired} handleInput={handleCheckbox} />
                } else if (option.options.type === 2) {
                    component = <SelectDropDown key={option.id} optionDetails={option.productOptionDetails} isRequired={option.isRequired} option={option.options} handleInput={handleOptions} />
                } else if (option.options.type === 3) {
                    component = <RadioButtons isRequired={option.isRequired} optionDetails={option.productOptionDetails} option={option.options} handleInput={handleOptions} />
                } else if (option.options.type === 4) {
                    component = <InputOption isRequired={option.isRequired} type="text" option={option.options} handleInput={handleInputs} />
                } else if (option.options.type === 6) {
                    component = <InputOption isRequired={option.isRequired} type="file" option={option.options} handleInput={handleInputs} />
                } else if (option.options.type === 7) {
                    component = <InputOption isRequired={option.isRequired} type="time" option={option.options} handleInput={handleInputs} />
                } else if (option.options.type === 8) {
                    component = <InputOption isRequired={option.isRequired} type="date" option={option.options} handleInput={handleInputs} />
                } else if (option.options.type === 9) {
                    component = <InputOption isRequired={option.isRequired} type="datetime-local" option={option.options} handleInput={handleInputs} />
                } else if (option.options.type === 5) {
                    component = <TextArea option={option.options} isRequired={option.isRequired} handleInput={handleInputs} />
                }
                return component
            })}
        </>
    )
}
