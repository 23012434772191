// import React from "react";
// import { useNavigate } from "react-router";

// export default function Footer() {
//   const navigate = useNavigate();

//   const handleAboutUs = () => {
//     navigate("/about");
//   };
//   const handleTermCondition = () => {
//     navigate("/termCondition");
//   };

//   const handleContactUs = () => {
//     navigate("/contactUs");
//   };
//   const handleSiteMap = () => {
//     navigate("/sitemap");
//   };
//   const handleReturn = () => {
//     navigate("/return");
//   };
//   const handlePrivacyPolicy= () => {
//     navigate("/privacy-policy");
//   };
 
//   return (
//     <>
//       <footer>
//         <div className="footer-container">
//           <div className="container bottom-to-top hb-animate-element hb-in-viewport">
//             <div className="footer-column footer-left-cms col-xs-3">
//               <aside id="footer-left">
//                 <div className="html-content">
//                   <div className="box-content">
//                     <div className="contact-us">
//                       <h5 className="">Restosleep coir &amp; foam products</h5>
//                       <ul
//                         style={{ display: "block" }}
//                         className="list-unstyled"
//                       >
//                         <li className="contact-detail">
//                           <div className="data address">
//                             <i className="fa fa-map-marker"></i>
//                             <div className="contact-address">
//                               5th cross, Muneshwara nagar, Bangalore-560045,
//                               India
//                             </div>
//                           </div>
//                         </li>
//                         <li className="contact">
//                           <div className="data contact">
//                             <i className="fa fa-phone"></i>
//                             <span className="phone">
//                               <a href="#">08023551022</a>
//                             </span>
//                           </div>
//                         </li>
//                         <li className="fax">
//                           <div className="data fax">
//                             <i className="fa fa-phone"></i>
//                             <span className="phone">
//                               <a href="#">09945111022</a>
//                             </span>
//                           </div>
//                         </li>
//                         <li className="email">
//                           <div className="data email">
//                             <i className="fa fa-envelope"></i>
//                             <span className="email-address">
//                               <a href="mailto:support@restosleep.in">
//                                 support@restosleep.in
//                               </a>
//                             </span>
//                           </div>
//                         </li>
//                       </ul>
//                     </div>
//                   </div>
//                 </div>
//               </aside>
//             </div>
//             <div className="col-sm-3 footer-column footer-my-account">
//               <h5 className="">Information</h5>
//               <ul className="list-unstyled" style={{ display: "block" }}>
//                 <li>
//                   <a
//                     //    href="https://restosleep.in/index.php?route=information/information&amp;information_id=4"
//                     onClick={handleAboutUs}
//                   >
//                     About Us
//                   </a>
//                 </li>
//                 <li>
//                   <a 
//                   // href="https://restosleep.in/index.php?route=information/information&amp;information_id=3"
//                   onClick={handlePrivacyPolicy}
//                   >
//                     Privacy Policy
//                   </a>
//                 </li>
//                 <li>
//                   <a onClick={handleTermCondition}>Terms &amp; Conditions</a>
//                 </li>
//               </ul>
//             </div>
//             <div className="col-sm-2 footer-column footer-my-account">
//               <h5 className="">My Account</h5>
//               <ul className="list-unstyled" style={{ display: "block" }}>
//                 <li>
//                     My Account
//                   </a>
//                 </li>
//                 <li>
//                     Wish List
//                   </a>
//                 </li>
//                 <li>
//                   <a onClick={handleReturn}>
//                     Returns
//                   </a>
//                 </li>
//                 <li>
//                   <a
//                     // href="https://restosleep.in/index.php?route=information/sitemap"
//                     onClick={handleSiteMap}
//                   >
//                     Site Map
//                   </a>
//                 </li>
//                 <li>
//                   <a
//                     // href="https://restosleep.in/index.php?route=information/contact"
//                     onClick={handleContactUs}
//                   >
//                     Contact Us
//                   </a>
//                 </li>
//               </ul>
//             </div>
       
//             {/* <div className="footer-column footer-right-cms col-sm-4">
//                         <aside id="footer-right" >
//                             <div className="newletter-subscribe">
//                                 <div id="newletter-boxes" className="newletter-container">
//                                     <div id="dialog-normal" className="window">
//                                         <div className="box">
//                                             <div className="newletter-title col-sm-6"><h2 className="tt-title">Sign Up For Newsletter</h2>
//                                                 <span className="newletter-desc">LIpsum simple text Lorem text simpl Duis faucibus enim vitae nun</span></div>
//                                             <div className="box-content newleter-content col-sm-6">
//                                                 <label></label>
//                                                 <div id="form_subscribe">
//                                                     <form name="subscribe" id="subscribe">
//                                                         <input type="text" placeholder="Your email address" value="" name="subscribe_email" id="subscribe_email" />
//                                                         <input type="hidden" value="" name="subscribe_name" id="subscribe_name" />
//                                                         <a className="button btn btn-primary" onclick="email_subscribe()"><span>subscribe</span><i className="fa fa-paper-plane"></i></a>
//                                                     </form>
//                                                 </div>
//                                                 <div id="notification-normal"></div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="html-content">  <div className="box-content"><div className="box-content newleter-content">
//                                 <img className="" src="https://restosleep.in/image/paypal.png" alt="Payment Gateway" style={{ width: "264.344px", height: "67.5665px" }} />
//                             </div></div></div>
//                         </aside>
//                     </div> */}
//           </div>
//         </div>
//         <div className="bottom-footer">
//           <div className="container bottom-to-top hb-animate-element hb-in-viewport">
//             <div className="footer-bottom col-sm-5">
//               <p>
//                 Powered By{" "}
//                 <a href="http://www.salaftechnocrats.com">Salaf Technocrats</a>{" "}
//                 Rest O sleep © 2022
//               </p>
//             </div>
//             <div className="block-social col-sm-7">
//               <div className="footer-bottom-cms">
//                 <aside id="footer-bottom">
//                   <div className="html-content">
//                     <div className="box-content">
//                       <p>
//                         <img
//                           src="https://restosleep.in/image/catalog/card.png"
//                           style={{ width: "425px", float: "right" }}
//                         />
//                         <br />
//                       </p>
//                     </div>
//                   </div>
//                 </aside>
//               </div>
//             </div>
//           </div>
//         </div>

//       </footer>
//     </>
//   );
// }


import React from "react";
import { useNavigate } from "react-router";

export default function Footer() {
  const navigate = useNavigate();

  const handleAboutUs = () => {
    navigate("/about");
  };
  const handleTermCondition = () => {
    navigate("/termCondition");
  };

  const handleContactUs = () => {
    navigate("/contactUs");
  };
  const handleSiteMap = () => {
    navigate("/sitemap");
  };
  const handleReturn = () => {
    navigate("/return");
  };
  const handlePrivacy = () => {
    navigate("/privacy");
  };
  return (
    <>
      <footer>
        <div className="footer-container">
          <div className="container bottom-to-top hb-animate-element hb-in-viewport">
            <div className="footer-column footer-left-cms col-xs-3">
              <aside id="footer-left">
                <div className="html-content">
                  <div className="box-content">
                    <div className="contact-us">
                      <h5 className="">Restosleep coir &amp; foam products</h5>
                      <ul
                        style={{ display: "block" }}
                        className="list-unstyled"
                      >
                        <li className="contact-detail">
                          <div className="data address">
                            <i className="fa fa-map-marker"></i>
                            <div className="contact-address">
                              5th cross, Muneshwara nagar, Bangalore-560045,
                              India
                            </div>
                          </div>
                        </li>
                        <li className="contact">
                          <div className="data contact">
                            <i className="fa fa-phone"></i>
                            <span className="phone">
                              <a href="#">08023551022</a>
                            </span>
                          </div>
                        </li>
                        <li className="fax">
                          <div className="data fax">
                            <i className="fa fa-phone"></i>
                            <span className="phone">
                              <a href="#">09945111022</a>
                            </span>
                          </div>
                        </li>
                        <li className="email">
                          <div className="data email">
                            <i className="fa fa-envelope"></i>
                            <span className="email-address">
                              <a href="mailto:support@restosleep.in">
                                support@restosleep.in
                              </a>
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
            <div className="col-sm-3 footer-column footer-my-account">
              <h5 className="">Information</h5>
              <ul className="list-unstyled" style={{ display: "block" }}>
                <li>
                  <a
                    //    href="https://restosleep.in/index.php?route=information/information&amp;information_id=4"
                    onClick={handleAboutUs}
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    onClick={handlePrivacy}
                    // href="https://restosleep.in/index.php?route=information/information&amp;information_id=3"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a onClick={handleTermCondition}>Terms &amp; Conditions</a>
                </li>
              </ul>
            </div>
            <div className="col-sm-2 footer-column footer-my-account">
              <h5 className="">My Account</h5>
              <ul className="list-unstyled" style={{ display: "block" }}>
                <li>
                  <a href="#">
                    My Account
                  </a>
                </li>
                <li>
                  <a href="#">
                    Wish List
                  </a>
                </li>
                <li>
                  <a onClick={handleReturn}>Returns</a>
                </li>
                <li>
                  <a
                    // href="https://restosleep.in/index.php?route=information/sitemap"
                    onClick={handleSiteMap}
                  >
                    Site Map
                  </a>
                </li>
                <li>
                  <a
                    // href="https://restosleep.in/index.php?route=information/contact"
                    onClick={handleContactUs}
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-sm-4 footer-column footer-my-account">
              <h5 className="">Sign Up For Newsletter</h5>
              <form name="subscribe" id="subscribe">
                <div style={{ display: 'flex', alignItems: 'center' }}>
      <input
        type="text"
        placeholder="Your email address"
        value=""
        name="subscribe_email"
        id="subscribe_email"
        style={{
          border: "none",
          borderBottom: "1px solid #ccc",
          padding: "5px",
          width: "calc(100% - 80px)", // Adjust width to accommodate button
          boxSizing: "border-box",
          background: "transparent",
          marginRight: "10px", // Add some spacing between input and button
          color: "white", // Set color of input text to white
        }}
      />
      <button style={{
        backgroundColor: "red",
        color: "white",
        border: "none",
        padding: "10px 20px",
        cursor: "pointer"
      }}>Subscribe</button>
    </div>
              </form>
              <div className="html-content">
                {" "}
                <div className="box-content">
                  <div
                    className="box-content newleter-content"
                    style={{ paddingTop: "10%" }}
                  >
                    <img
                      className=""
                      src="/assets/images/paypal.png"
                      alt="Payment Gateway"
                      style={{ width: "264.344px", height: "67.5665px" }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="footer-column footer-right-cms col-sm-4">
              <aside id="footer-right">
                <div className="newletter-subscribe">
                  <div id="newletter-boxes" className="newletter-container">
                    <div id="dialog-normal" className="window">
                      <div className="box-content newleter-content col-sm-6">
                        <label></label>
                        <div id="form_subscribe">
                          <div
                            className="footer-column footer-my-account"
                            style={{ color: "white" }}
                          >
                            <h2 className="tt-title">Sign Up For Newsletter</h2>
                            <form name="subscribe" id="subscribe">
                              <input
                                type="text"
                                placeholder="Your email address"
                                value=""
                                name="subscribe_email"
                              />
                              <a
                                className="button btn btn-primary"
                                onclick="email_subscribe()"
                              >
                                <span>subscribe</span>
                                <i className="fa fa-paper-plane"></i>
                              </a>
                            </form>
                          </div>
                          <div id="notification-normal"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="html-content">
                  {" "}
                  <div className="box-content">
                    <div className="box-content newleter-content">
                      <img
                        className=""
                        src="https://restosleep.in/image/paypal.png"
                        alt="Payment Gateway"
                        style={{ width: "264.344px", height: "67.5665px" }}
                      />
                    </div>
                  </div>
                </div>
              </aside>
            </div> */}
          </div>
        </div>
        <div className="bottom-footer">
          <div className="container bottom-to-top hb-animate-element hb-in-viewport">
            <div className="footer-bottom col-sm-5">
              <p>
                Powered By{" "}
                <a href="http://www.salaftechnocrats.com" target="_blank">Salaf Technocrats</a>{" "}
                Rest O sleep © 2022
              </p>
            </div>
            <div className="block-social col-sm-7">
              <div className="footer-bottom-cms">
                <aside id="footer-bottom">
                  <div className="html-content">
                    <div className="box-content">
                      <p>
                        <img
                          src="/assets/images/card.png"
                          style={{ width: "425px", float: "right" }}
                        />
                        <br />
                      </p>
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
