import React from 'react'
import { Col, Form } from 'react-bootstrap'

export default function RadioButtons({ optionDetails, option, isRequired, handleInput }) {
    const handleRadio = (e) => {
        const optionChanges = optionDetails.find(opt => opt.id === +e.target.value)
        handleInput(e.target.checked, optionChanges, option)
    }
    return (
        <>
            <Col sm="auto" xs="12" className="mb-3 me-1">
                <p className="fw-bold form-p">{option.name}</p>
                <div className="pt-1">
                    {/* <Form.Check type="radio" label="Red" id="colorOption1" inline name="colorOption" />
                    <Form.Check type="radio" label="Blue" id="colorOption2" inline name="colorOption" /> */}
                    {/* {optionDetails.map(detail => <Form.Check key={detail.id} type="radio" label={detail.optionValue} inline name={option.name} required={isRequired} />)} */}
                    <div className="mb-3">
                        <Form.Check type="radio" inline>
                            {optionDetails.map((detail) => {
                                if (detail.isDeleted) return null
                                return (
                                    <>
                                        <Form.Check.Input className='me-2' key={detail.id} type="radio" required={isRequired} name={option.name} label={detail.optionValue} value={detail.id} onChange={handleRadio} />
                                        <Form.Check.Label className='me-2' >{detail.optionValue}</Form.Check.Label>
                                    </>
                                )
                            })}
                            {/* <Form.Control.Feedback type="invalid">
                                Please select one option
                            </Form.Control.Feedback> */}
                            <div class="invalid-feedback">Please select {option.name}</div>
                        </Form.Check>
                    </div>
                    {/* <Form.Control.Feedback type='invalid'>Please select one option</Form.Control.Feedback> */}
                </div>
            </Col>
        </>
    )
}
