import React from "react";
import { useNavigate } from "react-router";

const AboutUs = () => {
  const navigate = useNavigate();

  
  const handleHome = () => {
    navigate("/");
  };
  return (
    <>
      <div class="header-content-title">
        <div class="page-title-wrapper">
          <div class="container">
            <div class="breadcrub">
              <h1 class="category-name page-title">about us</h1>
              <ul class="breadcrumb">
                <li>
                  <a onClick={handleHome} style={{cursor:'pointer'}}>
                    <i class="fa fa-home"></i>
                  </a>
                </li>
                <li>
                  <a class="last-breadcrumb">about us</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div id="information-information" class="container">
        <div class="row">
          <div id="content" class="col-sm-12 Cwidth" style={{ width: "100%" }}>
            <div id="ttcmsaboutus">
              <div class="ttaboutus">
                <div class="container">
                  <div class="row">
                    <div class="about-left about-block col-xs-12 col-xs-12">
                      <div class="about-content col-xs-12 col-xs-12 right-to-left hb-animate-element hb-in-viewport">
                        <div class="box-heading">
                          <h3
                            style={{
                              color: "#111",
                              fontWeight: 500,
                              fontSize: "40px",
                              lineHeight: "44px",
                            }}
                          >
                            About Us
                          </h3>
                        </div>
                        <div class="img-desc">
                          <p style={{ fontSize: "larger", lineHeight: 2 }}>
                            Rest O Sleep is an ingeniously built product from a
                            reputed mattress manufacturing company since early
                            90's. Our product is backed by the state of art
                            manufacturing technology, scientific research and
                            cutting-edge innovation to ensure your body receives
                            the right support and comfort to enjoy optimum sleep
                            benefits.
                          </p>
                          <p style={{ fontSize: "larger", lineHeight: 2 }}>
                            The mattress is indigenously crafted in our own
                            manufacturing units with high-quality control
                            measures at every stage with better raw materials,
                            to offer you a highly competitive product that meets
                            the top brand standard and offers the best value for
                            money. This direct factory sale product helps save
                            nearly 50% of the cost when compared to some
                            top-notch brands in the market.
                          </p>
                          <p style={{ fontSize: "larger", lineHeight: 2 }}>
                            Rest O Sleep range of products ensures relief
                            measures too, especially for the neck, spine and
                            back. Our diversified offering includes mattresses,
                            pillows and mats across different materials that
                            cater to specific needs. Our mattresses are
                            available in PU foam, Orthopaedic Memory Foam Dual
                            Comfort Mattress, Hybrid Bonnel spring, Rubberized
                            Coir, High-Density Foam to meet your every need.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="goToTop" title="Top" class="goToTop ttbox-img" style={{display: "block"}}></div>
    </>
  );
};

export default AboutUs;
