import React from "react";
import { Link } from "react-router-dom";
import AccountSection from "../../components/AccountSection";

export default function ForgotPassword() {
  return (
    <>
      <div class="header-content-title">
        <div class="page-title-wrapper">
          <div class="container">
            <div class="breadcrub">
              <h1 class="page-title">Account</h1>
              <ul class="breadcrumb">
                <li>
                  <Link to="/">
                    <i class="fa fa-home"></i>
                  </Link>
                </li>
                <li>
                  <Link to="/accounts">Account</Link>
                </li>
                <li>
                  <a class="last-breadcrumb">Forgotten Password</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div id="account-forgotten" class="container">
        <div class="row">
          <div id="content" class="col-sm-12 Cwidth" style={{ width: "100%" }}>
            <p>
              Enter the e-mail address associated with your account. Click
              submit to have a password reset link e-mailed to you.
            </p>
            <form
              action="https://restosleep.in/index.php?route=account/forgotten"
              method="post"
              enctype="multipart/form-data"
              class="form-horizontal"
            >
              <fieldset>
                <legend>Your E-Mail Address</legend>
                <div class="form-group required">
                  <label class="col-sm-2 control-label" for="input-email">
                    Your email address
                  </label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      name="email"
                      value=""
                      placeholder="Your email address"
                      id="input-email"
                      class="form-control"
                    />
                  </div>
                </div>
              </fieldset>
              <div class="buttons clearfix">
                <div class="pull-left">
                  <a
                    href="https://restosleep.in/index.php?route=account/login"
                    class="btn btn-default"
                  >
                    Back
                  </a>
                </div>
                <div class="pull-right">
                  <input
                    type="submit"
                    value="Continue"
                    class="btn btn-primary"
                  />
                </div>
              </div>
            </form>
            {/* <div class="panel panel-default account-list">
              <div class="panel-heading">Account</div>
              <div class="list-group">
                <a
                  href="https://restosleep.in/index.php?route=account/login"
                  class="list-group-item"
                >
                  Login
                </a>{" "}
                <a
                  href="https://restosleep.in/index.php?route=account/register"
                  class="list-group-item"
                >
                  Register
                </a>{" "}
                <a
                  href="https://restosleep.in/index.php?route=account/forgotten"
                  class="list-group-item"
                >
                  Forgotten Password
                </a>
                <a
                  href="https://restosleep.in/index.php?route=account/account"
                  class="list-group-item"
                >
                  My Account
                </a>
                <a
                  href="https://restosleep.in/index.php?route=account/address"
                  class="list-group-item"
                >
                  Address Book
                </a>{" "}
                <a
                  href="https://restosleep.in/index.php?route=account/wishlist"
                  class="list-group-item"
                >
                  Wish List
                </a>{" "}
                <a
                  href="https://restosleep.in/index.php?route=account/order"
                  class="list-group-item"
                >
                  Order History
                </a>{" "}
                <a
                  href="https://restosleep.in/index.php?route=account/download"
                  class="list-group-item"
                >
                  Downloads
                </a>
                <a
                  href="https://restosleep.in/index.php?route=account/recurring"
                  class="list-group-item"
                >
                  Recurring payments
                </a>{" "}
                <a
                  href="https://restosleep.in/index.php?route=account/reward"
                  class="list-group-item"
                >
                  Reward Points
                </a>{" "}
                <a
                  href="https://restosleep.in/index.php?route=account/return"
                  class="list-group-item"
                >
                  Returns
                </a>{" "}
                <a
                  href="https://restosleep.in/index.php?route=account/transaction"
                  class="list-group-item"
                >
                  Transactions
                </a>{" "}
                <a
                  href="https://restosleep.in/index.php?route=account/newsletter"
                  class="list-group-item"
                >
                  Newsletter
                </a>
              </div>
            </div> */}
            <AccountSection/>
          </div>
        </div>
      </div>
    </>
  );
}
