import React from "react";
import { useNavigate } from "react-router";

export default function Accessories() {
  const navigate = useNavigate();
  const handleHome = () => {
    navigate("/");
  };
  const handleRetroLite=()=>{
navigate('/retro-lite')
  }
  return (
    <>
      <div class="header-content-title">
        <div class="page-title-wrapper">
          <div class="container">
            <div class="breadcrub">
              <h1 class="category-name page-title">Accessories</h1>
              <ul class="breadcrumb">
                <li>
                  <a onClick={handleHome} style={{ cursor: "pointer" }}>
                    <i class="fa fa-home"></i>
                  </a>
                </li>
                <li>
                  <a class="last-breadcrumb">Accessories</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div id="product-category" class="container product-category">
        <div class="content_wrap">
          <div id="content" class="col-sm-12 Cwidth" style={{ width: "100%" }}>
            {/* <!-- Category Description START --> */}

            <div class="category-description">
              <div class="row">
                <div class="block-description col-sm-12">
                  {" "}
                  <div class="category-content">
                    <p>
                      <br />
                    </p>
                  </div>
                  {/* <!-- Category listing START -->
                        <!-- Category listing END --> */}
                  <p class="category-compare">
                    <a
                      href="https://restosleep.in/index.php?route=product/compare"
                      id="compare-total"
                    >
                      Product Compare (0)
                    </a>
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- Category Description END -->
            <!-- Category filter START --> */}
            <div class="category-filter">
              {/* <!-- Grid-List Buttons --> */}
              <div class="col-md-2 filter-grid-list">
                <div class="btn-group">
                  <button
                    type="button"
                    id="grid-view"
                    class="btn btn-default"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Grid"
                  >
                    <i class="fa fa-th"></i>
                  </button>
                  <button
                    type="button"
                    id="list-view"
                    class="btn btn-default active"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="List"
                  >
                    <i class="fa fa-th-list"></i>
                  </button>
                </div>
              </div>
              {/* <!-- Show Products Selection --> */}
              <div class="filter-show">
                <div class="col-md-4 text-right filter-text">
                  <label
                    class="input-group-addon control-label"
                    for="input-limit"
                  >
                    Show:
                  </label>
                </div>
                <div class="col-md-8 text-right filter-selection">
                  <div class="select-wrapper">
                    <select
                      id="input-limit"
                      class="form-control"
                      onchange="location = this.value;"
                    >
                      <option
                        value="https://restosleep.in/index.php?route=product/category&amp;path=67&amp;limit=15"
                        selected="selected"
                      >
                        15
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=67&amp;limit=25">
                        25
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=67&amp;limit=50">
                        50
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=67&amp;limit=75">
                        75
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=67&amp;limit=100">
                        100
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              {/* <!-- Sort By Selection --> */}
              <div class="filter-sort-by">
                <div class="col-md-3 text-right filter-text">
                  <label
                    class="input-group-addon control-label"
                    for="input-sort"
                  >
                    Sort By:
                  </label>
                </div>
                <div class="col-md-9 text-right filter-selection">
                  <div class="select-wrapper">
                    <select
                      id="input-sort"
                      class="form-control"
                      onchange="location = this.value;"
                    >
                      <option
                        value="https://restosleep.in/index.php?route=product/category&amp;path=67&amp;sort=p.sort_order&amp;order=ASC"
                        selected="selected"
                      >
                        Default
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=67&amp;sort=pd.name&amp;order=ASC">
                        Name (A - Z)
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=67&amp;sort=pd.name&amp;order=DESC">
                        Name (Z - A)
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=67&amp;sort=p.price&amp;order=ASC">
                        Price (Low &gt; High)
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=67&amp;sort=p.price&amp;order=DESC">
                        Price (High &gt; Low)
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=67&amp;sort=rating&amp;order=DESC">
                        Rating (Highest)
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=67&amp;sort=rating&amp;order=ASC">
                        Rating (Lowest)
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=67&amp;sort=p.model&amp;order=ASC">
                        Model (A - Z)
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=67&amp;sort=p.model&amp;order=DESC">
                        Model (Z - A)
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Category filter END -->
      <!-- Category products START --> */}
            <div class="category-products">
              <div class="row" onClick={handleRetroLite}>
                {" "}
                <div class="product-layout product-list col-xs-12">
                  <div class="product-thumb row">
                    <div class="image col-xs-5 col-sm-4 col-md-4">
                      {" "}
                      <a >
                        {" "}
                        <img
                          class="image_thumb"
                          src="/assets/Acc one.jpg"
                          title="resto lite"
                          alt="resto lite"
                        />{" "}
                        <img
                          class="image_thumb_swap"
                          src="/assets/three.jpg"
                          title="resto lite"
                          alt="resto lite"
                        />{" "}
                      </a>
                    </div>
                    <div class="thumb-description col-xs-7 col-sm-8 col-md-8">
                      <div class="caption">
                        <h4>
                          <a >
                            resto lite
                          </a>
                        </h4>
                        <p class="description">
                          Restolite multi-purpose, dual-sided slim mattress can
                          be used as a place to unwind with a book, to m..
                        </p>
                        <div class="price">
                          {" "}
                          Rs 1,049.00
                          <span class="price-tax">
                            Ex Tax: Rs 1,049.00
                          </span>{" "}
                        </div>
                      </div>
                      <div class="button-group">
                        <button
                          class="btn-cart "
                          type="button"
                          title="Add to Cart"
                          onclick="cart.add(&#39;54&#39;)"
                        >
                          <i class="fa fa-shopping-cart"></i>{" "}
                          <span class="hidden-xs hidden-sm hidden-md">
                            Add to Cart
                          </span>
                        </button>
                        <button
                          class="btn-wishlist"
                          title="Add to Wish List"
                          // onclick="wishlist.add(&#39;54&#39;);"
                        >
                          <i class="fa fa-heart"></i>{" "}
                          <span title="Add to Wish List">Add to Wish List</span>{" "}
                        </button>
                        <button
                          class="btn-compare"
                          title="Compare this Product"
                          // onclick="compare.add(&#39;54&#39;);"
                        >
                          <i class="fa fa-exchange"></i>{" "}
                          <span title="Compare this Product">
                            Compare this Product
                          </span>{" "}
                        </button>
                        <button
                          class="btn-quickview"
                          type="button"
                          title=""
                          // onclick="tt_quickview.ajaxView(&#39;https://restosleep.in/index.php?route=product/product&amp;path=67&amp;product_id=54&#39;)"
                        >
                          <i class="fa fa-eye"></i> <span title=""></span>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="product-layout product-list col-xs-12">
                  <div class="product-thumb row">
                    <div class="image col-xs-5 col-sm-4 col-md-4">
                      {" "}
                      <a >
                        {" "}
                        <img
                          class="image_thumb"
                          src="/assets/Acc two.jpg"
                          title="tranquil"
                          alt="tranquil"
                        />{" "}
                        <img
                          class="image_thumb_swap"
                          src="/assets/Acc three.jpg"
                          title="tranquil"
                          alt="tranquil"
                        />{" "}
                      </a>
                    </div>
                    <div class="thumb-description col-xs-7 col-sm-8 col-md-8">
                      <div class="caption">
                        <h4>
                          <a >
                            tranquil
                          </a>
                        </h4>
                        <p class="description">tranquil..</p>
                        <div class="price">
                          {" "}
                          Rs 699.00
                          <span class="price-tax">Ex Tax: Rs 699.00</span>{" "}
                        </div>
                      </div>
                      <div class="button-group">
                        <button
                          class="btn-cart "
                          type="button"
                          title="Add to Cart"
                          onclick="cart.add(&#39;53&#39;)"
                        >
                          <i class="fa fa-shopping-cart"></i>{" "}
                          <span class="hidden-xs hidden-sm hidden-md">
                            Add to Cart
                          </span>
                        </button>
                        <button
                          class="btn-wishlist"
                          title="Add to Wish List"
                          onclick="wishlist.add(&#39;53&#39;);"
                        >
                          <i class="fa fa-heart"></i>{" "}
                          <span title="Add to Wish List">Add to Wish List</span>{" "}
                        </button>
                        <button
                          class="btn-compare"
                          title="Compare this Product"
                          onclick="compare.add(&#39;53&#39;);"
                        >
                          <i class="fa fa-exchange"></i>{" "}
                          <span title="Compare this Product">
                            Compare this Product
                          </span>{" "}
                        </button>
                        <button
                          class="btn-quickview"
                          type="button"
                          title=""
                          // onclick="tt_quickview.ajaxView(&#39;https://restosleep.in/index.php?route=product/product&amp;path=67&amp;product_id=53&#39;)"
                        >
                          <i class="fa fa-eye"></i> <span title=""></span>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="product-layout product-list col-xs-12">
                  <div class="product-thumb row">
                    <div class="image col-xs-5 col-sm-4 col-md-4">
                      {" "}
                      <a>
                        {" "}
                        <img
                          class="image_thumb"
                          src="/assets/Acc one.jpg"
                          title="Wedge Pillow"
                          alt="Wedge Pillow"
                        />{" "}
                        <img
                          class="image_thumb_swap"
                          src="/assets/six.jpg"
                          title="Wedge Pillow"
                          alt="Wedge Pillow"
                        />{" "}
                      </a>
                    </div>
                    <div class="thumb-description col-xs-7 col-sm-8 col-md-8">
                      <div class="caption">
                        <h4>
                          <a>
                            Wedge Pillow
                          </a>
                        </h4>
                        <p class="description">
                          Wedge PillowPackage Contents: 1 PillowProduct
                          Dimension: Size: 22 inch x 22 inch x 12 inch or 56 cm
                          ..
                        </p>
                        <div class="price">
                          {" "}
                          Rs 1,899.00
                          <span class="price-tax">
                            Ex Tax: Rs 1,899.00
                          </span>{" "}
                        </div>
                      </div>
                      <div class="button-group">
                        <button
                          class="btn-cart "
                          type="button"
                          title="Add to Cart"
                          onclick="cart.add(&#39;56&#39;)"
                        >
                          <i class="fa fa-shopping-cart"></i>{" "}
                          <span class="hidden-xs hidden-sm hidden-md">
                            Add to Cart
                          </span>
                        </button>
                        <button
                          class="btn-wishlist"
                          title="Add to Wish List"
                          onclick="wishlist.add(&#39;56&#39;);"
                        >
                          <i class="fa fa-heart"></i>{" "}
                          <span title="Add to Wish List">Add to Wish List</span>{" "}
                        </button>
                        <button
                          class="btn-compare"
                          title="Compare this Product"
                          onclick="compare.add(&#39;56&#39;);"
                        >
                          <i class="fa fa-exchange"></i>{" "}
                          <span title="Compare this Product">
                            Compare this Product
                          </span>{" "}
                        </button>
                        <button
                          class="btn-quickview"
                          type="button"
                          title=""
                          // onclick="tt_quickview.ajaxView(&#39;https://restosleep.in/index.php?route=product/product&amp;path=67&amp;product_id=56&#39;)"
                        >
                          <i class="fa fa-eye"></i> <span title=""></span>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <script>
			  jQuery(document).ready(function($){
			  $(".item-countdown").each(function(){
			  var date = $(this).data('date');
			  $(this).lofCountDown({
				  TargetDate:date,
				  DisplayFormat:"<div>%%D%% <span>text_days</span></div><div>%%H%% <span>text_hours</span></div><div>%%M%% <span>text_minutes</span></div><div>%%S%% <span>text_seconds</span></div>"
				});
			  });
			  });   
		</script> */}
              </div>
            </div>
            {/* <!-- Category products END -->
      <!-- Category pagination START --> */}
            <div class="category-pagination">
              <div class="col-xs-6 text-left">
                Showing 1 to 3 of 3 (1 Pages)
              </div>
              <div class="col-xs-6 text-right"></div>
            </div>
            {/* <!-- Category pagination END --> */}
          </div>
        </div>
      </div>
      <div id="goToTop" title="Top" class="goToTop ttbox-img" style={{display: "block"}}></div>
    </>
  );
}
