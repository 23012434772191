import React, { useEffect, useMemo, useState } from 'react'
import axios from '../../assets/axios/axios';
import { useSelector } from 'react-redux';
import StoresApi from '../../assets/api/StoreApis';
import ProductBlock from '../../components/ProductBlock';
import OwlCarousel from 'react-owl-carousel2'

export default function Modules() {
    const store = useSelector(state => state.storeFront.store)

    const [modules, setModules] = useState([])
    const [width, setWidth] = useState(window.innerWidth);

    const getModules = () => {
        axios.get(`${StoresApi.StoreModules}/GetStoreFrontModules/${store.id}`)
            .then(response => {
                setModules(response.data.data)
            })
            .catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        if (store.id) {
            getModules()
        }
    }, [store])

    useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
    }, []);

    const options = useMemo(() => {
        const options = {   // These are the defaults.  
            items: 6,
            rewind: false,
            autoplay: false,
            dots: false,
            margin: 10,
            navText: [<button type="button" role="presentation" class="owl-next">Next<span aria-label="Next">›</span></button>, <button type="button" role="presentation" class="owl-prev disabled">Prev<span aria-label="Previous">‹</span></button>,]
        }
        if (width < 400) {
            options.items = 1;
        } else if (width < 768) {
            options.items = 2;
        }
        else if (width < 992) {
            options.items = 3;
        }
        else if (width < 1200) {
            options.items = 5;
        }
        else {
            options.items = 6;
        }
        return options;
    }, [width])
    console.log(modules);
    return (
        <>
            {modules.map(module => {
                if (!module.useModule) return null;
                return (
                    <div class="container w-95">
                        <div class="ttproduct-main bottom-to-top hb-animate-element hb-in-viewport">
                            <div class="container">
                                <div class="row main-tab">
                                    <div class="TTProduct-Tab TT-product-carousel products-list">
                                        <div class="box-heading tthometab-title">
                                            <h3>{module.name}</h3>
                                        </div>
                                        <div id="TTPTab-0" class="tab-box-heading">
                                            <ul class="nav nav-tabs">
                                                {" "}
                                                <li class="active">
                                                    <a href="#tab-latest-0" data-toggle="tab">
                                                        <h3 class="tab-title">Latest</h3>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div >
                            {/* <OwlCarousel options={options} className="row"> */}
                            {module.moduleDetails.map((prod, ind) => {
                                if (ind + 1 <= module.itemsToShow) {
                                return <ProductBlock product={prod.products} />
                                }
                            })}
                            {/* </OwlCarousel> */}
                        </div>
                    </div>
                )
            })}

        </>
    )
}
