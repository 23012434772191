import React from "react";

export default function HomeTwo() {
  return (
    <>
      <div id="page">
        <div class="header-content-title"></div>

        <div class="content-top">
          <div class="slideshow-panel">
            <div
              class="ttloading-bg ttloader"
              style={{ display: "none" }}
            ></div>
            <div class="swiper-viewport">
              <div
                id="slideshow0"
                class="slideshow-main swiper-container swiper-container-horizontal swiper-container-android"
              >
                <div
                  class="swiper-wrapper"
                  style={{
                    transform: "translate3d(-2410px, 0px, 0px)",
                    transitionDuration: "300ms",
                  }}
                >
                  <div
                    class="swiper-slide text-center swiper-slide-duplicate swiper-slide-next swiper-slide-duplicate-prev"
                    data-swiper-slide-index="3"
                    style={{ width: "482px" }}
                  >
                    {" "}
                    <img
                      src="./Rest O sleep_files/05-1903x950.jpg"
                      alt="Slide1"
                      class="img-responsive"
                    />{" "}
                  </div>{" "}
                  <div
                    class="swiper-slide text-center swiper-slide-duplicate-active"
                    data-swiper-slide-index="0"
                    style={{ width: "482px" }}
                  >
                    {" "}
                    <img
                      src="./Rest O sleep_files/01-1903x950.jpg"
                      alt="Slider5"
                      class="img-responsive"
                    />{" "}
                  </div>{" "}
                  <div
                    class="swiper-slide text-center"
                    data-swiper-slide-index="1"
                    style={{ width: "482px" }}
                  >
                    {" "}
                    <img
                      src="./Rest O sleep_files/02-1903x950.jpg"
                      alt="Slider4"
                      class="img-responsive"
                    />{" "}
                  </div>{" "}
                  <div
                    class="swiper-slide text-center"
                    data-swiper-slide-index="2"
                    style={{ width: "482px" }}
                  >
                    {" "}
                    <img
                      src="./Rest O sleep_files/03-1903x950.jpg"
                      alt="Slider3"
                      class="img-responsive"
                    />{" "}
                  </div>{" "}
                  <div
                    class="swiper-slide text-center swiper-slide-prev swiper-slide-duplicate-next"
                    data-swiper-slide-index="3"
                    style={{ width: "482px" }}
                  >
                    {" "}
                    <img
                      src="./Rest O sleep_files/05-1903x950.jpg"
                      alt="Slide1"
                      class="img-responsive"
                    />{" "}
                  </div>{" "}
                  <div
                    class="swiper-slide text-center swiper-slide-duplicate swiper-slide-active"
                    data-swiper-slide-index="0"
                    style={{ width: "482px" }}
                  >
                    {" "}
                    <img
                      src="./Rest O sleep_files/01-1903x950.jpg"
                      alt="Slider5"
                      class="img-responsive"
                    />{" "}
                  </div>
                </div>
                <div class="swiper-pagination slideshow0 swiper-pagination-clickable swiper-pagination-bullets">
                  <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                  <span class="swiper-pagination-bullet"></span>
                  <span class="swiper-pagination-bullet"></span>
                  <span class="swiper-pagination-bullet"></span>
                </div>
                {/* <div class="swiper-pager">
                  <div class="swiper-button-prev"></div>
                  <div class="swiper-button-next"></div>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div class="tt-home">
          {" "}
          <div id="content" class="col-sm-12">
            <div class="html-content">
              {" "}
              <div class="box-content">
                <div id="ttcmsaboutus">
                  <div class="ttaboutus">
                    <div class="container">
                      <div class="row">
                        <div class="about-left about-block col-xs-12 col-xs-12">
                          <div class="about-content col-xs-12 col-xs-12 right-to-left hb-animate-element hb-in-viewport">
                            <div class="box-heading">
                              <h3 style={{color:" #111",font: "500 40px/44px" }}>
                                About Us
                              </h3>
                            </div>
                            <div class="img-desc">
                              <p style={{fontSize: "larger", lineHeight: "2"}}>
                                {" "}
                                Rest O Sleep is an ingeniously built product
                                from a reputed mattress manufacturing company
                                since early 90's. Our product is backed by the
                                state of art manufacturing technology,
                                scientific research and cutting-edge innovation
                                to ensure your body receives the right support
                                and comfort to enjoy optimum sleep benefits.
                              </p>
                            <p style={{fontSize: "larger", lineHeight: "2"}}>
                                {" "}
                                The mattress is indigenously crafted in our own
                                manufacturing units with high-quality control
                                measures at every stage with better raw
                                materials, to offer you a highly competitive
                                product that meets the top brand standard and
                                offers the best value for money. This direct
                                factory sale product helps save nearly 50% of
                                the cost when compared to some top-notch brands
                                in the market.
                              </p>
                            <p style={{fontSize: "larger", lineHeight: "2"}}>
                                {" "}
                                Rest O Sleep range of products ensures relief
                                measures too, especially for the neck, spine and
                                back. Our diversified offering includes
                                mattresses, pillows and mats across different
                                materials that cater to specific needs. Our
                                mattresses are available in PU foam, Orthopaedic
                                Memory Foam Dual Comfort Mattress, Hybrid Bonnel
                                spring, Rubberized Coir, High-Density Foam to
                                meet your every need.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ttproduct-main bottom-to-top hb-animate-element hb-in-viewport">
              <div class="container">
                <div class="row main-tab">
                  <div class="TTProduct-Tab TT-product-carousel products-list">
                    <div class="box-heading tthometab-title">
                      <h3>Trending Products</h3>
                    </div>
                    <div id="TTPTab-0" class="tab-box-heading">
                      <ul class="nav nav-tabs">
                        {" "}
                        <li class="active">
                          <a
                            href="https://restosleep.in/#tab-latest-0"
                            data-toggle="tab"
                          >
                            <h3 class="tab-title">Latest</h3>
                          </a>
                        </li>{" "}
                      </ul>
                    </div>
                    <div class="tab-content">
                      {" "}
                      <div id="tab-latest-0" class="tab-pane active">
                        <div
                          id="owl2"
                          class="products-carousel owl-loaded owl-carousel owl-drag"
                        >
                          {" "}
                          <div class="owl-stage-outer">
                            <div
                              class="owl-stage"
                              style={{transform: "translate3d(-482px, 0px, 0px)",transition: "all 0.25s ease 0s", width: "1928px"}}
                            >
                              <div
                                class="owl-item cloned"
                                style={{width: "241px"}}
                              >
                                <div class="single-column">
                                  {" "}
                                  <div class="product-layouts">
                                    <div class="product-thumb transition">
                                      <div class="image">
                                        {" "}
                                        <a href="https://restosleep.in/index.php?route=product/product&amp;product_id=53">
                                          <img
                                            class="image_thumb"
                                            src="./Rest O sleep_files/Pillows 3-285x371.jpg"
                                            title="tranquil"
                                            alt="tranquil"
                                          />
                                          <img
                                            class="image_thumb_swap"
                                            src="./Rest O sleep_files/Pillows 2-285x371.jpg"
                                            title="tranquil"
                                            alt="tranquil"
                                          />
                                        </a>
                                        <div class="button-group">
                                          <button
                                            class="btn-cart"
                                            type="button"
                                            title="Add to Cart"
                                            onclick="cart.add(&#39;53&#39;)"
                                          >
                                            <i class="fa fa-shopping-cart"></i>{" "}
                                            <span class="hidden-xs hidden-sm hidden-md">
                                              Add to Cart{" "}
                                            </span>
                                          </button>
                                          <button
                                            class="btn-wishlist"
                                            title="Add to wishlist"
                                            onclick="wishlist.add(&#39;53&#39;);"
                                          >
                                            <i class="fa fa-heart"></i>{" "}
                                            <span title="Add to wishlist">
                                              Add to wishlist
                                            </span>{" "}
                                          </button>
                                          <button
                                            class="btn-compare"
                                            title="Add to Compare"
                                            onclick="compare.add(&#39;53&#39;);"
                                          >
                                            <i class="fa fa-exchange"></i>{" "}
                                            <span title="Add to Compare">
                                              Add to Compare
                                            </span>{" "}
                                          </button>
                                          <button
                                            class="btn-quickview"
                                            type="button"
                                            title=""
                                            onclick="tt_quickview.ajaxView(&#39;https://restosleep.in/index.php?route=product/product&amp;product_id=53&#39;)"
                                          >
                                            <i class="fa fa-eye"></i>{" "}
                                            <span title=""></span>{" "}
                                          </button>
                                        </div>
                                      </div>
                                      <div class="thumb-description">
                                        <div class="caption">
                                          <h4>
                                            <a href="https://restosleep.in/index.php?route=product/product&amp;product_id=53">
                                              tranquil
                                            </a>
                                          </h4>{" "}
                                          <div class="price">
                                            Rs 699.00{" "}
                                            <span class="price-tax">
                                              Without tax:Rs 699.00
                                            </span>{" "}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>{" "}
                                  <div class="product-layouts">
                                    <div class="product-thumb transition">
                                      <div class="image">
                                        {" "}
                                        <a href="https://restosleep.in/index.php?route=product/product&amp;product_id=52">
                                          <img
                                            class="image_thumb"
                                            src="./Rest O sleep_files/Memory Puf 1-285x371.jpg"
                                            title="Memory Puf"
                                            alt="Memory Puf"
                                          />
                                          <img
                                            class="image_thumb_swap"
                                            src="./Rest O sleep_files/Memory Puf 2-285x371.jpg"
                                            title="Memory Puf"
                                            alt="Memory Puf"
                                          />
                                        </a>{" "}
                                        <div class="rating">
                                          {" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star off fa-stack-2x"></i>
                                          </span>{" "}
                                        </div>
                                        <div class="button-group">
                                          <button
                                            class="btn-cart"
                                            type="button"
                                            title="Add to Cart"
                                            onclick="cart.add(&#39;52&#39;)"
                                          >
                                            <i class="fa fa-shopping-cart"></i>{" "}
                                            <span class="hidden-xs hidden-sm hidden-md">
                                              Add to Cart{" "}
                                            </span>
                                          </button>
                                          <button
                                            class="btn-wishlist"
                                            title="Add to wishlist"
                                            onclick="wishlist.add(&#39;52&#39;);"
                                          >
                                            <i class="fa fa-heart"></i>{" "}
                                            <span title="Add to wishlist">
                                              Add to wishlist
                                            </span>{" "}
                                          </button>
                                          <button
                                            class="btn-compare"
                                            title="Add to Compare"
                                            onclick="compare.add(&#39;52&#39;);"
                                          >
                                            <i class="fa fa-exchange"></i>{" "}
                                            <span title="Add to Compare">
                                              Add to Compare
                                            </span>{" "}
                                          </button>
                                          <button
                                            class="btn-quickview"
                                            type="button"
                                            title=""
                                            onclick="tt_quickview.ajaxView(&#39;https://restosleep.in/index.php?route=product/product&amp;product_id=52&#39;)"
                                          >
                                            <i class="fa fa-eye"></i>{" "}
                                            <span title=""></span>{" "}
                                          </button>
                                        </div>
                                      </div>
                                      <div class="thumb-description">
                                        <div class="caption">
                                          <h4>
                                            <a href="https://restosleep.in/index.php?route=product/product&amp;product_id=52">
                                              Memory Puf
                                            </a>
                                          </h4>{" "}
                                          <div class="price">
                                            Rs 10,712.00{" "}
                                            <span class="price-tax">
                                              Without tax:Rs 10,712.00
                                            </span>{" "}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>{" "}
                                </div>
                              </div>
                              <div
                                class="owl-item cloned"
                                style={{width: "241px"}}
                              >
                                <div class="single-column">
                                  {" "}
                                  <div class="product-layouts">
                                    <div class="product-thumb transition">
                                      <div class="image">
                                        {" "}
                                        <a href="https://restosleep.in/index.php?route=product/product&amp;product_id=51">
                                          <img
                                            class="image_thumb"
                                            src="./Rest O sleep_files/FoamLite 1-285x371.jpg"
                                            title="Foam Lite"
                                            alt="Foam Lite"
                                          />
                                          <img
                                            class="image_thumb_swap"
                                            src="./Rest O sleep_files/FoamLite 4-285x371.jpg"
                                            title="Foam Lite"
                                            alt="Foam Lite"
                                          />
                                        </a>{" "}
                                        <div class="rating">
                                          {" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star off fa-stack-2x"></i>
                                          </span>{" "}
                                        </div>
                                        <div class="button-group">
                                          <button
                                            class="btn-cart"
                                            type="button"
                                            title="Add to Cart"
                                            onclick="cart.add(&#39;51&#39;)"
                                          >
                                            <i class="fa fa-shopping-cart"></i>{" "}
                                            <span class="hidden-xs hidden-sm hidden-md">
                                              Add to Cart{" "}
                                            </span>
                                          </button>
                                          <button
                                            class="btn-wishlist"
                                            title="Add to wishlist"
                                            onclick="wishlist.add(&#39;51&#39;);"
                                          >
                                            <i class="fa fa-heart"></i>{" "}
                                            <span title="Add to wishlist">
                                              Add to wishlist
                                            </span>{" "}
                                          </button>
                                          <button
                                            class="btn-compare"
                                            title="Add to Compare"
                                            onclick="compare.add(&#39;51&#39;);"
                                          >
                                            <i class="fa fa-exchange"></i>{" "}
                                            <span title="Add to Compare">
                                              Add to Compare
                                            </span>{" "}
                                          </button>
                                          <button
                                            class="btn-quickview"
                                            type="button"
                                            title=""
                                            onclick="tt_quickview.ajaxView(&#39;https://restosleep.in/index.php?route=product/product&amp;product_id=51&#39;)"
                                          >
                                            <i class="fa fa-eye"></i>{" "}
                                            <span title=""></span>{" "}
                                          </button>
                                        </div>
                                      </div>
                                      <div class="thumb-description">
                                        <div class="caption">
                                          <h4>
                                            <a href="https://restosleep.in/index.php?route=product/product&amp;product_id=51">
                                              Foam Lite
                                            </a>
                                          </h4>{" "}
                                          <div class="price">
                                            Rs 4,834.00{" "}
                                            <span class="price-tax">
                                              Without tax:Rs 4,834.00
                                            </span>{" "}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>{" "}
                                  <div class="product-layouts">
                                    <div class="product-thumb transition">
                                      <div class="image">
                                        {" "}
                                        <a href="https://restosleep.in/index.php?route=product/product&amp;product_id=50">
                                          <img
                                            class="image_thumb"
                                            src="./Rest O sleep_files/Spring 1-285x371.jpg"
                                            title="Springzz"
                                            alt="Springzz"
                                          />
                                          <img
                                            class="image_thumb_swap"
                                            src="./Rest O sleep_files/Brand 02 - 05-285x371.jpg"
                                            title="Springzz"
                                            alt="Springzz"
                                          />
                                        </a>{" "}
                                        <div class="sale-icon">Sale</div>
                                        <span class="percent">-0%</span>
                                        <div class="button-group">
                                          <button
                                            class="btn-cart"
                                            type="button"
                                            title="Add to Cart"
                                            onclick="cart.add(&#39;50&#39;)"
                                          >
                                            <i class="fa fa-shopping-cart"></i>{" "}
                                            <span class="hidden-xs hidden-sm hidden-md">
                                              Add to Cart{" "}
                                            </span>
                                          </button>
                                          <button
                                            class="btn-wishlist"
                                            title="Add to wishlist"
                                            onclick="wishlist.add(&#39;50&#39;);"
                                          >
                                            <i class="fa fa-heart"></i>{" "}
                                            <span title="Add to wishlist">
                                              Add to wishlist
                                            </span>{" "}
                                          </button>
                                          <button
                                            class="btn-compare"
                                            title="Add to Compare"
                                            onclick="compare.add(&#39;50&#39;);"
                                          >
                                            <i class="fa fa-exchange"></i>{" "}
                                            <span title="Add to Compare">
                                              Add to Compare
                                            </span>{" "}
                                          </button>
                                          <button
                                            class="btn-quickview"
                                            type="button"
                                            title=""
                                            onclick="tt_quickview.ajaxView(&#39;https://restosleep.in/index.php?route=product/product&amp;product_id=50&#39;)"
                                          >
                                            <i class="fa fa-eye"></i>{" "}
                                            <span title=""></span>{" "}
                                          </button>
                                        </div>
                                      </div>
                                      <div class="thumb-description">
                                        <div class="caption">
                                          <h4>
                                            <a href="https://restosleep.in/index.php?route=product/product&amp;product_id=50">
                                              Springzz
                                            </a>
                                          </h4>{" "}
                                          <div class="price">
                                            {" "}
                                            <span class="price-new">
                                              Rs 7,779.00
                                            </span>{" "}
                                            <span class="price-old">
                                              Rs 7,779.00
                                            </span>{" "}
                                            <span class="price-tax">
                                              Without tax:Rs 7,779.00
                                            </span>{" "}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>{" "}
                                </div>
                              </div>
                              <div
                                class="owl-item active"
                                style={{width: "241px"}}
                              >
                                <div class="single-column">
                                  {" "}
                                  <div class="product-layouts">
                                    <div class="product-thumb transition">
                                      <div class="image">
                                        {" "}
                                        <a href="https://restosleep.in/index.php?route=product/product&amp;product_id=57">
                                          <img
                                            class="image_thumb"
                                            src="./Rest O sleep_files/Coir with Memory foam 2-285x371.jpg"
                                            title="Spine o safe"
                                            alt="Spine o safe"
                                          />
                                          <img
                                            class="image_thumb_swap"
                                            src="./Rest O sleep_files/Coir with Memory foam 4-285x371.jpg"
                                            title="Spine o safe"
                                            alt="Spine o safe"
                                          />
                                        </a>{" "}
                                        <div class="rating">
                                          {" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                        </div>
                                        <div class="button-group">
                                          <button
                                            class="btn-cart"
                                            type="button"
                                            title="Add to Cart"
                                            onclick="cart.add(&#39;57&#39;)"
                                          >
                                            <i class="fa fa-shopping-cart"></i>{" "}
                                            <span class="hidden-xs hidden-sm hidden-md">
                                              Add to Cart{" "}
                                            </span>
                                          </button>
                                          <button
                                            class="btn-wishlist"
                                            title="Add to wishlist"
                                            onclick="wishlist.add(&#39;57&#39;);"
                                          >
                                            <i class="fa fa-heart"></i>{" "}
                                            <span title="Add to wishlist">
                                              Add to wishlist
                                            </span>{" "}
                                          </button>
                                          <button
                                            class="btn-compare"
                                            title="Add to Compare"
                                            onclick="compare.add(&#39;57&#39;);"
                                          >
                                            <i class="fa fa-exchange"></i>{" "}
                                            <span title="Add to Compare">
                                              Add to Compare
                                            </span>{" "}
                                          </button>
                                          <button
                                            class="btn-quickview"
                                            type="button"
                                            title=""
                                            onclick="tt_quickview.ajaxView(&#39;https://restosleep.in/index.php?route=product/product&amp;product_id=57&#39;)"
                                          >
                                            <i class="fa fa-eye"></i>{" "}
                                            <span title=""></span>{" "}
                                          </button>
                                        </div>
                                      </div>
                                      <div class="thumb-description">
                                        <div class="caption">
                                          <h4>
                                            <a href="https://restosleep.in/index.php?route=product/product&amp;product_id=57">
                                              Spine o safe
                                            </a>
                                          </h4>{" "}
                                          <div class="price">
                                            Rs 12,391.00{" "}
                                            <span class="price-tax">
                                              Without tax:Rs 12,391.00
                                            </span>{" "}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>{" "}
                                  <div class="product-layouts">
                                    <div class="product-thumb transition">
                                      <div class="image">
                                        {" "}
                                        <a href="https://restosleep.in/index.php?route=product/product&amp;product_id=56">
                                          <img
                                            class="image_thumb"
                                            src="./Rest O sleep_files/Wedge Pillow 1-285x371.jpg"
                                            title="Wedge Pillow"
                                            alt="Wedge Pillow"
                                          />
                                          <img
                                            class="image_thumb_swap"
                                            src="./Rest O sleep_files/Wedge Pillow 4-285x371.jpg"
                                            title="Wedge Pillow"
                                            alt="Wedge Pillow"
                                          />
                                        </a>
                                        <div class="button-group">
                                          <button
                                            class="btn-cart"
                                            type="button"
                                            title="Add to Cart"
                                            onclick="cart.add(&#39;56&#39;)"
                                          >
                                            <i class="fa fa-shopping-cart"></i>{" "}
                                            <span class="hidden-xs hidden-sm hidden-md">
                                              Add to Cart{" "}
                                            </span>
                                          </button>
                                          <button
                                            class="btn-wishlist"
                                            title="Add to wishlist"
                                            onclick="wishlist.add(&#39;56&#39;);"
                                          >
                                            <i class="fa fa-heart"></i>{" "}
                                            <span title="Add to wishlist">
                                              Add to wishlist
                                            </span>{" "}
                                          </button>
                                          <button
                                            class="btn-compare"
                                            title="Add to Compare"
                                            onclick="compare.add(&#39;56&#39;);"
                                          >
                                            <i class="fa fa-exchange"></i>{" "}
                                            <span title="Add to Compare">
                                              Add to Compare
                                            </span>{" "}
                                          </button>
                                          <button
                                            class="btn-quickview"
                                            type="button"
                                            title=""
                                            onclick="tt_quickview.ajaxView(&#39;https://restosleep.in/index.php?route=product/product&amp;product_id=56&#39;)"
                                          >
                                            <i class="fa fa-eye"></i>{" "}
                                            <span title=""></span>{" "}
                                          </button>
                                        </div>
                                      </div>
                                      <div class="thumb-description">
                                        <div class="caption">
                                          <h4>
                                            <a href="https://restosleep.in/index.php?route=product/product&amp;product_id=56">
                                              Wedge Pillow
                                            </a>
                                          </h4>{" "}
                                          <div class="price">
                                            Rs 1,899.00{" "}
                                            <span class="price-tax">
                                              Without tax:Rs 1,899.00
                                            </span>{" "}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>{" "}
                                </div>
                              </div>
                              <div
                                class="owl-item active"
                                style={{width: "241px"}}
                              >
                                <div class="single-column">
                                  {" "}
                                  <div class="product-layouts">
                                    <div class="product-thumb transition">
                                      <div class="image">
                                        {" "}
                                        <a href="https://restosleep.in/index.php?route=product/product&amp;product_id=55">
                                          <img
                                            class="image_thumb"
                                            src="./Rest O sleep_files/resto coir -1-285x371.jpg"
                                            title="Resto Coir"
                                            alt="Resto Coir"
                                          />
                                          <img
                                            class="image_thumb_swap"
                                            src="./Rest O sleep_files/resto coir - 3-285x371.jpg"
                                            title="Resto Coir"
                                            alt="Resto Coir"
                                          />
                                        </a>{" "}
                                        <div class="rating">
                                          {" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                        </div>
                                        <div class="button-group">
                                          <button
                                            class="btn-cart"
                                            type="button"
                                            title="Add to Cart"
                                            onclick="cart.add(&#39;55&#39;)"
                                          >
                                            <i class="fa fa-shopping-cart"></i>{" "}
                                            <span class="hidden-xs hidden-sm hidden-md">
                                              Add to Cart{" "}
                                            </span>
                                          </button>
                                          <button
                                            class="btn-wishlist"
                                            title="Add to wishlist"
                                            onclick="wishlist.add(&#39;55&#39;);"
                                          >
                                            <i class="fa fa-heart"></i>{" "}
                                            <span title="Add to wishlist">
                                              Add to wishlist
                                            </span>{" "}
                                          </button>
                                          <button
                                            class="btn-compare"
                                            title="Add to Compare"
                                            onclick="compare.add(&#39;55&#39;);"
                                          >
                                            <i class="fa fa-exchange"></i>{" "}
                                            <span title="Add to Compare">
                                              Add to Compare
                                            </span>{" "}
                                          </button>
                                          <button
                                            class="btn-quickview"
                                            type="button"
                                            title=""
                                            onclick="tt_quickview.ajaxView(&#39;https://restosleep.in/index.php?route=product/product&amp;product_id=55&#39;)"
                                          >
                                            <i class="fa fa-eye"></i>{" "}
                                            <span title=""></span>{" "}
                                          </button>
                                        </div>
                                      </div>
                                      <div class="thumb-description">
                                        <div class="caption">
                                          <h4>
                                            <a href="https://restosleep.in/index.php?route=product/product&amp;product_id=55">
                                              Resto Coir
                                            </a>
                                          </h4>{" "}
                                          <div class="price">
                                            Rs 4,950.00{" "}
                                            <span class="price-tax">
                                              Without tax:Rs 4,950.00
                                            </span>{" "}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>{" "}
                                  <div class="product-layouts">
                                    <div class="product-thumb transition">
                                      <div class="image">
                                        {" "}
                                        <a href="https://restosleep.in/index.php?route=product/product&amp;product_id=54">
                                          <img
                                            class="image_thumb"
                                            src="./Rest O sleep_files/restolite (1)-285x371.jpg"
                                            title="resto lite"
                                            alt="resto lite"
                                          />
                                          <img
                                            class="image_thumb_swap"
                                            src="./Rest O sleep_files/restolite (4)-285x371.jpg"
                                            title="resto lite"
                                            alt="resto lite"
                                          />
                                        </a>
                                        <div class="button-group">
                                          <button
                                            class="btn-cart"
                                            type="button"
                                            title="Add to Cart"
                                            onclick="cart.add(&#39;54&#39;)"
                                          >
                                            <i class="fa fa-shopping-cart"></i>{" "}
                                            <span class="hidden-xs hidden-sm hidden-md">
                                              Add to Cart{" "}
                                            </span>
                                          </button>
                                          <button
                                            class="btn-wishlist"
                                            title="Add to wishlist"
                                            onclick="wishlist.add(&#39;54&#39;);"
                                          >
                                            <i class="fa fa-heart"></i>{" "}
                                            <span title="Add to wishlist">
                                              Add to wishlist
                                            </span>{" "}
                                          </button>
                                          <button
                                            class="btn-compare"
                                            title="Add to Compare"
                                            onclick="compare.add(&#39;54&#39;);"
                                          >
                                            <i class="fa fa-exchange"></i>{" "}
                                            <span title="Add to Compare">
                                              Add to Compare
                                            </span>{" "}
                                          </button>
                                          <button
                                            class="btn-quickview"
                                            type="button"
                                            title=""
                                            onclick="tt_quickview.ajaxView(&#39;https://restosleep.in/index.php?route=product/product&amp;product_id=54&#39;)"
                                          >
                                            <i class="fa fa-eye"></i>{" "}
                                            <span title=""></span>{" "}
                                          </button>
                                        </div>
                                      </div>
                                      <div class="thumb-description">
                                        <div class="caption">
                                          <h4>
                                            <a href="https://restosleep.in/index.php?route=product/product&amp;product_id=54">
                                              resto lite
                                            </a>
                                          </h4>{" "}
                                          <div class="price">
                                            Rs 1,049.00{" "}
                                            <span class="price-tax">
                                              Without tax:Rs 1,049.00
                                            </span>{" "}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>{" "}
                                </div>
                              </div>
                              <div class="owl-item" style={{width: "241px"}}>
                                <div class="single-column">
                                  {" "}
                                  <div class="product-layouts">
                                    <div class="product-thumb transition">
                                      <div class="image">
                                        {" "}
                                        <a href="https://restosleep.in/index.php?route=product/product&amp;product_id=53">
                                          <img
                                            class="image_thumb"
                                            src="./Rest O sleep_files/Pillows 3-285x371.jpg"
                                            title="tranquil"
                                            alt="tranquil"
                                          />
                                          <img
                                            class="image_thumb_swap"
                                            src="./Rest O sleep_files/Pillows 2-285x371.jpg"
                                            title="tranquil"
                                            alt="tranquil"
                                          />
                                        </a>
                                        <div class="button-group">
                                          <button
                                            class="btn-cart"
                                            type="button"
                                            title="Add to Cart"
                                            onclick="cart.add(&#39;53&#39;)"
                                          >
                                            <i class="fa fa-shopping-cart"></i>{" "}
                                            <span class="hidden-xs hidden-sm hidden-md">
                                              Add to Cart{" "}
                                            </span>
                                          </button>
                                          <button
                                            class="btn-wishlist"
                                            title="Add to wishlist"
                                            onclick="wishlist.add(&#39;53&#39;);"
                                          >
                                            <i class="fa fa-heart"></i>{" "}
                                            <span title="Add to wishlist">
                                              Add to wishlist
                                            </span>{" "}
                                          </button>
                                          <button
                                            class="btn-compare"
                                            title="Add to Compare"
                                            onclick="compare.add(&#39;53&#39;);"
                                          >
                                            <i class="fa fa-exchange"></i>{" "}
                                            <span title="Add to Compare">
                                              Add to Compare
                                            </span>{" "}
                                          </button>
                                          <button
                                            class="btn-quickview"
                                            type="button"
                                            title=""
                                            onclick="tt_quickview.ajaxView(&#39;https://restosleep.in/index.php?route=product/product&amp;product_id=53&#39;)"
                                          >
                                            <i class="fa fa-eye"></i>{" "}
                                            <span title=""></span>{" "}
                                          </button>
                                        </div>
                                      </div>
                                      <div class="thumb-description">
                                        <div class="caption">
                                          <h4>
                                            <a href="https://restosleep.in/index.php?route=product/product&amp;product_id=53">
                                              tranquil
                                            </a>
                                          </h4>{" "}
                                          <div class="price">
                                            Rs 699.00{" "}
                                            <span class="price-tax">
                                              Without tax:Rs 699.00
                                            </span>{" "}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>{" "}
                                  <div class="product-layouts">
                                    <div class="product-thumb transition">
                                      <div class="image">
                                        {" "}
                                        <a href="https://restosleep.in/index.php?route=product/product&amp;product_id=52">
                                          <img
                                            class="image_thumb"
                                            src="./Rest O sleep_files/Memory Puf 1-285x371.jpg"
                                            title="Memory Puf"
                                            alt="Memory Puf"
                                          />
                                          <img
                                            class="image_thumb_swap"
                                            src="./Rest O sleep_files/Memory Puf 2-285x371.jpg"
                                            title="Memory Puf"
                                            alt="Memory Puf"
                                          />
                                        </a>{" "}
                                        <div class="rating">
                                          {" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star off fa-stack-2x"></i>
                                          </span>{" "}
                                        </div>
                                        <div class="button-group">
                                          <button
                                            class="btn-cart"
                                            type="button"
                                            title="Add to Cart"
                                            onclick="cart.add(&#39;52&#39;)"
                                          >
                                            <i class="fa fa-shopping-cart"></i>{" "}
                                            <span class="hidden-xs hidden-sm hidden-md">
                                              Add to Cart{" "}
                                            </span>
                                          </button>
                                          <button
                                            class="btn-wishlist"
                                            title="Add to wishlist"
                                            onclick="wishlist.add(&#39;52&#39;);"
                                          >
                                            <i class="fa fa-heart"></i>{" "}
                                            <span title="Add to wishlist">
                                              Add to wishlist
                                            </span>{" "}
                                          </button>
                                          <button
                                            class="btn-compare"
                                            title="Add to Compare"
                                            onclick="compare.add(&#39;52&#39;);"
                                          >
                                            <i class="fa fa-exchange"></i>{" "}
                                            <span title="Add to Compare">
                                              Add to Compare
                                            </span>{" "}
                                          </button>
                                          <button
                                            class="btn-quickview"
                                            type="button"
                                            title=""
                                            onclick="tt_quickview.ajaxView(&#39;https://restosleep.in/index.php?route=product/product&amp;product_id=52&#39;)"
                                          >
                                            <i class="fa fa-eye"></i>{" "}
                                            <span title=""></span>{" "}
                                          </button>
                                        </div>
                                      </div>
                                      <div class="thumb-description">
                                        <div class="caption">
                                          <h4>
                                            <a href="https://restosleep.in/index.php?route=product/product&amp;product_id=52">
                                              Memory Puf
                                            </a>
                                          </h4>{" "}
                                          <div class="price">
                                            Rs 10,712.00{" "}
                                            <span class="price-tax">
                                              Without tax:Rs 10,712.00
                                            </span>{" "}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>{" "}
                                </div>
                              </div>
                              <div class="owl-item" style={{width: "241px"}}>
                                <div class="single-column">
                                  {" "}
                                  <div class="product-layouts">
                                    <div class="product-thumb transition">
                                      <div class="image">
                                        {" "}
                                        <a href="https://restosleep.in/index.php?route=product/product&amp;product_id=51">
                                          <img
                                            class="image_thumb"
                                            src="./Rest O sleep_files/FoamLite 1-285x371.jpg"
                                            title="Foam Lite"
                                            alt="Foam Lite"
                                          />
                                          <img
                                            class="image_thumb_swap"
                                            src="./Rest O sleep_files/FoamLite 4-285x371.jpg"
                                            title="Foam Lite"
                                            alt="Foam Lite"
                                          />
                                        </a>{" "}
                                        <div class="rating">
                                          {" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star off fa-stack-2x"></i>
                                          </span>{" "}
                                        </div>
                                        <div class="button-group">
                                          <button
                                            class="btn-cart"
                                            type="button"
                                            title="Add to Cart"
                                            onclick="cart.add(&#39;51&#39;)"
                                          >
                                            <i class="fa fa-shopping-cart"></i>{" "}
                                            <span class="hidden-xs hidden-sm hidden-md">
                                              Add to Cart{" "}
                                            </span>
                                          </button>
                                          <button
                                            class="btn-wishlist"
                                            title="Add to wishlist"
                                            onclick="wishlist.add(&#39;51&#39;);"
                                          >
                                            <i class="fa fa-heart"></i>{" "}
                                            <span title="Add to wishlist">
                                              Add to wishlist
                                            </span>{" "}
                                          </button>
                                          <button
                                            class="btn-compare"
                                            title="Add to Compare"
                                            onclick="compare.add(&#39;51&#39;);"
                                          >
                                            <i class="fa fa-exchange"></i>{" "}
                                            <span title="Add to Compare">
                                              Add to Compare
                                            </span>{" "}
                                          </button>
                                          <button
                                            class="btn-quickview"
                                            type="button"
                                            title=""
                                            onclick="tt_quickview.ajaxView(&#39;https://restosleep.in/index.php?route=product/product&amp;product_id=51&#39;)"
                                          >
                                            <i class="fa fa-eye"></i>{" "}
                                            <span title=""></span>{" "}
                                          </button>
                                        </div>
                                      </div>
                                      <div class="thumb-description">
                                        <div class="caption">
                                          <h4>
                                            <a href="https://restosleep.in/index.php?route=product/product&amp;product_id=51">
                                              Foam Lite
                                            </a>
                                          </h4>{" "}
                                          <div class="price">
                                            Rs 4,834.00{" "}
                                            <span class="price-tax">
                                              Without tax:Rs 4,834.00
                                            </span>{" "}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>{" "}
                                  <div class="product-layouts">
                                    <div class="product-thumb transition">
                                      <div class="image">
                                        {" "}
                                        <a href="https://restosleep.in/index.php?route=product/product&amp;product_id=50">
                                          <img
                                            class="image_thumb"
                                            src="./Rest O sleep_files/Spring 1-285x371.jpg"
                                            title="Springzz"
                                            alt="Springzz"
                                          />
                                          <img
                                            class="image_thumb_swap"
                                            src="./Rest O sleep_files/Brand 02 - 05-285x371.jpg"
                                            title="Springzz"
                                            alt="Springzz"
                                          />
                                        </a>{" "}
                                        <div class="sale-icon">Sale</div>
                                        <span class="percent">-0%</span>
                                        <div class="button-group">
                                          <button
                                            class="btn-cart"
                                            type="button"
                                            title="Add to Cart"
                                            onclick="cart.add(&#39;50&#39;)"
                                          >
                                            <i class="fa fa-shopping-cart"></i>{" "}
                                            <span class="hidden-xs hidden-sm hidden-md">
                                              Add to Cart{" "}
                                            </span>
                                          </button>
                                          <button
                                            class="btn-wishlist"
                                            title="Add to wishlist"
                                            onclick="wishlist.add(&#39;50&#39;);"
                                          >
                                            <i class="fa fa-heart"></i>{" "}
                                            <span title="Add to wishlist">
                                              Add to wishlist
                                            </span>{" "}
                                          </button>
                                          <button
                                            class="btn-compare"
                                            title="Add to Compare"
                                            onclick="compare.add(&#39;50&#39;);"
                                          >
                                            <i class="fa fa-exchange"></i>{" "}
                                            <span title="Add to Compare">
                                              Add to Compare
                                            </span>{" "}
                                          </button>
                                          <button
                                            class="btn-quickview"
                                            type="button"
                                            title=""
                                            onclick="tt_quickview.ajaxView(&#39;https://restosleep.in/index.php?route=product/product&amp;product_id=50&#39;)"
                                          >
                                            <i class="fa fa-eye"></i>{" "}
                                            <span title=""></span>{" "}
                                          </button>
                                        </div>
                                      </div>
                                      <div class="thumb-description">
                                        <div class="caption">
                                          <h4>
                                            <a href="https://restosleep.in/index.php?route=product/product&amp;product_id=50">
                                              Springzz
                                            </a>
                                          </h4>{" "}
                                          <div class="price">
                                            {" "}
                                            <span class="price-new">
                                              Rs 7,779.00
                                            </span>{" "}
                                            <span class="price-old">
                                              Rs 7,779.00
                                            </span>{" "}
                                            <span class="price-tax">
                                              Without tax:Rs 7,779.00
                                            </span>{" "}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>{" "}
                                </div>
                              </div>
                              <div
                                class="owl-item cloned"
                                style={{width: "241px"}}
                              >
                                <div class="single-column">
                                  {" "}
                                  <div class="product-layouts">
                                    <div class="product-thumb transition">
                                      <div class="image">
                                        {" "}
                                        <a href="https://restosleep.in/index.php?route=product/product&amp;product_id=57">
                                          <img
                                            class="image_thumb"
                                            src="./Rest O sleep_files/Coir with Memory foam 2-285x371.jpg"
                                            title="Spine o safe"
                                            alt="Spine o safe"
                                          />
                                          <img
                                            class="image_thumb_swap"
                                            src="./Rest O sleep_files/Coir with Memory foam 4-285x371.jpg"
                                            title="Spine o safe"
                                            alt="Spine o safe"
                                          />
                                        </a>{" "}
                                        <div class="rating">
                                          {" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                        </div>
                                        <div class="button-group">
                                          <button
                                            class="btn-cart"
                                            type="button"
                                            title="Add to Cart"
                                            onclick="cart.add(&#39;57&#39;)"
                                          >
                                            <i class="fa fa-shopping-cart"></i>{" "}
                                            <span class="hidden-xs hidden-sm hidden-md">
                                              Add to Cart{" "}
                                            </span>
                                          </button>
                                          <button
                                            class="btn-wishlist"
                                            title="Add to wishlist"
                                            onclick="wishlist.add(&#39;57&#39;);"
                                          >
                                            <i class="fa fa-heart"></i>{" "}
                                            <span title="Add to wishlist">
                                              Add to wishlist
                                            </span>{" "}
                                          </button>
                                          <button
                                            class="btn-compare"
                                            title="Add to Compare"
                                            onclick="compare.add(&#39;57&#39;);"
                                          >
                                            <i class="fa fa-exchange"></i>{" "}
                                            <span title="Add to Compare">
                                              Add to Compare
                                            </span>{" "}
                                          </button>
                                          <button
                                            class="btn-quickview"
                                            type="button"
                                            title=""
                                            onclick="tt_quickview.ajaxView(&#39;https://restosleep.in/index.php?route=product/product&amp;product_id=57&#39;)"
                                          >
                                            <i class="fa fa-eye"></i>{" "}
                                            <span title=""></span>{" "}
                                          </button>
                                        </div>
                                      </div>
                                      <div class="thumb-description">
                                        <div class="caption">
                                          <h4>
                                            <a href="https://restosleep.in/index.php?route=product/product&amp;product_id=57">
                                              Spine o safe
                                            </a>
                                          </h4>{" "}
                                          <div class="price">
                                            Rs 12,391.00{" "}
                                            <span class="price-tax">
                                              Without tax:Rs 12,391.00
                                            </span>{" "}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>{" "}
                                  <div class="product-layouts">
                                    <div class="product-thumb transition">
                                      <div class="image">
                                        {" "}
                                        <a href="https://restosleep.in/index.php?route=product/product&amp;product_id=56">
                                          <img
                                            class="image_thumb"
                                            src="./Rest O sleep_files/Wedge Pillow 1-285x371.jpg"
                                            title="Wedge Pillow"
                                            alt="Wedge Pillow"
                                          />
                                          <img
                                            class="image_thumb_swap"
                                            src="./Rest O sleep_files/Wedge Pillow 4-285x371.jpg"
                                            title="Wedge Pillow"
                                            alt="Wedge Pillow"
                                          />
                                        </a>
                                        <div class="button-group">
                                          <button
                                            class="btn-cart"
                                            type="button"
                                            title="Add to Cart"
                                            onclick="cart.add(&#39;56&#39;)"
                                          >
                                            <i class="fa fa-shopping-cart"></i>{" "}
                                            <span class="hidden-xs hidden-sm hidden-md">
                                              Add to Cart{" "}
                                            </span>
                                          </button>
                                          <button
                                            class="btn-wishlist"
                                            title="Add to wishlist"
                                            onclick="wishlist.add(&#39;56&#39;);"
                                          >
                                            <i class="fa fa-heart"></i>{" "}
                                            <span title="Add to wishlist">
                                              Add to wishlist
                                            </span>{" "}
                                          </button>
                                          <button
                                            class="btn-compare"
                                            title="Add to Compare"
                                            onclick="compare.add(&#39;56&#39;);"
                                          >
                                            <i class="fa fa-exchange"></i>{" "}
                                            <span title="Add to Compare">
                                              Add to Compare
                                            </span>{" "}
                                          </button>
                                          <button
                                            class="btn-quickview"
                                            type="button"
                                            title=""
                                            onclick="tt_quickview.ajaxView(&#39;https://restosleep.in/index.php?route=product/product&amp;product_id=56&#39;)"
                                          >
                                            <i class="fa fa-eye"></i>{" "}
                                            <span title=""></span>{" "}
                                          </button>
                                        </div>
                                      </div>
                                      <div class="thumb-description">
                                        <div class="caption">
                                          <h4>
                                            <a href="https://restosleep.in/index.php?route=product/product&amp;product_id=56">
                                              Wedge Pillow
                                            </a>
                                          </h4>{" "}
                                          <div class="price">
                                            Rs 1,899.00{" "}
                                            <span class="price-tax">
                                              Without tax:Rs 1,899.00
                                            </span>{" "}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>{" "}
                                </div>
                              </div>
                              <div
                                class="owl-item cloned"
                                style={{width: "241px"}}
                              >
                                <div class="single-column">
                                  {" "}
                                  <div class="product-layouts">
                                    <div class="product-thumb transition">
                                      <div class="image">
                                        {" "}
                                        <a href="https://restosleep.in/index.php?route=product/product&amp;product_id=55">
                                          <img
                                            class="image_thumb"
                                            src="./Rest O sleep_files/resto coir -1-285x371.jpg"
                                            title="Resto Coir"
                                            alt="Resto Coir"
                                          />
                                          <img
                                            class="image_thumb_swap"
                                            src="./Rest O sleep_files/resto coir - 3-285x371.jpg"
                                            title="Resto Coir"
                                            alt="Resto Coir"
                                          />
                                        </a>{" "}
                                        <div class="rating">
                                          {" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                          <span class="fa fa-stack">
                                            <i class="fa fa-star fa-stack-2x"></i>
                                          </span>{" "}
                                        </div>
                                        <div class="button-group">
                                          <button
                                            class="btn-cart"
                                            type="button"
                                            title="Add to Cart"
                                            onclick="cart.add(&#39;55&#39;)"
                                          >
                                            <i class="fa fa-shopping-cart"></i>{" "}
                                            <span class="hidden-xs hidden-sm hidden-md">
                                              Add to Cart{" "}
                                            </span>
                                          </button>
                                          <button
                                            class="btn-wishlist"
                                            title="Add to wishlist"
                                            onclick="wishlist.add(&#39;55&#39;);"
                                          >
                                            <i class="fa fa-heart"></i>{" "}
                                            <span title="Add to wishlist">
                                              Add to wishlist
                                            </span>{" "}
                                          </button>
                                          <button
                                            class="btn-compare"
                                            title="Add to Compare"
                                            onclick="compare.add(&#39;55&#39;);"
                                          >
                                            <i class="fa fa-exchange"></i>{" "}
                                            <span title="Add to Compare">
                                              Add to Compare
                                            </span>{" "}
                                          </button>
                                          <button
                                            class="btn-quickview"
                                            type="button"
                                            title=""
                                            onclick="tt_quickview.ajaxView(&#39;https://restosleep.in/index.php?route=product/product&amp;product_id=55&#39;)"
                                          >
                                            <i class="fa fa-eye"></i>{" "}
                                            <span title=""></span>{" "}
                                          </button>
                                        </div>
                                      </div>
                                      <div class="thumb-description">
                                        <div class="caption">
                                          <h4>
                                            <a href="https://restosleep.in/index.php?route=product/product&amp;product_id=55">
                                              Resto Coir
                                            </a>
                                          </h4>{" "}
                                          <div class="price">
                                            Rs 4,950.00{" "}
                                            <span class="price-tax">
                                              Without tax:Rs 4,950.00
                                            </span>{" "}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>{" "}
                                  <div class="product-layouts">
                                    <div class="product-thumb transition">
                                      <div class="image">
                                        {" "}
                                        <a href="https://restosleep.in/index.php?route=product/product&amp;product_id=54">
                                          <img
                                            class="image_thumb"
                                            src="./Rest O sleep_files/restolite (1)-285x371.jpg"
                                            title="resto lite"
                                            alt="resto lite"
                                          />
                                          <img
                                            class="image_thumb_swap"
                                            src="./Rest O sleep_files/restolite (4)-285x371.jpg"
                                            title="resto lite"
                                            alt="resto lite"
                                          />
                                        </a>
                                        <div class="button-group">
                                          <button
                                            class="btn-cart"
                                            type="button"
                                            title="Add to Cart"
                                            onclick="cart.add(&#39;54&#39;)"
                                          >
                                            <i class="fa fa-shopping-cart"></i>{" "}
                                            <span class="hidden-xs hidden-sm hidden-md">
                                              Add to Cart{" "}
                                            </span>
                                          </button>
                                          <button
                                            class="btn-wishlist"
                                            title="Add to wishlist"
                                            onclick="wishlist.add(&#39;54&#39;);"
                                          >
                                            <i class="fa fa-heart"></i>{" "}
                                            <span title="Add to wishlist">
                                              Add to wishlist
                                            </span>{" "}
                                          </button>
                                          <button
                                            class="btn-compare"
                                            title="Add to Compare"
                                            onclick="compare.add(&#39;54&#39;);"
                                          >
                                            <i class="fa fa-exchange"></i>{" "}
                                            <span title="Add to Compare">
                                              Add to Compare
                                            </span>{" "}
                                          </button>
                                          <button
                                            class="btn-quickview"
                                            type="button"
                                            title=""
                                            onclick="tt_quickview.ajaxView(&#39;https://restosleep.in/index.php?route=product/product&amp;product_id=54&#39;)"
                                          >
                                            <i class="fa fa-eye"></i>{" "}
                                            <span title=""></span>{" "}
                                          </button>
                                        </div>
                                      </div>
                                      <div class="thumb-description">
                                        <div class="caption">
                                          <h4>
                                            <a href="https://restosleep.in/index.php?route=product/product&amp;product_id=54">
                                              resto lite
                                            </a>
                                          </h4>{" "}
                                          <div class="price">
                                            Rs 1,049.00{" "}
                                            <span class="price-tax">
                                              Without tax:Rs 1,049.00
                                            </span>{" "}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="owl-nav">
                            <button
                              type="button"
                              role="presentation"
                              class="owl-prev"
                            >
                              Prev<span aria-label="Previous">‹</span>
                            </button>
                            <button
                              type="button"
                              role="presentation"
                              class="owl-next"
                            >
                              Next<span aria-label="Next">›</span>
                            </button>
                          </div>
                          <div class="owl-dots disabled"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="html-content">
              {" "}
              <div class="box-content">
                <div id="ttcmsservices">
                  <div class="container">
                    <div class="ttcmsservices owl-loaded owl-carousel owl-drag">
                      <div class="owl-stage-outer">
                        <div
                          class="owl-stage"
                          style={{transform: "translate3d(-452px, 0px, 0px)", transition: "all 1s ease 0s" ,width: "904px"}}
                        >
                          <div class="owl-item" style={{width: "226px"}}>
                            <div class="ttservice col-xs-3">
                              <div class="icon">
                                <div class="ttgift_img service-icon"></div>
                              </div>
                              <div class="service-content">
                                <div class="service-title">Free Shipping</div>
                                <div class="service-desc">
                                  Send a Gift Products
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="owl-item" style={{width: "226px"}}>
                            <div class="ttservice col-xs-3">
                              <div class="icon">
                                <div class="ttdisc_img service-icon"></div>
                              </div>
                              <div class="service-content">
                                <div class="service-title">Easy Finance</div>
                                <div class="service-desc">
                                  Discounts Everyday
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="owl-item active" style={{width: "226px"}}>
                            <div class="ttservice col-xs-3">
                              <div class="icon">
                                <div class="ttshipping_img service-icon"></div>
                              </div>
                              <div class="service-content">
                                <div class="service-title">24*7 Support</div>
                                <div class="service-desc">On Order Of $99</div>
                              </div>
                            </div>
                          </div>
                          <div class="owl-item active" style={{width: "226px"}}>
                            <div class="ttservice col-xs-3">
                              <div class="icon">
                                <div class="ttsupport_img service-icon"></div>
                              </div>
                              <div class="service-content">
                                <div class="service-title">Gift Vouchers</div>
                                <div class="service-desc">
                                  On Demand Support
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="owl-nav disabled">
                        <button
                          type="button"
                          role="presentation"
                          class="owl-prev"
                        >
                          Prev<span aria-label="Previous">‹</span>
                        </button>
                        <button
                          type="button"
                          role="presentation"
                          class="owl-next"
                        >
                          Next<span aria-label="Next">›</span>
                        </button>
                      </div>
                      <div class="owl-dots disabled"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="html-content">
              {" "}
              <div class="box-content">
                <p>
                  <img
                    src="./Rest O sleep_files/GIF.jpg"
                    style={{width:"100%"}}
                  />
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="html-content">
        {" "}
        <div class="box-content">
          <div class="box-content newleter-content">
            <img
              class=""
              src="./Rest O sleep_files/paypal.png"
              alt="Payment Gateway"
              style={{width: "264.344px", height: "67.5665px"}}
            />
          </div>
        </div>
      </div>
    </>
  );
}
