import React, { useEffect, useState } from 'react'
import TitleBar from '../components/TitleBar'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import StoresApi from '../assets/api/StoreApis'
import { addToCart, buyNow } from '../slice/cartSlice'
import axios, { webBaseUrl } from '../assets/axios/axios'
import slugify from '../utilities/urlCreatore'
import { toast } from 'react-toastify'
import OwlCarousel from 'react-owl-carousel2'
import { Form } from 'react-bootstrap'
import RenderOptions from '../components/productOptions/RenderOptions'

export default function Product() {
    const [searchParams] = useSearchParams()
    const cart = useSelector(state => state.cart.items)
    const store = useSelector(state => state.storeFront.store)
    const productId = searchParams.get("pid")

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [tab, setTab] = useState(0)
    const [product, setProduct] = useState({})
    const [attributeDetails, setAttributeDetails] = useState([])
    const [productOptions, setProductOptions] = useState([])
    const [similarProducts, setSimilarProducts] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [validated, setValidated] = useState(false)
    const [selectedOption, setSelectedOption] = useState([])
    const [productImages, setProductImages] = useState([])
    const [quantity, setQuantity] = useState('1')
    const [submitMode, setSubmitMode] = useState(0)
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [imageUrl, setImageUrl] = useState('')
    const [imageZoom, setImageZoom] = useState('0% 0%')

    const getProductDetails = () => {
        axios.get(`${StoresApi.ProductsUrl}/GetProduct/${productId}/${store.id}`)
            .then(response => {
                console.log(response);
                const productDetails = response.data.data
                productDetails.inclTax = productDetails.tax > 0 ? ((productDetails.sellingPrice * productDetails.tax) / 100) + productDetails.sellingPrice : productDetails.sellingPrice
                productDetails.displayPrice = productDetails.inclTax
                setProduct(productDetails)
                // groupAttributes(productDetails.productAttributes)
                setAttributeDetails(productDetails.productAttributes)
                setSimilarProducts(productDetails.relatedProducts)
                setProductOptions(productDetails.productOptions)
                setQuantity(productDetails.minimumQuantity)
                setProductImages(productDetails.productImages)
                setIsLoading(false)
                if (productDetails.path) {
                    setImageUrl(`${webBaseUrl}${productDetails.path}`)
                } else if (productDetails.path === null && productDetails.productImages.length > 0) {
                    setImageUrl(`${webBaseUrl}${productDetails.productImages[0].path}`)
                }
                window.history.replaceState(null, '', `/details/${slugify(productDetails.name)}?pid=${productDetails.id}`)
            })
            .catch(error => {
                setIsLoading(false)
                setIsError(true)
                console.log(error);
            })
    }

    const groupAttributes = (attribute) => {
        const arrAttribute = [...attribute]
        const arrAttributesGroup = []
        if (arrAttribute.length > 0) {
            arrAttribute.map((attrib, index) => {
                const indOfAttribute = arrAttributesGroup.findIndex(atr => {
                    if (arrAttributesGroup.length > 0) {
                        return attrib.attributes.attributesGroupId === atr.id
                    }
                    return false;
                })
                const attributeDetailsObj = {
                    name: attrib.attributes.name,
                    value: attrib.value,
                    sort: attrib.sortOrder
                }
                if (indOfAttribute >= 0) {
                    arrAttributesGroup[indOfAttribute].attributeDetails.push(attributeDetailsObj)
                } else {
                    arrAttributesGroup.push({
                        id: attrib.attributes.attributesGroupId,
                        groupName: attrib.attributes.attributesGroup.name,
                        sort: attrib.attributes.sortOrder,
                        attributeDetails: [attributeDetailsObj]
                    })
                }
                return null;
            })
        }
        // console.log(arrAttributesGroup);
        setAttributeDetails(arrAttributesGroup)
    }

    useEffect(() => {
        if (productId) {
            setIsLoading(true)
            getProductDetails()
        } else {
            setIsError(true)
        }
        window.scrollTo(0, 0);
    }, [productId, store])


    const updatePrice = (latestPrice) => {
        const objProduct = { ...product }
        const additionTax = objProduct.tax > 0 ? ((+latestPrice * objProduct.tax) / 100) : 0
        objProduct.displayPrice = objProduct.inclTax + latestPrice + additionTax
        objProduct.sellingPrice += latestPrice
        setProduct(objProduct)
    }

    useEffect(() => {
        let addition = 0
        selectedOption.map(option => {
            if (option.type === 3) {
                option.optionDetails.map(details => {
                    addition = details.isSubstractFromPrice ? addition - details.price : addition + details.price
                    return null;
                })
            } else if (option.type === 1) {
                addition = option.optionDetails.isSubstractFromPrice ? addition - option.optionDetails.price : addition + option.optionDetails.price
            }
            return null;
        })
        updatePrice(addition)
    }, [selectedOption])

    const increaseQuantity = (i) => {
        setQuantity(quantity + 1)
    }

    const decreaseQuantity = (i) => {
        setQuantity(quantity - 1)
    }

    const buyNowHandler = () => {
        const addProduct = { ...product }
        addProduct.quantity = +quantity
        addProduct.totalTax = product.tax > 0 ? ((product.sellingPrice * product.tax) / 100) : product.tax
        addProduct.itemTotal = product.displayPrice
        addProduct.totalPrice = addProduct.quantity * addProduct.itemTotal
        addProduct.selectedOptions = selectedOption
        dispatch(buyNow(addProduct))
        console.log("move to cart");
        navigate('/cart')
    }

    const toCart = () => {
        const isAdded = cart.findIndex(prod => prod.id === +productId)
        debugger
        if (isAdded >= 0) {
            let addedProducts = cart.filter(prod => prod.id === +productId)
            let exist = false
            addedProducts.forEach(cartProd => {
                if (cartProd.selectedOptions.length > 0) {
                    selectedOption.forEach(selected => {
                        cartProd.selectedOptions.forEach(productOption => {
                            if (productOption.productOptionDetailsId === selected.productOptionDetailsId) {
                                exist = true
                            }
                        });
                    });
                }

                if (cartProd.selectedOptions.length == 0 && selectedOption.length == 0) {
                    exist = true
                }
                // else {
                //     toast.warn('Product already in Cart!', {
                //         position: toast.POSITION.TOP_RIGHT,
                //         hideProgressBar: true,
                //         autoClose: 3000,
                //         theme: 'dark'
                //     })
                // }
                // return null;
            });
            if (exist) {
                toast.warn('Product already in Cart with same option!', {
                    position: toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000,
                    theme: 'light'
                })
            } else {
                const addProduct = { ...product }
                addProduct.quantity = +quantity
                addProduct.itemTotal = addProduct.displayPrice
                addProduct.totalPrice = addProduct.quantity * addProduct.itemTotal
                addProduct.selectedOptions = selectedOption
                dispatch(addToCart(addProduct))
            }
        } else {
            const addProduct = { ...product }
            addProduct.quantity = +quantity
            addProduct.itemTotal = addProduct.displayPrice
            addProduct.totalPrice = addProduct.quantity * addProduct.itemTotal
            addProduct.selectedOptions = selectedOption
            dispatch(addToCart(addProduct))
        }
    }


    const proceed = () => {
        if (submitMode === 1) {
            buyNowHandler()
        }

        if (submitMode === 2) {
            toCart()
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            console.log("Validation fails");
        } else {
            proceed()
        }
        setValidated(true)
    }

    const options = {
        items: 3,
        rewind: false,
        autoplay: false,
        dots: false,
        navText: [<button type="button" role="presentation" class="owl-next">Next<span aria-label="Next">›</span></button>, <button type="button" role="presentation" class="owl-prev disabled">Prev<span aria-label="Previous">‹</span></button>,]
    };

    const handleMouseMove = e => {
        const { left, top, width, height } = e.target.getBoundingClientRect()
        const x = (e.pageX - left) / width * 100
        const y = (e.pageY - top) / height * 100
        setImageZoom(`${x}% ${y}%`)
    }

    return (
        <>
            <TitleBar product={product.name} />
            <div id="product-product" className="container product-product">
                <div className="row">
                    <div id="content" className="col-sm-12 Cwidth" style={{ width: "100%" }}>
                        <div className="row">
                            <div className="col-xs-12 col-sm-5 col-md-5 product-images">
                                <div className="thumbnails">
                                    <div className="product-image">
                                        <div role="tabpanel" class="tab-pane active fade in" id="single-img-1" style={{ backgroundImage: `url(${imageUrl})`, backgroundPosition: imageZoom, padding: "0px" }}> <img className='product-image-main' src={imageUrl ? imageUrl : "/assets/img/brand/logo2.jpg"} alt="" onMouseMove={handleMouseMove} /> </div>
                                    </div>
                                    <div className="additional-images-container">
                                        <OwlCarousel options={options} className="additional-images">
                                            {productImages.map(image => {
                                                if (image.isDeleted) return null;
                                                return (
                                                    <div className="image-additional" onClick={() => setImageUrl(`${webBaseUrl}${image.path}`)}>
                                                        <img src={`${webBaseUrl}${image.path}`} title={product.name} alt={product.name} />
                                                    </div>
                                                )
                                            })}
                                        </OwlCarousel>
                                        {/* <div className="additional-images owl-loaded owl-carousel owl-drag">
                                            <div className="owl-stage-outer"><div className="owl-stage" style={{ transform: "translate3d(0px, 0px, 0px)", transition: "all 0s ease 0s", width: "356px" }}><div className="owl-item active" style={{ width: "89px" }}>
                                                <div className="image-additional">
                                                    <img src="https://restosleep.in/image/cache/catalog/Products/Memory%20Puf/Memory%20Puf%201-800x1040.jpg" title="Memory Puf" data-image="https://restosleep.in/image/cache/catalog/Products/Memory%20Puf/Memory%20Puf%201-800x1040.jpg" data-zoom-image="https://restosleep.in/image/cache/catalog/Products/Memory%20Puf/Memory%20Puf%201-800x1040.jpg" alt="Memory Puf" />
                                                </div>
                                            </div><div className="owl-item active" style={{ width: "89px" }} ><div className="image-additional">
                                                <img src="https://restosleep.in/image/cache/catalog/Products/Memory%20Puf/Memory%20Puf%202-285x371.jpg" title="Memory Puf" data-image="https://restosleep.in/image/cache/catalog/Products/Memory%20Puf/Memory%20Puf%202-800x1040.jpg" data-zoom-image="https://restosleep.in/image/cache/catalog/Products/Memory%20Puf/Memory%20Puf%202-800x1040.jpg" alt="Memory Puf" />
                                            </div></div><div className="owl-item active" style={{ width: "89px" }}><div className="image-additional">
                                                <img src="https://restosleep.in/image/cache/catalog/Products/Memory%20Puf/Memory%20Puf%203-285x371.jpg" title="Memory Puf" data-image="https://restosleep.in/image/cache/catalog/Products/Memory%20Puf/Memory%20Puf%203-800x1040.jpg" data-zoom-image="https://restosleep.in/image/cache/catalog/Products/Memory%20Puf/Memory%20Puf%203-800x1040.jpg" alt="Memory Puf" />
                                            </div></div><div className="owl-item" style={{ width: "89px" }}><div className="image-additional">
                                                <img src="https://restosleep.in/image/cache/catalog/Products/Memory%20Puf/Memory%20Puf%204-285x371.jpg" title="Memory Puf" data-image="https://restosleep.in/image/cache/catalog/Products/Memory%20Puf/Memory%20Puf%204-800x1040.jpg" data-zoom-image="https://restosleep.in/image/cache/catalog/Products/Memory%20Puf/Memory%20Puf%204-800x1040.jpg" alt="Memory Puf" />
                                            </div></div></div></div><div className="owl-nav"><button type="button" role="presentation" className="owl-prev disabled">Prev<span aria-label="Previous">‹</span></button><button type="button" role="presentation" className="owl-next">Next<span aria-label="Next">›</span></button></div><div className="owl-dots disabled"></div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-7 col-md-7 product-details">
                                <h1 className="product-name">{product.name}</h1>
                                <table className="product-info">
                                    <tbody><tr><td>Brands</td><td className="product-info-value"><a href="https://restosleep.in/index.php?route=product/manufacturer/info&amp;manufacturer_id=8">Rest O Sleep</a></td></tr>
                                        <tr><td>Product Code:</td><td className="product-info-value">{product.sku}</td></tr>
                                        <tr><td>Availability:</td><td className="product-info-value">In Stock</td></tr>
                                    </tbody></table>
                                <div className="rating">
                                    <div className="product-rating">
                                        <span className="fa fa-stack"><i className="fa fa-star fa-stack-1x"></i></span>
                                        <span className="fa fa-stack"><i className="fa fa-star fa-stack-1x"></i></span>
                                        <span className="fa fa-stack"><i className="fa fa-star fa-stack-1x"></i></span>
                                        <span className="fa fa-stack"><i className="fa fa-star fa-stack-1x"></i></span>
                                        <span className="fa fa-stack"><i className="fa fa-star off fa-stack-1x"></i></span>
                                    </div>
                                    <a className="product-total-review" href="#" ><i className="fa fa-comment"></i>3 reviews</a>
                                    <a className="product-write-review" href="#" ><i className="fa fa-pencil"></i>Write a review</a>
                                </div>

                                <ul className="list-unstyled product-price">
                                    <li>
                                        <h2 className="holder_price_reg">Rs {Math.round(product.displayPrice)} </h2>
                                    </li>
                                    <li className="product-tax">Ex Tax: Rs 10,712.00</li>
                                </ul>

                                {/* <div id="product" className="">
                                    <div className="form-group required ">
                                        <label className="control-label" for="input-option241">SIZE</label>
                                        <div className="select-wrapper"><select name="option[241]" id="input-option241" className="form-control">
                                            <option value=""> --- Please Select --- </option>
                                            <option value="331">
                                                72 x 30 x 6 Inch

                                            </option>
                                            <option value="300">
                                                72 x 30 x 5 Inch

                                            </option>
                                            <option value="332">
                                                72 x 36 x 6 Inch

                                            </option>
                                            <option value="301">
                                                72 x 36 x 5 Inch

                                            </option>
                                            <option value="333">
                                                72 x 42 x 6 Inch

                                            </option>
                                            <option value="302">
                                                72 x 42 x 5 Inch

                                            </option>
                                            <option value="303">
                                                72 x 48 x 5 Inch

                                            </option>
                                            <option value="334">
                                                72 x 48 x 6 Inch

                                            </option>
                                            <option value="335">
                                                72 x 54 x 6 Inch

                                            </option>
                                            <option value="304">
                                                72 x 54 x 5 Inch

                                            </option>
                                            <option value="200">
                                                72  x 60 x 5 Inch

                                            </option>
                                            <option value="201">
                                                72  x 60 x 6 Inch

                                            </option>
                                            <option value="305">
                                                72 x 66 x 5 Inch

                                            </option>
                                            <option value="336">
                                                72 x 66 x 6 Inch

                                            </option>
                                            <option value="337">
                                                72 x 72 x 6 Inch

                                            </option>
                                            <option value="306">
                                                72 x 72 x 5 Inch

                                            </option>
                                            <option value="307">
                                                75 x 30 x 5 Inch

                                            </option>
                                            <option value="338">
                                                75 x 30 x 6 Inch

                                            </option>
                                            <option value="308">
                                                75 x 36 x 5 Inch

                                            </option>
                                            <option value="339">
                                                75 x 36 x 6 Inch

                                            </option>
                                            <option value="309">
                                                75x 42 x 5 Inch

                                            </option>
                                            <option value="340">
                                                75x 42 x 6 Inch

                                            </option>
                                            <option value="341">
                                                75 x 48 x 6 Inch

                                            </option>
                                            <option value="310">
                                                75 x 48 x 5 Inch

                                            </option>
                                            <option value="311">
                                                75 x 54 x 5 Inch

                                            </option>
                                            <option value="342">
                                                75 x 54 x 6 Inch

                                            </option>
                                            <option value="343">
                                                75 x 60 x 6 Inch

                                            </option>
                                            <option value="312">
                                                75 x 60 x 5 Inch

                                            </option>
                                            <option value="344">
                                                75 x 66 x 6 Inch

                                            </option>
                                            <option value="313">
                                                75 x 66 x 5 Inch

                                            </option>
                                            <option value="345">
                                                75 x 72 x 6 Inch

                                            </option>
                                            <option value="314">
                                                75 x 72 x 5 Inch

                                            </option>
                                            <option value="315">
                                                78 x 30 x 5 Inch

                                            </option>
                                            <option value="346">
                                                78 x 30 x 6 Inch

                                            </option>
                                            <option value="316">
                                                78 x 36 x 5 Inch

                                            </option>
                                            <option value="347">
                                                78 x 36 x 6 Inch

                                            </option>
                                            <option value="317">
                                                78 x 42 x 5 Inch

                                            </option>
                                            <option value="348">
                                                78 x 42 x 6 Inch

                                            </option>
                                            <option value="318">
                                                78 x 48 x 5 Inch

                                            </option>
                                            <option value="349">
                                                78 x 48 x 6 Inch

                                            </option>
                                            <option value="350">
                                                78 x 54 x 6 Inch

                                            </option>
                                            <option value="319">
                                                78 x 54 x 5 Inch

                                            </option>
                                            <option value="320">
                                                78 x 60 x 5 Inch

                                            </option>
                                            <option value="351">
                                                78 x 60 x 6 Inch

                                            </option>
                                            <option value="352">
                                                78 x 66 x 6 Inch

                                            </option>
                                            <option value="321">
                                                78 x 66 x 5 Inch

                                            </option>
                                            <option value="322">
                                                78 x 72 x 5 Inch

                                            </option>
                                            <option value="353">
                                                78 x 72 x 6 Inch

                                            </option>
                                            <option value="323">
                                                84 x 30 x 5 Inch

                                            </option>
                                            <option value="354">
                                                84 x 30 x 6 Inch

                                            </option>
                                            <option value="355">
                                                84 x 36 x 6 Inch

                                            </option>
                                            <option value="324">
                                                84 x 36 x 5 Inch

                                            </option>
                                            <option value="325">
                                                84 x 42 x 5 Inch

                                            </option>
                                            <option value="356">
                                                84 x 42 x 6 Inch

                                            </option>
                                            <option value="357">
                                                84 x 48 x 6 Inch

                                            </option>
                                            <option value="326">
                                                84 x 48 x 5 Inch

                                            </option>
                                            <option value="358">
                                                84 x 54 x 6 Inch

                                            </option>
                                            <option value="327">
                                                84 x 54 x 5 Inch

                                            </option>
                                            <option value="359">
                                                84 x 60 x 6 Inch

                                            </option>
                                            <option value="328">
                                                84 x 60 x 5 Inch

                                            </option>
                                            <option value="329">
                                                84 x 66 x 5 Inch

                                            </option>
                                            <option value="360">
                                                84 x 66 x 6 Inch

                                            </option>
                                            <option value="361">
                                                84 x 72 x 6 Inch

                                            </option>
                                            <option value="330">
                                                84 x 72 x 5 Inch

                                            </option>
                                        </select></div>
                                    </div>
                                    <div className="form-group product-quantity">
                                        <label className="control-label" for="input-quantity">Qty</label>
                                        <input type="text" name="quantity" value="1" size="2" id="input-quantity" className="form-control" />
                                        <input type="hidden" name="product_id" value="52" />
                                        <button type="button" id="button-cart" className="btn btn-primary  btn-lg">
                                            Add to Cart </button>
                                    </div>
                                </div> */}
                                <Form noValidate validated={validated} onSubmit={handleSubmit} className='product-options'>
                                    <h3>Available Options</h3>
                                    <div class="fillter-option">
                                        <RenderOptions product={product} productOptions={productOptions} selectedOption={selectedOption} setSelectedOption={setSelectedOption} priceUpdater={updatePrice} />
                                        {/* <div class="cart-quantity">
                                            <p>Quantity</p>
                                            <ItemCounter quantity={quantity} minQuantity={product.minimumQuantity} increase={increaseQuantity} decrease={decreaseQuantity} />
                                        </div> */}
                                    </div>
                                    <div className="form-group product-quantity">
                                        <label className="control-label" for="input-quantity">Qty</label>
                                        <input type="text" name="quantity" value="1" size="2" id="input-quantity" className="form-control" />
                                        <input type="hidden" name="product_id" value="52" />
                                        <button type="button" id="button-cart" className="btn btn-primary  btn-lg">
                                            Add to Cart </button>
                                    </div>
                                </Form>
                                <div className="btn-group">
                                    <button className="btn btn-default product-btn-wishlist" type="button" title="Add to Wish List" onclick="wishlist.add('52');"><i className="fa fa-heart"></i>
                                        Add to Wish List
                                    </button>
                                    <button className="btn btn-default product-btn-compare" type="button" title="Compare this Product" onclick="compare.add('52');"><i className="fa fa-exchange"></i>
                                        Compare this Product
                                    </button>
                                </div>
                                {/* <button type="button" id="button-cart" className="btn btn-primary  btn-lg" onClick={()=>navigate(`/customize/${slugify(product.name)}?pid=${product.id}`)}>
                                    customize </button> */}
                            </div>

                        </div>
                    </div>


                    <div className="col-sm-12 product-tabs">
                        <ul className="nav nav-tabs">
                            <li className="active"><a href="#tab-description" data-toggle="tab">Description</a></li>
                            <li className="li-tab-review"><a href="#tab-review" data-toggle="tab">Reviews (3)</a></li>
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane active" id="tab-description">{product.description}</div>
                            <div className="tab-pane" id="tab-review">
                                {product.description}
                            </div>
                        </div>
                    </div>
                </div>
                <span onClick={()=>navigate(`/customize/${slugify(product.name)}?pid=${product.id}`)}>.</span>
            </div>
        </>
    )
}
