import React from 'react'
import { Col, Form } from 'react-bootstrap'

export default function TextArea({ option, isrequired, handleInput }) {
    const inputHandler = (e) => {
        handleInput(e.target.value, option)
    }
    return (
        <>
            <Col xs="auto" className="mb-3 me-1">
                <label className="fw-bold form-label">{option.name}</label>
                <Form.Control as='textarea' type="text" size='sm' required={isrequired} onChange={inputHandler} />
                <div class="invalid-feedback">Please select {option.name}</div>
            </Col>
        </>
    )
}
