import React from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

const Restolite = () => {
  const navigate = useNavigate();

  const handleHome = () => {
    navigate("/");
  };
  return (
    <>
      <div class="header-content-title">
        <div class="page-title-wrapper">
          <div class="container">
            <div class="breadcrub">
              <h1 class="page-title">resto lite</h1>
              <ul class="breadcrumb">
                <li>
                  <Link to="/">
                    <i class="fa fa-home"></i>
                  </Link>
                </li>
                <li>
                  <Link to="/accessories">Accessories</Link>
                </li>
                <li>
                  <a class="last-breadcrumb">resto lite</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div id="product-product" class="container product-product">
        <div class="row">
          <div id="content" class="col-sm-12 Cwidth" style={{ width: "100%" }}>
            {/* Product row START --> <!-- */}
            <div class="row">
              <div class="col-xs-12 col-sm-5 col-md-5 product-images">
                {/* <!-- Product Image thumbnails START --> */}
                <div class="thumbnails">
                  <div class="product-image">
                    <a class="thumbnail">
                      <img
                        src="/assets/Acc one.jpg"
                        title="resto lite"
                        data-zoom-image="https://restosleep.in/image/cache/catalog/Products/restolite/restolite%20(1)-800x1040.jpg"
                        alt="resto lite"
                      />
                    </a>
                  </div>

                  <div class="additional-images-container">
                    <div class="additional-images owl-loaded owl-carousel owl-drag">
                      <div class="owl-stage-outer">
                        <div
                          class="owl-stage"
                          style={{
                            transform: "translate3d(0px, 0px, 0px)",
                            transition: "all 0s ease 0s",
                            width: "356px",
                          }}
                        >
                          <div
                            class="owl-item active"
                            style={{ width: "89px" }}
                          >
                            <div class="image-additional">
                              <img
                                src="/assets/acc two.jpg"
                                title="resto lite"
                                data-image="https://restosleep.in/image/cache/catalog/Products/restolite/restolite%20(1)-800x1040.jpg"
                                data-zoom-image="https://restosleep.in/image/cache/catalog/Products/restolite/restolite%20(1)-800x1040.jpg"
                                alt="resto lite"
                              />
                            </div>
                          </div>
                          <div
                            class="owl-item active"
                            style={{ width: "89px" }}
                          >
                            <div class="image-additional">
                              <img
                                src="/assets/acc three.jpg"
                                title="resto lite"
                                data-image="https://restosleep.in/image/cache/catalog/Products/restolite/restolite%20(4)-800x1040.jpg"
                                data-zoom-image="https://restosleep.in/image/cache/catalog/Products/restolite/restolite%20(4)-800x1040.jpg"
                                alt="resto lite"
                              />
                            </div>
                          </div>
                          <div
                            class="owl-item active"
                            style={{ width: "89px" }}
                          >
                            <div class="image-additional">
                              <img
                                src="/assets/acc two.jpg"
                                title="resto lite"
                                data-image="https://restosleep.in/image/cache/catalog/Products/restolite/restolite%20(3)-800x1040.jpg"
                                data-zoom-image="https://restosleep.in/image/cache/catalog/Products/restolite/restolite%20(3)-800x1040.jpg"
                                alt="resto lite"
                              />
                            </div>
                          </div>
                          <div class="owl-item" style={{ width: "89px" }}>
                            <div class="image-additional">
                              <img
                                src="/assets/six.jpg"
                                title="resto lite"
                                data-image="https://restosleep.in/image/cache/catalog/Products/restolite/restolite%20(2)-800x1040.jpg"
                                data-zoom-image="https://restosleep.in/image/cache/catalog/Products/restolite/restolite%20(2)-800x1040.jpg"
                                alt="resto lite"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="owl-nav">
                        <button
                          type="button"
                          role="presentation"
                          class="owl-prev disabled"
                        >
                          Prev<span aria-label="Previous">‹</span>
                        </button>
                        <button
                          type="button"
                          role="presentation"
                          class="owl-next"
                        >
                          Next<span aria-label="Next">›</span>
                        </button>
                      </div>
                      <div class="owl-dots disabled"></div>
                    </div>
                  </div>
                  {/* <!-- Product Image thumbnails END --> */}
                </div>
              </div>

              <div class="col-xs-12 col-sm-7 col-md-7 product-details">
                <h1 class="product-name">resto lite</h1>

                <table class="product-info">
                  <tbody>
                    <tr>
                      <td>Brands</td>
                      <td class="product-info-value">
                        <a>Rest O Sleep</a>
                      </td>
                    </tr>
                    <tr>
                      <td>Product Code:</td>
                      <td class="product-info-value">PC004</td>
                    </tr>
                    <tr>
                      <td>Availability:</td>
                      <td class="product-info-value">In Stock</td>
                    </tr>
                  </tbody>
                </table>

                {/* <!-- Product Rating START --> */}
                <div class="rating">
                  <div class="product-rating">
                    <span class="fa fa-stack">
                      <i class="fa fa-star off fa-stack-1x"></i>
                    </span>
                    <span class="fa fa-stack">
                      <i class="fa fa-star off fa-stack-1x"></i>
                    </span>
                    <span class="fa fa-stack">
                      <i class="fa fa-star off fa-stack-1x"></i>
                    </span>
                    <span class="fa fa-stack">
                      <i class="fa fa-star off fa-stack-1x"></i>
                    </span>
                    <span class="fa fa-stack">
                      <i class="fa fa-star off fa-stack-1x"></i>
                    </span>
                  </div>
                  <a
                    class="product-total-review"

                    // onclick="$(&#39;a[href=\&#39;#tab-review\&#39;]&#39;).trigger(&#39;click&#39;); return false;"
                  >
                    <i class="fa fa-comment"></i>0 reviews
                  </a>
                  <a
                    class="product-write-review"

                    // onclick="$(&#39;a[href=\&#39;#tab-review\&#39;]&#39;).trigger(&#39;click&#39;); return false;"
                  >
                    <i class="fa fa-pencil"></i>Write a review
                  </a>
                </div>
                {/* <!-- Product Rating END --> */}

                <ul class="list-unstyled product-price">
                  <li>
                    <h2 class="holder_price_reg">Rs 1,049.00</h2>
                  </li>
                  <li class="product-tax">Ex Tax: Rs 1,049.00</li>
                </ul>

                {/* <!-- Product Options START --> */}
                <div id="product" class="product-options">
                  <div class="form-group product-quantity">
                    <label class="control-label" for="input-quantity">
                      Qty
                    </label>
                    <input
                      type="text"
                      name="quantity"
                      value="1"
                      size="2"
                      id="input-quantity"
                      class="form-control"
                    />
                    <input type="hidden" name="product_id" value="54" />
                    <button
                      type="button"
                      id="button-cart"
                      data-loading-text="Loading..."
                      class="btn btn-primary btn-lg btn-block"
                    >
                      Add to Cart
                    </button>
                  </div>
                </div>
                {/* <!-- Product Options END --> */}

                {/* <!-- Product Wishlist Compare START --> */}
                <div class="btn-group">
                  <button
                    class="btn btn-default product-btn-wishlist"
                    type="button"
                    title="Add to Wish List"
                    onclick="wishlist.add(&#39;54&#39;);"
                  >
                    <i class="fa fa-heart"></i>
                    Add to Wish List
                  </button>
                  <button
                    class="btn btn-default product-btn-compare"
                    type="button"
                    title="Compare this Product"
                    onclick="compare.add(&#39;54&#39;);"
                  >
                    <i class="fa fa-exchange"></i>
                    Compare this Product
                  </button>
                </div>
                {/* <!-- Product Wishlist Compare END --> */}

                {/* <!-- AddThis Button BEGIN --> */}
                <div
                  class="addthis_toolbox addthis_default_style"
                  data-url="https://restosleep.in/index.php?route=product/product&amp;product_id=54"
                >
                  {/* <a
                    class="addthis_button_facebook_like"
                    fb:like:layout="button_count"
                  ></a> */}
                  <a class="addthis_button_tweet"></a>
                  <a class="addthis_button_pinterest_pinit"></a>
                  <a class="addthis_counter addthis_pill_style"></a>
                </div>
                <script src="./resto lite_files/addthis_widget.js.download"></script>
                {/* <!-- AddThis Button END --> */}
              </div>
              {/* <!-- Product option details END --> */}
            </div>
            {/* <!-- Product row END --> */}
          </div>
          {/* <!-- id content END --> */}
          {/* <!-- Product nav Tabs START --> */}
          <div class="col-sm-12 product-tabs">
            <ul class="nav nav-tabs">
              <li class="active">
                <a data-toggle="tab">Description</a>
              </li>
              <li class="li-tab-review">
                <a data-toggle="tab">Reviews (0)</a>
              </li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane active" id="tab-description">
                <p>
                  <span style={{ fontSize: "14px" }}>
                    Restolite multi-purpose, dual-sided slim mattress can be
                    used as a place to unwind with a book, to meditate, as an
                    extra seating for that movie watch party or simply to laze
                    around. Just like its name, Lite, it is easy to store, it
                    can either be rolled up or folded.
                  </span>
                  <br />
                </p>
              </div>
              <div class="tab-pane" id="tab-review">
                <form class="form-horizontal" id="form-review">
                  <div id="review">
                    <b>Unknown</b>: Creation of dynamic property
                    Request::$request is deprecated in
                    <b>
                      /home1/salafcwj/restosleep.in/system/library/request.php
                    </b>
                    on line <b>26</b>
                    <b>Unknown</b>: Creation of dynamic property Session\DB::$db
                    is deprecated in
                    <b>
                      /home1/salafcwj/restosleep.in/system/library/session/db.php
                    </b>
                    on line <b>15</b>
                    <b>Warning</b>: Cannot modify header information - headers
                    already sent by (output started at
                    /home1/salafcwj/restosleep.in/system/framework.php:42) in
                    <b>/home1/salafcwj/restosleep.in/system/framework.php</b> on
                    line <b>108</b>
                    <b>Unknown</b>: Creation of dynamic property
                    Openbay::$logger is deprecated in
                    <b>
                      /home1/salafcwj/restosleep.in/system/library/openbay.php
                    </b>
                    on line <b>22</b>
                    <b>Unknown</b>: Creation of dynamic property
                    Proxy::$getTranslations is deprecated in
                    <b>/home1/salafcwj/restosleep.in/system/engine/proxy.php</b>
                    on line <b>30</b>
                    <b>Unknown</b>: Creation of dynamic property
                    Proxy::$__construct is deprecated in
                    <b>/home1/salafcwj/restosleep.in/system/engine/proxy.php</b>
                    on line <b>30</b>
                    <b>Unknown</b>: Creation of dynamic property Proxy::$__get
                    is deprecated in
                    <b>/home1/salafcwj/restosleep.in/system/engine/proxy.php</b>
                    on line <b>30</b>
                    <b>Unknown</b>: Creation of dynamic property Proxy::$__set
                    is deprecated in
                    <b>/home1/salafcwj/restosleep.in/system/engine/proxy.php</b>
                    on line <b>30</b>
                    <b>Unknown</b>: mysqli::real_escape_string(): Passing null
                    to parameter #1 ($string) of type string is deprecated in
                    <b>
                      /home1/salafcwj/restosleep.in/system/library/db/mysqli.php
                    </b>
                    on line <b>45</b>
                    <b>Unknown</b>: Creation of dynamic property
                    Openbay::$logger is deprecated in
                    <b>
                      /home1/salafcwj/restosleep.in/system/library/openbay.php
                    </b>
                    on line <b>22</b>
                    <b>Warning</b>: Cannot modify header information - headers
                    already sent by (output started at
                    /home1/salafcwj/restosleep.in/system/framework.php:42) in
                    <b>
                      /home1/salafcwj/restosleep.in/catalog/controller/startup/session.php
                    </b>
                    on line <b>25</b>
                    <b>Unknown</b>: Creation of dynamic property
                    Proxy::$getLanguage is deprecated in
                    <b>/home1/salafcwj/restosleep.in/system/engine/proxy.php</b>
                    on line <b>30</b>
                    <b>Unknown</b>: Creation of dynamic property
                    Proxy::$getLanguages is deprecated in
                    <b>/home1/salafcwj/restosleep.in/system/engine/proxy.php</b>
                    on line <b>30</b>
                    <b>Unknown</b>: Creation of dynamic property
                    Proxy::$__construct is deprecated in
                    <b>/home1/salafcwj/restosleep.in/system/engine/proxy.php</b>
                    on line <b>30</b>
                    <b>Unknown</b>: Creation of dynamic property Proxy::$__get
                    is deprecated in
                    <b>/home1/salafcwj/restosleep.in/system/engine/proxy.php</b>
                    on line <b>30</b>
                    <b>Unknown</b>: Creation of dynamic property Proxy::$__set
                    is deprecated in
                    <b>/home1/salafcwj/restosleep.in/system/engine/proxy.php</b>
                    on line <b>30</b>
                    <b>Unknown</b>: Automatic conversion of false to array is
                    deprecated in
                    <b>
                      /home1/salafcwj/restosleep.in/catalog/controller/startup/startup.php
                    </b>
                    on line <b>95</b>
                    <b>Warning</b>: Cannot modify header information - headers
                    already sent by (output started at
                    /home1/salafcwj/restosleep.in/system/framework.php:42) in
                    <b>
                      /home1/salafcwj/restosleep.in/catalog/controller/startup/startup.php
                    </b>
                    on line <b>99</b>
                    <b>Unknown</b>: Creation of dynamic property
                    Cart\Customer::$config is deprecated in
                    <b>
                      /home1/salafcwj/restosleep.in/system/library/cart/customer.php
                    </b>
                    on line <b>14</b>
                    <b>Unknown</b>: Creation of dynamic property
                    Cart\Customer::$db is deprecated in
                    <b>
                      /home1/salafcwj/restosleep.in/system/library/cart/customer.php
                    </b>
                    on line <b>15</b>
                    <b>Unknown</b>: Creation of dynamic property
                    Cart\Customer::$request is deprecated in
                    <b>
                      /home1/salafcwj/restosleep.in/system/library/cart/customer.php
                    </b>
                    on line <b>16</b>
                    <b>Unknown</b>: Creation of dynamic property
                    Cart\Customer::$session is deprecated in
                    <b>
                      /home1/salafcwj/restosleep.in/system/library/cart/customer.php
                    </b>
                    on line <b>17</b>
                    <b>Unknown</b>: Creation of dynamic property
                    Proxy::$getCurrencyByCode is deprecated in
                    <b>/home1/salafcwj/restosleep.in/system/engine/proxy.php</b>
                    on line <b>30</b>
                    <b>Unknown</b>: Creation of dynamic property
                    Proxy::$getCurrencies is deprecated in
                    <b>/home1/salafcwj/restosleep.in/system/engine/proxy.php</b>
                    on line <b>30</b>
                    <b>Unknown</b>: Creation of dynamic property
                    Proxy::$__construct is deprecated in
                    <b>/home1/salafcwj/restosleep.in/system/engine/proxy.php</b>
                    on line <b>30</b>
                    <b>Unknown</b>: Creation of dynamic property Proxy::$__get
                    is deprecated in
                    <b>/home1/salafcwj/restosleep.in/system/engine/proxy.php</b>
                    on line <b>30</b>
                    <b>Unknown</b>: Creation of dynamic property Proxy::$__set
                    is deprecated in
                    <b>/home1/salafcwj/restosleep.in/system/engine/proxy.php</b>
                    on line <b>30</b>
                    <b>Warning</b>: Cannot modify header information - headers
                    already sent by (output started at
                    /home1/salafcwj/restosleep.in/system/framework.php:42) in
                    <b>
                      /home1/salafcwj/restosleep.in/catalog/controller/startup/startup.php
                    </b>
                    on line <b>157</b>
                    <b>Unknown</b>: Creation of dynamic property
                    Cart\Currency::$db is deprecated in
                    <b>
                      /home1/salafcwj/restosleep.in/system/library/cart/currency.php
                    </b>
                    on line <b>7</b>
                    <b>Unknown</b>: Creation of dynamic property
                    Cart\Currency::$language is deprecated in
                    <b>
                      /home1/salafcwj/restosleep.in/system/library/cart/currency.php
                    </b>
                    on line <b>8</b>
                    <b>Unknown</b>: Creation of dynamic property
                    Cart\Tax::$config is deprecated in
                    <b>
                      /home1/salafcwj/restosleep.in/system/library/cart/tax.php
                    </b>
                    on line <b>7</b>
                    <b>Unknown</b>: Creation of dynamic property Cart\Tax::$db
                    is deprecated in
                    <b>
                      /home1/salafcwj/restosleep.in/system/library/cart/tax.php
                    </b>
                    on line <b>8</b>
                    <b>Unknown</b>: Creation of dynamic property
                    Cart\Weight::$db is deprecated in
                    <b>
                      /home1/salafcwj/restosleep.in/system/library/cart/weight.php
                    </b>
                    on line <b>7</b>
                    <b>Unknown</b>: Creation of dynamic property
                    Cart\Weight::$config is deprecated in
                    <b>
                      /home1/salafcwj/restosleep.in/system/library/cart/weight.php
                    </b>
                    on line <b>8</b>
                    <b>Unknown</b>: Creation of dynamic property
                    Cart\Length::$db is deprecated in
                    <b>
                      /home1/salafcwj/restosleep.in/system/library/cart/length.php
                    </b>
                    on line <b>7</b>
                    <b>Unknown</b>: Creation of dynamic property
                    Cart\Length::$config is deprecated in
                    <b>
                      /home1/salafcwj/restosleep.in/system/library/cart/length.php
                    </b>
                    on line <b>8</b>
                    <b>Unknown</b>: Creation of dynamic property
                    Cart\Cart::$config is deprecated in
                    <b>
                      /home1/salafcwj/nstorage/modification/system/library/cart/cart.php
                    </b>
                    on line <b>7</b>
                    <b>Unknown</b>: Creation of dynamic property
                    Cart\Cart::$customer is deprecated in
                    <b>
                      /home1/salafcwj/nstorage/modification/system/library/cart/cart.php
                    </b>
                    on line <b>8</b>
                    <b>Unknown</b>: Creation of dynamic property
                    Cart\Cart::$session is deprecated in
                    <b>
                      /home1/salafcwj/nstorage/modification/system/library/cart/cart.php
                    </b>
                    on line <b>9</b>
                    <b>Unknown</b>: Creation of dynamic property Cart\Cart::$db
                    is deprecated in
                    <b>
                      /home1/salafcwj/nstorage/modification/system/library/cart/cart.php
                    </b>
                    on line <b>10</b>
                    <b>Unknown</b>: Creation of dynamic property Cart\Cart::$tax
                    is deprecated in
                    <b>
                      /home1/salafcwj/nstorage/modification/system/library/cart/cart.php
                    </b>
                    on line <b>11</b>
                    <b>Unknown</b>: Creation of dynamic property
                    Cart\Cart::$weight is deprecated in
                    <b>
                      /home1/salafcwj/nstorage/modification/system/library/cart/cart.php
                    </b>
                    on line <b>12</b>
                    <b>Unknown</b>: Creation of dynamic property
                    Openbay::$logger is deprecated in
                    <b>
                      /home1/salafcwj/restosleep.in/system/library/openbay.php
                    </b>
                    on line <b>22</b>
                    <b>Unknown</b>: Creation of dynamic property
                    Proxy::$getEvents is deprecated in
                    <b>/home1/salafcwj/restosleep.in/system/engine/proxy.php</b>
                    on line <b>30</b>
                    <b>Unknown</b>: Creation of dynamic property
                    Proxy::$__construct is deprecated in
                    <b>/home1/salafcwj/restosleep.in/system/engine/proxy.php</b>
                    on line <b>30</b>
                    <b>Unknown</b>: Creation of dynamic property Proxy::$__get
                    is deprecated in
                    <b>/home1/salafcwj/restosleep.in/system/engine/proxy.php</b>
                    on line <b>30</b>
                    <b>Unknown</b>: Creation of dynamic property Proxy::$__set
                    is deprecated in
                    <b>/home1/salafcwj/restosleep.in/system/engine/proxy.php</b>
                    on line <b>30</b>
                    <b>Unknown</b>: Creation of dynamic property
                    Proxy::$addReview is deprecated in
                    <b>/home1/salafcwj/restosleep.in/system/engine/proxy.php</b>
                    on line <b>30</b>
                    <b>Unknown</b>: Creation of dynamic property
                    Proxy::$getReviewsByProductId is deprecated in
                    <b>/home1/salafcwj/restosleep.in/system/engine/proxy.php</b>
                    on line <b>30</b>
                    <b>Unknown</b>: Creation of dynamic property
                    Proxy::$getTotalReviewsByProductId is deprecated in
                    <b>/home1/salafcwj/restosleep.in/system/engine/proxy.php</b>
                    on line <b>30</b>
                    <b>Unknown</b>: Creation of dynamic property
                    Proxy::$__construct is deprecated in
                    <b>/home1/salafcwj/restosleep.in/system/engine/proxy.php</b>
                    on line <b>30</b>
                    <b>Unknown</b>: Creation of dynamic property Proxy::$__get
                    is deprecated in
                    <b>/home1/salafcwj/restosleep.in/system/engine/proxy.php</b>
                    on line <b>30</b>
                    <b>Unknown</b>: Creation of dynamic property Proxy::$__set
                    is deprecated in
                    <b>/home1/salafcwj/restosleep.in/system/engine/proxy.php</b>
                    on line <b>30</b>
                    <b>Unknown</b>: Creation of dynamic property
                    Proxy::$getTheme is deprecated in
                    <b>/home1/salafcwj/restosleep.in/system/engine/proxy.php</b>
                    on line <b>30</b>
                    <b>Unknown</b>: Creation of dynamic property
                    Proxy::$__construct is deprecated in
                    <b>/home1/salafcwj/restosleep.in/system/engine/proxy.php</b>
                    on line <b>30</b>
                    <b>Unknown</b>: Creation of dynamic property Proxy::$__get
                    is deprecated in
                    <b>/home1/salafcwj/restosleep.in/system/engine/proxy.php</b>
                    on line <b>30</b>
                    <b>Unknown</b>: Creation of dynamic property Proxy::$__set
                    is deprecated in
                    <b>/home1/salafcwj/restosleep.in/system/engine/proxy.php</b>
                    on line <b>30</b>
                    <b>Unknown</b>: The Twig_Autoloader class is deprecated
                    since version 1.21 and will be removed in 2.0. Use Composer
                    instead. in
                    <b>
                      /home1/salafcwj/restosleep.in/system/library/template/Twig/Autoloader.php
                    </b>
                    on line <b>12</b>
                    <b>Unknown</b>: Using Twig_Autoloader is deprecated since
                    version 1.21. Use Composer instead. in
                    <b>
                      /home1/salafcwj/restosleep.in/system/library/template/Twig/Autoloader.php
                    </b>
                    on line <b>30</b>
                    <p>There are no reviews for this product.</p>
                  </div>
                  <h2>Write a review</h2>
                  Please
                  <a>login</a>
                  or
                  <a>register</a>
                  to review
                </form>
              </div>
            </div>
          </div>
          {/* <!-- Product tab END --> */}
          {/* <!-- Related products START -->
          <!-- Related products END --> */}
          =
        </div>
      </div>
    </>
  );
};

export default Restolite;
