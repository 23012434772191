import React, { useState } from 'react'
import { Outlet } from 'react-router'
import { ToastContainer } from 'react-toastify'
import { useSelector } from 'react-redux'
import Header from '../header/Header'
import Footer from '../footer/Footer'
// import axios from '../assets/axios/instance'

export default function Layout() {
    // const isLogedIn = useSelector(state => state.auth.isLogedIn)
    // const user = useSelector(state => state.auth.user)


    // if (isLogedIn && user.token) {
    //     axios.defaults.headers.common['Authorization'] = "Bearer " + user.token;
    // }

    return (
        <>
            <Header />
            <main className="main__content_wrapper">
                <Outlet />
            </main>
            <Footer />
            <ToastContainer />
        </>
    )
}
