import React from "react";
import { useNavigate } from "react-router";

export default function TermsConditions() {

  const navigate = useNavigate();
  const handleHome = () => {
    
    navigate("/");
  };
  return (
    <>
      <div class="header-content-title">
        <div class="page-title-wrapper">
          <div class="container">
            <div class="breadcrub">
              <h1 class="page-title">Terms &amp; Conditions</h1>
              <ul class="breadcrumb">
                <li>
                  <a onClick={handleHome} style={{cursor:'pointer'}}>
                    <i class="fa fa-home"></i>
                  </a>
                </li>
                <li>
                  <a class="last-breadcrumb">Terms &amp; Conditions</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div id="information-information" class="container">
        <div class="row">
          <div id="content" class="col-sm-12 Cwidth" style={{ width: "100%" }}>
            <p
              class="MsoNormal"
              align="center"
              style={{
                marginBottom: "13.5pt",
                textAlign: "center",
                lineHeight: "25.5pt",
                backgroundImage: "initial",
                backgroundPosition: "initial",
                backgroundSize: "initial",
                backgroundRepeat: "initial",
                backgroundAttachment: "initial",
                backgroundOrigin: "initial",
                backgroundClip: "initial",
              }}
            >
              <b>
                <span
                  style={{
                    fontSize: "18pt",
                    fontFamily: "Arial, sans-serif",
                    msoFareastFontFamily: "'Times New Roman'",
                    color: "#00008a",
                    msFontKerning: "18pt",
                  }}
                >
                  Terms &amp; Conditions
                  {/*  */}
                </span>
              </b>
            </p>

            <p
              class="MsoNormal"
              align="center"
              style={{
                marginBottom: "27pt",
                textAlign: "center",
                lineHeight: "19.5pt",
                backgroundImage: "initial",
                backgroundPosition: "initial",
                backgroundSize: "initial",
                backgroundRepeat: "initial",
                backgroundAttachment: "initial",
                backgroundOrigin: "initial",
                backgroundClip: "initial",
              }}
            >
              <span
                style={{
                  fontSize: "18pt",
                  fontFamily: "'Arial', 'sans-serif'",
                  msoFareastFontFamily: "'Times New Roman'",
                  color: "#00008a",
                }}
              >
                30 Nights Trial
                {/*  */}
              </span>
            </p>

            <p
              class="MsoNormal"
              style={{
                lineHeight: "18pt",
                backgroundImage: "initial",
                backgroundPosition: "initial",
                backgroundSize: "initial",
                backgroundRepeat: "initial",
                backgroundAttachment: "initial",
                backgroundOrigin: "initial",
                backgroundClip: "initial",
              }}
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Arial, sans-serif",
                  msoFareastFontFamily: "Times New Roman",
                  color: "#455a64",
                }}
              >
                Restosleep offers a 30 nights trial for all the mattress sold on
                the website. For all eligible purchases, customers are advised
                to contact customer service within 30 calendar days from the
                time of purchase. Cost of shipping will be borne by the company,
                so as long as the product has not been moved out of the premises
                of the original delivery location. In all other cases, the
                customer will bear the differential shipping charges. We reserve
                the right to change or withdraw the 30 night’s trial program at
                any time.
                {/*  */}
              </span>
            </p>

            <p
              class="MsoNormal"
              align="center"
              style={{
                marginBottom: "27pt",
                textAlign: "center",
                lineHeight: "19.5pt",
                backgroundImage: "initial",
                backgroundPosition: "initial",
                backgroundSize: "initial",
                backgroundRepeat: "initial",
                backgroundAttachment: "initial",
                backgroundOrigin: "initial",
                backgroundClip: "initial",
              }}
            >
              <span
                style={{
                  fontSize: "18pt",
                  fontFamily: "'Arial', 'sans-serif'",
                  msoFareastFontFamily: "'Times New Roman'",
                  color: "#00008a",
                }}
              >
                <br />
              </span>
            </p>
            <p
              class="MsoNormal"
              align="center"
              style={{
                marginBottom: "27pt",
                textAlign: "center",
                lineHeight: "19.5pt",
                backgroundImage: "initial",
                backgroundPosition: "initial",
                backgroundSize: "initial",
                backgroundRepeat: "initial",
                backgroundAttachment: "initial",
                backgroundOrigin: "initial",
                backgroundClip: "initial",
              }}
            >
              <span
                style={{
                  fontSize: "18pt",
                  fontFamily: "'Arial', 'sans-serif'",
                  msoFareastFontFamily: "'Times New Roman'",
                  color: "#00008a",
                }}
              >
                Process for Returns
                {/*  */}
              </span>
            </p>

            <p
              class="MsoNormal"
              style={{
                lineHeight: "18pt",
                backgroundImage: "initial",
                backgroundPosition: "initial",
                backgroundSize: "initial",
                backgroundRepeat: "initial",
                backgroundAttachment: "initial",
                backgroundOrigin: "initial",
                backgroundClip: "initial",
              }}
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Arial, sans-serif",
                  msoFareastFontFamily: "Times New Roman",
                  color: "#455a64",
                }}
              >
                Please write to&nbsp;
              </span>
              <a href="mailto:support@restosleep.in">
                <b>
                  <span
                    style={{
                      fontSize: "12pt",
                      fontFamily: "Arial, sans-serif",
                      msoFareastFontFamily: "Times New Roman",
                      // color: "#455a64",
                    }}
                  >
                    support@restosleep.in
                  </span>
                </b>
              </a>
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Arial, sans-serif",
                  msoFareastFontFamily: "Times New Roman",
                  color: "#455a64",
                }}
              >
                with your order details within 30 calendar days from date of
                purchase to register your return request.
                {/*  */}
              </span>
            </p>

            <p
              class="MsoNormal"
              align="center"
              style={{
                marginBottom: "27pt",
                textAlign: "center",
                lineHeight: "19.5pt",
                backgroundImage: "initial",
                backgroundPosition: "initial",
                backgroundSize: "initial",
                backgroundRepeat: "initial",
                backgroundAttachment: "initial",
                backgroundOrigin: "initial",
                backgroundClip: "initial",
              }}
            >
              <span
                style={{
                  fontSize: "18pt",
                  fontFamily: "'Arial', 'sans-serif'",
                  msoFareastFontFamily: "'Times New Roman'",
                  color: "#00008a",
                }}
              >
                <br />
              </span>
            </p>
            <p
              class="MsoNormal"
              align="center"
              style={{
                marginBottom: "27pt",
                textAlign: "center",
                lineHeight: "19.5pt",
                backgroundImage: "initial",
                backgroundPosition: "initial",
                backgroundSize: "initial",
                backgroundRepeat: "initial",
                backgroundAttachment: "initial",
                backgroundOrigin: "initial",
                backgroundClip: "initial",
              }}
            >
              <span
                style={{
                  color: "rgb(0, 0, 138)",
                  fontFamily: "Arial, sans-serif",
                  fontSize: "18pt",
                }}
              >
                Exceptions to our policies (Warranty &amp; 30 Nights Trail)
              </span>
              <br />
            </p>

            <ul type="disc">
              <li
                class="MsoNormal"
                style={{
                  color: "rgb(69, 90, 100)",
                  marginBottom: "6pt",
                  lineHeight: "18pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "'Arial', sans-serif",
                    msoFareastFontFamily: "'Times New Roman'",
                  }}
                >
                  Misused products or tampered products will not be eligible for
                  returns.
                  {/*  */}
                </span>
              </li>
              <li
                class="MsoNormal"
                style={{
                  color: "rgb(69, 90, 100)",
                  lineHeight: "18pt",
                  backgroundImage: "initial",
                  backgroundPosition: "initial",
                  backgroundSize: "initial",
                  backgroundRepeat: "initial",
                  backgroundAttachment: "initial",
                  backgroundOrigin: "initial",
                  backgroundClip: "initial",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "'Arial', sans-serif",
                    msoFareastFontFamily: "'Times New Roman'",
                  }}
                >
                  Any damage or defect not covered under the manufacturer's
                  warranty
                </span>
              </li>
              <li
                class="MsoNormal"
                style={{
                  color: "rgb(69, 90, 100)",
                  lineHeight: "18pt",
                  backgroundImage: "initial",
                  backgroundPosition: "initial",
                  backgroundSize: "initial",
                  backgroundRepeat: "initial",
                  backgroundAttachment: "initial",
                  backgroundOrigin: "initial",
                  backgroundClip: "initial",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "'Arial', sans-serif",
                    msoFareastFontFamily: "'Times New Roman'",
                  }}
                >
                  The original invoice with the company's seal and signature has
                  to be presented for all warranty and return requests
                  {/* <o:p></o:p> */}
                </span>
              </li>
            </ul>

            <p
              class="MsoNormal"
              align="center"
              style={{
                marginBottom: "27pt",
                textAlign: "center",
                lineHeight: "19.5pt",
                backgroundImage: "initial",
                backgroundPosition: "initial",
                backgroundSize: "initial",
                backgroundRepeat: "initial",
                backgroundAttachment: "initial",
                backgroundOrigin: "initial",
                backgroundClip: "initial",
              }}
            >
              <span
                style={{
                  fontSize: "18pt",
                  fontFamily: "'Arial', 'sans-serif'",
                  msoFareastFontFamily: "'Times New Roman'",
                  color: "#00008a",
                }}
              >
                <br />
              </span>
            </p>
            <p
              class="MsoNormal"
              align="center"
              style={{
                marginBottom: "27pt",
                textAlign: "center",
                lineHeight: "19.5pt",
                backgroundImage: "initial",
                backgroundPosition: "initial",
                backgroundSize: "initial",
                backgroundRepeat: "initial",
                backgroundAttachment: "initial",
                backgroundOrigin: "initial",
                backgroundClip: "initial",
              }}
            >
              <span
                style={{
                  fontSize: "18pt",
                  fontFamily: "'Arial', 'sans-serif'",
                  msoFareastFontFamily: "'Times New Roman'",
                  color: "#00008a",
                }}
              >
                Shipping &amp; Transportation
              </span>
            </p>

            <p
              class="MsoNormal"
              style={{
                lineHeight: "18pt",
                backgroundImage: "initial",
                backgroundPosition: "initial",
                backgroundSize: "initial",
                backgroundRepeat: "initial",
                backgroundAttachment: "initial",
                backgroundOrigin: "initial",
                backgroundClip: "initial",
              }}
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Arial, sans-serif",
                  msoFareastFontFamily: "Times New Roman",
                  color: "#455a64",
                }}
              >
                The company takes reasonable care to pack and ship the products
                in a safe manner, the sale is complete once the goods are
                delivered to the customer. If the goods are delivered in damaged
                condition, the customer has to inform the company immediately.
                {/* <o:p></o:p> */}
              </span>
            </p>

            <p
              class="MsoNormal"
              align="center"
              style={{
                marginBottom: "27pt",
                textAlign: "center",
                lineHeight: "19.5pt",
                backgroundImage: "initial",
                backgroundPosition: "initial",
                backgroundSize: "initial",
                backgroundRepeat: "initial",
                backgroundAttachment: "initial",
                backgroundOrigin: "initial",
                backgroundClip: "initial",
              }}
            >
              <span
                style={{
                  fontSize: "18pt",
                  fontFamily: "'Arial', 'sans-serif'",
                  msoFareastFontFamily: "'Times New Roman'",
                  color: "#00008a",
                }}
              >
                <br />
              </span>
            </p>
            <p
              class="MsoNormal"
              align="center"
              style={{
                marginBottom: "27pt",
                textAlign: "center",
                lineHeight: "19.5pt",
                backgroundImage: "initial",
                backgroundPosition: "initial",
                backgroundSize: "initial",
                backgroundRepeat: "initial",
                backgroundAttachment: "initial",
                backgroundOrigin: "initial",
                backgroundClip: "initial",
              }}
            >
              <span
                style={{
                  fontSize: "18pt",
                  fontFamily: "'Arial', 'sans-serif'",
                  msoFareastFontFamily: "'Times New Roman'",
                  color: "#00008a",
                }}
              >
                Applicable law
              </span>
            </p>

            <p
              class="MsoNormal"
              style={{
                lineHeight: "18pt",
                backgroundImage: "initial",
                backgroundPosition: "initial",
                backgroundSize: "initial",
                backgroundRepeat: "initial",
                backgroundAttachment: "initial",
                backgroundOrigin: "initial",
                backgroundClip: "initial",
              }}
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Arial, sans-serif",
                  msoFareastFontFamily: "Times New Roman",
                  color: "#455a64",
                }}
              >
                All sales and interactions with Restosleep coir &amp;foam
                products.shall be governed by and interpreted and construed in
                accordance with the laws of India. In case of any disputes with
                the same shall be subject to courts/authorities/forums in
                Bangalore only.
              </span>
            </p>

            <p
              class="MsoNormal"
              align="center"
              style={{
                marginBottom: "27pt",
                textAlign: "center",
                lineHeight: "19.5pt",
                backgroundImage: "initial",
                backgroundPosition: "initial",
                backgroundSize: "initial",
                backgroundRepeat: "initial",
                backgroundAttachment: "initial",
                backgroundOrigin: "initial",
                backgroundClip: "initial",
              }}
            >
              <span
                style={{
                  fontSize: "18pt",
                  fontFamily: "'Arial', 'sans-serif'",
                  msoFareastFontFamily: "'Times New Roman'",
                  color: "#00008a",
                }}
              >
                <br />
              </span>
            </p>
            <p
              class="MsoNormal"
              align="center"
              style={{
                marginBottom: "27pt",
                textAlign: "center",
                lineHeight: "19.5pt",
                backgroundImage: "initial",
                backgroundPosition: "initial",
                backgroundSize: "initial",
                backgroundRepeat: "initial",
                backgroundAttachment: "initial",
                backgroundOrigin: "initial",
                backgroundClip: "initial",
              }}
            >
              <span
                style={{
                  fontSize: "18pt",
                  fontFamily: "'Arial', 'sans-serif'",
                  msoFareastFontFamily: "'Times New Roman'",
                  color: "#00008a",
                }}
              >
                Registered Office
              </span>
            </p>

            <p
              class="MsoNormal"
              // style="
              //   margin-bottom: 7.5pt;
              //   line-height: 18pt;
              //   background-image: initial;
              //   background-position: initial;
              //   background-size: initial;
              //   background-repeat: initial;
              //   background-attachment: initial;
              //   background-origin: initial;
              //   background-clip: initial;
              // "
              style={{
                marginBottom: "7.5pt",
                lineHeight: "19pt",
                backgaroundImage: "initial",
                backgroundPosition: "initial",
                backgroundSize: "initial",
                backgroundRepeat: "initial",
                backgroundAttachment: "initial",
                backgroundOrigin: "initial",
                backgroundClip: "initial",
              }}
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Arial, sans-serif",
                  msoFareastFontFamily: "Times New Roman",
                  color: "#455a64",
                }}
              >
                Restosleep coir &amp; foam products.
              </span>
            </p>

            <p
              class="MsoNormal"
              // style="
              //   margin-bottom: 7.5pt;
              //   line-height: 18pt;
              //   background-image: initial;
              //   background-position: initial;
              //   background-size: initial;
              //   background-repeat: initial;
              //   background-attachment: initial;
              //   background-origin: initial;
              //   background-clip: initial;
              // "
              style={{
                marginBottom: "7.5pt",
                lineHeight: "19pt",
                backgaroundImage: "initial",
                backgroundPosition: "initial",
                backgroundSize: "initial",
                backgroundRepeat: "initial",
                backgroundAttachment: "initial",
                backgroundOrigin: "initial",
                backgroundClip: "initial",
              }}
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Arial, sans-serif",
                  msoFareastFontFamily: "Times New Roman",
                  color: "#455a64",
                }}
              >
                5<sup>th</sup> cross,
              </span>
            </p>

            <p
              class="MsoNormal"
              // style="
              //   margin-bottom: 7.5pt;
              //   line-height: 18pt;
              //   background-image: initial;
              //   background-position: initial;
              //   background-size: initial;
              //   background-repeat: initial;
              //   background-attachment: initial;
              //   background-origin: initial;
              //   background-clip: initial;
              // "
              style={{
                marginBottom: "7.5pt",
                lineHeight: "19pt",
                backgaroundImage: "initial",
                backgroundPosition: "initial",
                backgroundSize: "initial",
                backgroundRepeat: "initial",
                backgroundAttachment: "initial",
                backgroundOrigin: "initial",
                backgroundClip: "initial",
              }}
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Arial, sans-serif",
                  msoFareastFontFamily: "Times New Roman",
                  color: "#455a64",
                }}
              >
                Shampur road
              </span>
            </p>

            <p
              class="MsoNormal"
              // style="
              //   margin-bottom: 7.5pt;
              //   line-height: 18pt;
              //   background-image: initial;
              //   background-position: initial;
              //   background-size: initial;
              //   background-repeat: initial;
              //   background-attachment: initial;
              //   background-origin: initial;
              //   background-clip: initial;
              // "
              style={{
                marginBottom: "7.5pt",
                lineHeight: "19pt",
                backgaroundImage: "initial",
                backgroundPosition: "initial",
                backgroundSize: "initial",
                backgroundRepeat: "initial",
                backgroundAttachment: "initial",
                backgroundOrigin: "initial",
                backgroundClip: "initial",
              }}
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Arial, sans-serif",
                  msoFareastFontFamily: "Times New Roman",
                  color: "#455a64",
                }}
              >
                Muneshwaranagar
              </span>
            </p>

            <p
              class="MsoNormal"
              // style="
              //   margin-bottom: 7.5pt;
              //   line-height: 18pt;
              //   background-image: initial;
              //   background-position: initial;
              //   background-size: initial;
              //   background-repeat: initial;
              //   background-attachment: initial;
              //   background-origin: initial;
              //   background-clip: initial;
              // "
              style={{
                marginBottom: "7.5pt",
                lineHeight: "19pt",
                backgaroundImage: "initial",
                backgroundPosition: "initial",
                backgroundSize: "initial",
                backgroundRepeat: "initial",
                backgroundAttachment: "initial",
                backgroundOrigin: "initial",
                backgroundClip: "initial",
              }}
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Arial, sans-serif",
                  msoFareastFontFamily: "Times New Roman",
                  color: "#455a64",
                }}
              >
                Bangalore 560045, Karnataka
              </span>
            </p>

            <p
              class="MsoNormal"
              style={{
                lineHeight: "18pt",
                backgroundImage: "initial",
                backgroundPosition: "initial",
                backgroundSize: "initial",
                backgroundRepeat: "initial",
                backgroundAttachment: "initial",
                backgroundOrigin: "initial",
                backgroundClip: "initial",
              }}
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Arial, sans-serif",
                  msoFareastFontFamily: "Times New Roman",
                  color: "#455a64",
                }}
              >
                Email: support@restosleep.in
              </span>
            </p>
          </div>
        </div>
      </div>
      <div id="goToTop" title="Top" class="goToTop ttbox-img" style={{display: "block"}}></div>
    </>
  );
}
