import React from 'react'
import { Col, Form } from 'react-bootstrap'

export default function InputOption({ type, option, isRequired, handleInput }) {
    const inputHandler = (e) => {
        handleInput(e.target.value, option)
    }
    return (
        <>
            <Col xs="auto" className="mb-3 me-1">
                <p className="fw-bold form-p">{option.name}</p>
                <Form.Control type={type} size='sm' required={isRequired} onChange={inputHandler} />
                <div class="invalid-feedback">Please select {option.name}</div>
            </Col>
        </>
    )
}
