import React from "react";
import { Link } from "react-router-dom";

export default function TitleBar({ product, category }) {
  return (
    <div className="header-content-title">
      <div className="page-title-wrapper">
        <div className="container">
          <div className="breadcrub">
            <h1 className="page-title">{product} </h1>
            <ul className="breadcrumb">
              <li>
                <Link to="/">
                  <i className="fa fa-home"></i>
                </Link>
              </li>
              {/* <li>
                <Link to="/mattresses">{category}</Link>
              </li> */}
              <li>
                <a className="last-breadcrumb">{product}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
