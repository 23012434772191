import React from 'react'
import { Col, Form } from 'react-bootstrap'

export default function SelectDropDown({ optionDetails = [], isRequired, option, handleInput }) {
    const handleSelect = (e) => {
        const optionChanges = optionDetails.find(opt => opt.id === +e.target.value)
        handleInput(e.target.value, optionChanges, option)
    }
    return (
        <>
            <div className="select-wrapper">
                <label className="control-label">{option.name}</label>
                <select className='form-control' type="text" size='sm' required={isRequired} onChange={handleSelect} >
                    <option value="">Select</option>
                    {optionDetails.map(options => {
                        if (options.isDeleted) return null
                        return <option key={options.id} value={options.id}>{options.optionValue}</option>
                    })}
                </select>
                <div class="invalid-feedback">Please select {option.name}</div>
            </div>
        </>
    )
}
