import React, { useEffect, useState } from "react";
import instance, { webBaseUrl } from "../assets/axios/axios";
import StoresApi from "../assets/api/StoreApis";
import { Link } from "react-router-dom";
import slugify from "../utilities/urlCreatore";

export default function ProductBlock({ product = {}, noCol }) {
  const [subscription, setSubscription] = useState({});
  const [data, setData] = useState([]);

  // const getData = () => {
  //   instance
  //     .get(`${StoresApi.StoreUrl}/GetStoreByCode/${"Padmlabs"}`)
  //     .then((response) => {
  //       console.log(response.data.data);
  //       setSubscription(response.data.data);
  //     })
  //     .catch((error) => {
  //       console.log(error.response);
  //     });
  // };

  // useEffect(() => {
  //   getData();
  // }, []);

  const getImageUrl = (img) => {
    let uri = 'assets/images/191x60px Logo-01-571x380.jpg';
    if (!product.productImages || product.productImages.length == 0) {
      return uri
    }

    if (img == 1 && product.productImages[0]) {
      uri = product.productImages[0].path
    }

    if (img == 2 && product.productImages[1]) {
      uri = product.productImages[1].path
    } else if (img == 2 && product.productImages[0]) {
      uri = product.productImages[0].path
    }
    return `${webBaseUrl}${uri}`
  }

  return (
    <>
      <div className={`product-layout product-grid ${noCol ? "" : "col-lg-3 col-md-3 col-sm-4 col-xs-6"}`}>
        <div className="product-thumb row">
          <div className="image col-xs-12">
            <Link to={`/details/${slugify(product.name)}?pid=${product.id}`}>
              <img
                className="image_thumb"
                src={getImageUrl(1)}
                title={product.name}
                alt={product.name}
                style={{ minHeight: '356px' }}
              />
              <img
                className="image_thumb_swap"
                src={getImageUrl(2)}
                title={product.name}
                alt={product.name}
              />
            </Link>
            <div className="button-group">
              <button
                className="btn-cart "
                type="button"
                title="Add to Cart"
                onclick="cart.add('51')"
              >
                <i className="fa fa-shopping-cart"></i>{" "}
                <span className="hidden-xs hidden-sm hidden-md">
                  Add to Cart
                </span>
              </button>
              <button
                className="btn-wishlist"
                title="Add to Wish List"
                onclick="wishlist.add('51');"
              >
                <i className="fa fa-heart"></i>{" "}
                <span title="Add to Wish List">Add to Wish List</span>{" "}
              </button>
              <button
                className="btn-compare"
                title="Compare this Product"
                onclick="compare.add('51');"
              >
                <i className="fa fa-exchange"></i>{" "}
                <span title="Compare this Product">Compare this Product</span>{" "}
              </button>
              <button
                className="btn-quickview"
                type="button"
                title=""
                onclick="tt_quickview.ajaxView('https://restosleep.in/index.php?route=product/product&amp;path=66&amp;product_id=51')"
              >
                <i className="fa fa-eye"></i> <span title=""></span>{" "}
              </button>
            </div>
            <div className="rating">
              <span className="fa fa-stack">
                <i className="fa fa-star fa-stack-2x"></i>
              </span>
              <span className="fa fa-stack">
                <i className="fa fa-star fa-stack-2x"></i>
              </span>
              <span className="fa fa-stack">
                <i className="fa fa-star fa-stack-2x"></i>
              </span>
              <span className="fa fa-stack">
                <i className="fa fa-star fa-stack-2x"></i>
              </span>
              <span className="fa fa-stack">
                <i className="fa fa-star off fa-stack-2x"></i>
              </span>
            </div>
          </div>
          <div className="thumb-description col-xs-12">
            <div className="caption">
              <h4>
                {/* <Clamp tag='h2' clamp='2' className='product-title'> */}
                <Link to={`/details/${slugify(product.name)}?pid=${product.id}`}>{product.name}</Link>
                {/* </Clamp> */}
              </h4>
              <p className="description">
                {product.description}
              </p>
              <div className="price">
                Rs {product.sellingPrice}
                <span className="price-tax">Rs {product.sellingPrice} </span>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
