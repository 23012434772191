import { combineReducers, configureStore } from "@reduxjs/toolkit";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import persistStore from "redux-persist/es/persistStore";
import authReducer from "../slice/authSlice";
import storeFrontReducer from "../slice/storeFront";
import cartReducer from "../slice/cartSlice";

const persistConfig = {
    key: "presist-store",
    storage,
    whitelist: ['menu', 'settings', 'lang', 'auth', 'store'],
};

const presistedReducer = persistReducer(
    persistConfig,
    combineReducers({
        auth: authReducer,
        storeFront: storeFrontReducer,
        cart: cartReducer
    })
)

const store = configureStore({
    reducer: presistedReducer,
});

const persistedStore = persistStore(store);
export { store, persistedStore };