import React from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import AccountSection from "../components/AccountSection";

export default function LogIn() {
  const navigate = useNavigate();

  const handleHome = () => {
    navigate("/");
  };
  return (
    <>
      <div class="header-content-title">
        <div class="page-title-wrapper">
          <div class="container">
            <div class="breadcrub">
              <h2 class="page-title">Login</h2>
              <ul class="breadcrumb">
                <li>
                  <a onClick={handleHome} style={{cursor:'pointer'}}>
                    <i class="fa fa-home" ></i>
                  </a>
                </li>
                <li>
                  <Link to="/accounts">
                    Account
                  </Link>
                </li>
                <li>
                  <a class="last-breadcrumb">Login</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div id="account-login" class="container">
        <div class="row">
          <div id="content" class="col-sm-12 Cwidth" style={{ width: "100%" }}>
            <div class="row">
              <div class="col-sm-6 new-customer">
                <div class="well">
                  <h2>New Customer</h2>
                  <p>
                    <strong>Register</strong>
                  </p>
                  <p>
                    By creating an account you will be able to shop faster, be
                    up to date on an order's status, and keep track of the
                    orders you have previously made.
                  </p>
                </div>
                <div class="bottom-form">
                  <a
                    href="https://restosleep.in/index.php?route=account/register"
                    class="btn btn-primary"
                  >
                    Continue
                  </a>
                </div>
              </div>
              <div class="col-sm-6 customer-login">
                <div class="well">
                  <h2>Returning Customer</h2>
                  <p>
                    <strong>I am a returning customer</strong>
                  </p>
                  <form
                    action="https://restosleep.in/index.php?route=account/login"
                    method="post"
                    enctype="multipart/form-data"
                  >
                    <div class="form-group">
                      <label class="control-label" for="input-email">
                        Your email address
                      </label>
                      <input
                        type="text"
                        name="email"
                        value=""
                        placeholder="Your email address"
                        id="input-email"
                        class="form-control"
                      />
                    </div>
                    <div class="form-group">
                      <label class="control-label" for="input-password">
                        Password
                      </label>
                      <input
                        type="password"
                        name="password"
                        value=""
                        placeholder="Password"
                        id="input-password"
                        class="form-control"
                      />
                    </div>
                  </form>
                </div>
                <div class="bottom-form">
                  <a 
                  // href="https://restosleep.in/index.php?route=account/forgotten"
                  >
                    Forgotten Password
                  </a>
                  <input type="submit" value="Login" class="btn btn-primary" />
                </div>
              </div>
            </div>
            <AccountSection/>
          </div>
        </div>
      </div>
    </>
  );
}
