import React from "react";
import { useNavigate } from "react-router";

export default function Mattresses() {
  const navigate = useNavigate();
  const handleHome = () => {
    navigate("/");
  };
  const handleClick = () => {
    navigate("/foam-lite");
    console.log("hi");
  };
  return (
    <>
      {/* Header content start */}
      <div class="header-content-title">
        <div class="page-title-wrapper">
          <div class="container">
            <div class="breadcrub">
              <h1 class="category-name page-title">Mattresses</h1>
              <ul class="breadcrumb">
                <li>
                  <a
                    onClick={handleHome}
                    style={{ cursor: "pointer" }}
                    // "https://restosleep.in/index.php?route=common/home"
                  >
                    <i class="fa fa-home"></i>
                  </a>
                </li>
                <li>
                  <a class="last-breadcrumb">Mattresses</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Header content end  */}

      <div id="product-category" class="container product-category">
        <div class="content_wrap">
          <div id="content" class="col-sm-12 Cwidth" style={{ width: "100%" }}>
            {/* <!-- Category Description START --> */}

            <div class="category-description">
              <div class="row"></div>
            </div>
            {/* <!-- Category Description END -->
            <!-- Category filter START --> */}
            <div class="category-filter">
              {/* <!-- Grid-List Buttons --> */}
              <div class="col-md-2 filter-grid-list">
                <div class="btn-group">
                  <button
                    type="button"
                    id="grid-view"
                    class="btn btn-default active"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Grid"
                  >
                    <i class="fa fa-th"></i>
                  </button>
                  <button
                    type="button"
                    id="list-view"
                    class="btn btn-default"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="List"
                  >
                    <i class="fa fa-th-list"></i>
                  </button>
                </div>
              </div>
              {/* <!-- Show Products Selection --> */}
              <div class="filter-show">
                <div class="col-md-4 text-right filter-text">
                  <label
                    class="input-group-addon control-label"
                    for="input-limit"
                  >
                    Show:
                  </label>
                </div>
                <div class="col-md-8 text-right filter-selection">
                  <div class="select-wrapper">
                    <select
                      id="input-limit"
                      class="form-control"
                      onchange="location = this.value;"
                    >
                      <option
                        value="https://restosleep.in/index.php?route=product/category&amp;path=66&amp;limit=15"
                        selected="selected"
                      >
                        15
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=66&amp;limit=25">
                        25
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=66&amp;limit=50">
                        50
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=66&amp;limit=75">
                        75
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=66&amp;limit=100">
                        100
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              {/* <!-- Sort By Selection --> */}
              <div class="filter-sort-by">
                <div class="col-md-3 text-right filter-text">
                  <label
                    class="input-group-addon control-label"
                    for="input-sort"
                  >
                    Sort By:
                  </label>
                </div>
                <div class="col-md-9 text-right filter-selection">
                  <div class="select-wrapper">
                    <select
                      id="input-sort"
                      class="form-control"
                      onchange="location = this.value;"
                    >
                      <option
                        value="https://restosleep.in/index.php?route=product/category&amp;path=66&amp;sort=p.sort_order&amp;order=ASC"
                        selected="selected"
                      >
                        Default
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=66&amp;sort=pd.name&amp;order=ASC">
                        Name (A - Z)
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=66&amp;sort=pd.name&amp;order=DESC">
                        Name (Z - A)
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=66&amp;sort=p.price&amp;order=ASC">
                        Price (Low &gt; High)
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=66&amp;sort=p.price&amp;order=DESC">
                        Price (High &gt; Low)
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=66&amp;sort=rating&amp;order=DESC">
                        Rating (Highest)
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=66&amp;sort=rating&amp;order=ASC">
                        Rating (Lowest)
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=66&amp;sort=p.model&amp;order=ASC">
                        Model (A - Z)
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=66&amp;sort=p.model&amp;order=DESC">
                        Model (Z - A)
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Category filter END -->
      <!-- Category products START --> */}
            <div class="category-products">
              <div class="row">
                {" "}
                <div class="product-layout product-grid col-lg-3 col-md-3 col-sm-4 col-xs-12">
                  <div class="product-thumb row">
                    <div class="image col-xs-12">
                      <a onClick={handleClick}>
                        {" "}
                        <img
                          class="image_thumb"
                          src="/assets/four.jpg"
                          title="Foam Lite"
                          alt="Foam Lite"
                        />
                        <img
                          class="image_thumb_swap"
                          src="/assets/five.jpg"
                          title="Foam Lite"
                          alt="Foam Lite"
                        />{" "}
                      </a>
                      <div class="rating">
                        <span class="fa fa-stack">
                          <i class="fa fa-star fa-stack-2x"></i>
                        </span>
                        <span class="fa fa-stack">
                          <i class="fa fa-star fa-stack-2x"></i>
                        </span>
                        <span class="fa fa-stack">
                          <i class="fa fa-star fa-stack-2x"></i>
                        </span>
                        <span class="fa fa-stack">
                          <i class="fa fa-star fa-stack-2x"></i>
                        </span>
                        <span class="fa fa-stack">
                          <i class="fa fa-star off fa-stack-2x"></i>
                        </span>
                      </div>

                      <div class="button-group">
                        <button
                          class="btn-cart "
                          type="button"
                          title="Add to Cart"
                        >
                          <i class="fa fa-shopping-cart"></i>{" "}
                          <span class="hidden-xs hidden-sm hidden-md">
                            Add to Cart
                          </span>
                        </button>
                        <button class="btn-wishlist" title="Add to Wish List">
                          <i class="fa fa-heart"></i>{" "}
                          <span title="Add to Wish List">Add to Wish List</span>{" "}
                        </button>
                        <button
                          class="btn-compare"
                          title="Compare this Product"
                        >
                          <i class="fa fa-exchange"></i>{" "}
                          <span title="Compare this Product">
                            Compare this Product
                          </span>{" "}
                        </button>
                        <button
                          class="btn-quickview"
                          type="button"
                          title=""
                          // onclick="tt_quickview.ajaxView(&#39;https://restosleep.in/index.php?route=product/product&amp;path=66&amp;product_id=51&#39;)"
                        >
                          <i class="fa fa-eye"></i> <span title=""></span>{" "}
                        </button>
                      </div>
                    </div>
                    <div class="thumb-description col-xs-12">
                      <div class="caption">
                        <h4>
                          <a
                          // href="https://restosleep.in/index.php?route=product/product&amp;path=66&amp;product_id=51"
                          >
                            Foam Lite
                          </a>
                        </h4>
                        <p class="description">
                          Foam lite&nbsp; with High density PU foam aided with
                          generous thick PU foam comfort layer quilt on t..
                        </p>
                        <div class="price">
                          {" "}
                          Rs 4,834.00
                          <span class="price-tax">
                            Ex Tax: Rs 4,834.00
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="product-layout product-grid col-lg-3 col-md-3 col-sm-4 col-xs-12">
                  <div class="product-thumb row">
                    <div class="image col-xs-12">
                      {" "}
                      <a>
                        {" "}
                        <img
                          class="image_thumb"
                          // src="./Mattresses_files/Memory Puf 1-285x371.jpg"
                          src="/assets/six.jpg"
                          title="Memory Puf"
                          alt="Memory Puf"
                        />{" "}
                        <img
                          class="image_thumb_swap"
                          src="/assets/three.jpg"
                          title="Memory Puf"
                          alt="Memory Puf"
                        />{" "}
                      </a>
                      <div class="rating">
                        <span class="fa fa-stack">
                          <i class="fa fa-star fa-stack-2x"></i>
                        </span>
                        <span class="fa fa-stack">
                          <i class="fa fa-star fa-stack-2x"></i>
                        </span>
                        <span class="fa fa-stack">
                          <i class="fa fa-star fa-stack-2x"></i>
                        </span>
                        <span class="fa fa-stack">
                          <i class="fa fa-star fa-stack-2x"></i>
                        </span>
                        <span class="fa fa-stack">
                          <i class="fa fa-star off fa-stack-2x"></i>
                        </span>
                      </div>
                      <div class="button-group">
                        <button
                          class="btn-cart "
                          type="button"
                          title="Add to Cart"
                        >
                          <i class="fa fa-shopping-cart"></i>{" "}
                          <span class="hidden-xs hidden-sm hidden-md">
                            Add to Cart
                          </span>
                        </button>
                        <button
                          class="btn-wishlist"
                          title="Add to Wish List"
                          // onclick="wishlist.add(&#39;52&#39;);"
                        >
                          <i class="fa fa-heart"></i>{" "}
                          <span title="Add to Wish List">Add to Wish List</span>{" "}
                        </button>
                        <button
                          class="btn-compare"
                          title="Compare this Product"
                        >
                          <i class="fa fa-exchange"></i>{" "}
                          <span title="Compare this Product">
                            Compare this Product
                          </span>{" "}
                        </button>
                        <button
                          class="btn-quickview"
                          type="button"
                          title=""
                          // onclick="tt_quickview.ajaxView(&#39;https://restosleep.in/index.php?route=product/product&amp;path=66&amp;product_id=52&#39;)"
                        >
                          <i class="fa fa-eye"></i> <span title=""></span>{" "}
                        </button>
                      </div>
                    </div>
                    <div class="thumb-description col-xs-12">
                      <div class="caption">
                        <h4>
                          <a>Memory Puf</a>
                        </h4>
                        <p class="description">
                          Memory puf, is a foam mattress contours to your body
                          shape keeping it in the most comfortable positi..
                        </p>
                        <div class="price">
                          {" "}
                          Rs 10,712.00
                          <span class="price-tax">
                            Ex Tax: Rs 10,712.00
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="product-layout product-grid col-lg-3 col-md-3 col-sm-4 col-xs-12">
                  <div class="product-thumb row">
                    <div class="image col-xs-12">
                      {" "}
                      <a
                      // href="https://restosleep.in/index.php?route=product/product&amp;path=66&amp;product_id=55"
                      >
                        {" "}
                        <img
                          class="image_thumb"
                          src="/assets/two.jpg"
                          title="Resto Coir"
                          alt="Resto Coir"
                        />{" "}
                        <img
                          class="image_thumb_swap"
                          src="/assets/one.jpg"
                          title="Resto Coir"
                          alt="Resto Coir"
                        />{" "}
                      </a>
                      <div class="rating">
                        <span class="fa fa-stack">
                          <i class="fa fa-star fa-stack-2x"></i>
                        </span>
                        <span class="fa fa-stack">
                          <i class="fa fa-star fa-stack-2x"></i>
                        </span>
                        <span class="fa fa-stack">
                          <i class="fa fa-star fa-stack-2x"></i>
                        </span>
                        <span class="fa fa-stack">
                          <i class="fa fa-star fa-stack-2x"></i>
                        </span>
                        <span class="fa fa-stack">
                          <i class="fa fa-star fa-stack-2x"></i>
                        </span>
                      </div>
                      <div class="button-group">
                        <button
                          class="btn-cart "
                          type="button"
                          title="Add to Cart"
                          // onclick="cart.add(&#39;55&#39;)"
                        >
                          <i class="fa fa-shopping-cart"></i>{" "}
                          <span class="hidden-xs hidden-sm hidden-md">
                            Add to Cart
                          </span>
                        </button>
                        <button
                          class="btn-wishlist"
                          title="Add to Wish List"
                          // onclick="wishlist.add(&#39;55&#39;);"
                        >
                          <i class="fa fa-heart"></i>{" "}
                          <span title="Add to Wish List">Add to Wish List</span>{" "}
                        </button>
                        <button
                          class="btn-compare"
                          title="Compare this Product"
                          onclick="compare.add(&#39;55&#39;);"
                        >
                          <i class="fa fa-exchange"></i>{" "}
                          <span title="Compare this Product">
                            Compare this Product
                          </span>{" "}
                        </button>
                        <button
                          class="btn-quickview"
                          type="button"
                          title=""
                          // onclick="tt_quickview.ajaxView(&#39;https://restosleep.in/index.php?route=product/product&amp;path=66&amp;product_id=55&#39;)"
                        >
                          <i class="fa fa-eye"></i> <span title=""></span>{" "}
                        </button>
                      </div>
                    </div>
                    <div class="thumb-description col-xs-12">
                      <div class="caption">
                        <h4>
                          <a
                          // href="https://restosleep.in/index.php?route=product/product&amp;path=66&amp;product_id=55"
                          >
                            Resto Coir
                          </a>
                        </h4>
                        <p class="description">
                          Resto coir, rubberized coir mattress offers the most
                          natural comfort as it has been crafted with the..
                        </p>
                        <div class="price">
                          {" "}
                          Rs 4,950.00
                          <span class="price-tax">
                            Ex Tax: Rs 4,950.00
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="product-layout product-grid col-lg-3 col-md-3 col-sm-4 col-xs-12">
                  <div class="product-thumb row">
                    <div class="image col-xs-12">
                      {" "}
                      <a>
                        {" "}
                        <img
                          class="image_thumb"
                          src="/assets/four.jpg"
                          title="Spine o safe"
                          alt="Spine o safe"
                        />{" "}
                        <img
                          class="image_thumb_swap"
                          src="/assets/six.jpg"
                          title="Spine o safe"
                          alt="Spine o safe"
                        />{" "}
                      </a>
                      <div class="rating">
                        <span class="fa fa-stack">
                          <i class="fa fa-star fa-stack-2x"></i>
                        </span>
                        <span class="fa fa-stack">
                          <i class="fa fa-star fa-stack-2x"></i>
                        </span>
                        <span class="fa fa-stack">
                          <i class="fa fa-star fa-stack-2x"></i>
                        </span>
                        <span class="fa fa-stack">
                          <i class="fa fa-star fa-stack-2x"></i>
                        </span>
                        <span class="fa fa-stack">
                          <i class="fa fa-star fa-stack-2x"></i>
                        </span>
                      </div>
                      <div class="button-group">
                        <button
                          class="btn-cart "
                          type="button"
                          title="Add to Cart"
                          // onclick="cart.add(&#39;57&#39;)"
                        >
                          <i class="fa fa-shopping-cart"></i>{" "}
                          <span class="hidden-xs hidden-sm hidden-md">
                            Add to Cart
                          </span>
                        </button>
                        <button
                          class="btn-wishlist"
                          title="Add to Wish List"
                          // onclick="wishlist.add(&#39;57&#39;);"
                        >
                          <i class="fa fa-heart"></i>{" "}
                          <span title="Add to Wish List">Add to Wish List</span>{" "}
                        </button>
                        <button
                          class="btn-compare"
                          title="Compare this Product"
                          onclick="compare.add(&#39;57&#39;);"
                        >
                          <i class="fa fa-exchange"></i>{" "}
                          <span title="Compare this Product">
                            Compare this Product
                          </span>{" "}
                        </button>
                        <button
                          class="btn-quickview"
                          type="button"
                          title=""
                          // onclick="tt_quickview.ajaxView(&#39;https://restosleep.in/index.php?route=product/product&amp;path=66&amp;product_id=57&#39;)"
                        >
                          <i class="fa fa-eye"></i> <span title=""></span>{" "}
                        </button>
                      </div>
                    </div>
                    <div class="thumb-description col-xs-12">
                      <div class="caption">
                        <h4>
                          <a>Spine o safe</a>
                        </h4>
                        <p class="description">Spine o safe..</p>
                        <div class="price">
                          {" "}
                          Rs 12,391.00
                          <span class="price-tax">
                            Ex Tax: Rs 12,391.00
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="product-layout product-grid col-lg-3 col-md-3 col-sm-4 col-xs-12">
                  <div class="product-thumb row">
                    <div class="image col-xs-12">
                      {" "}
                      <a>
                        {" "}
                        <img
                          class="image_thumb"
                          src="/assets/three.jpg"
                          title="Springzz"
                          alt="Springzz"
                        />{" "}
                        <img
                          class="image_thumb_swap"
                          src="/assets/two.jpg"
                          title="Springzz"
                          alt="Springzz"
                        />{" "}
                      </a>{" "}
                      <div class="sale-icon">Sale</div>
                      <span class="percent">-0%</span>
                      <div class="button-group">
                        <button
                          class="btn-cart "
                          type="button"
                          title="Add to Cart"
                          // onclick="cart.add(&#39;50&#39;)"
                        >
                          <i class="fa fa-shopping-cart"></i>{" "}
                          <span class="hidden-xs hidden-sm hidden-md">
                            Add to Cart
                          </span>
                        </button>
                        <button
                          class="btn-wishlist"
                          title="Add to Wish List"
                          // onclick="wishlist.add(&#39;50&#39;);"
                        >
                          <i class="fa fa-heart"></i>{" "}
                          <span title="Add to Wish List">Add to Wish List</span>{" "}
                        </button>
                        <button
                          class="btn-compare"
                          title="Compare this Product"
                          onclick="compare.add(&#39;50&#39;);"
                        >
                          <i class="fa fa-exchange"></i>{" "}
                          <span title="Compare this Product">
                            Compare this Product
                          </span>{" "}
                        </button>
                        <button
                          class="btn-quickview"
                          type="button"
                          title=""
                          // onclick="tt_quickview.ajaxView(&#39;https://restosleep.in/index.php?route=product/product&amp;path=66&amp;product_id=50&#39;)"
                        >
                          <i class="fa fa-eye"></i> <span title=""></span>{" "}
                        </button>
                      </div>
                    </div>
                    <div class="thumb-description col-xs-12">
                      <div class="caption">
                        <h4>
                          <a>Springzz</a>
                        </h4>
                        <p class="description">
                          The SPRINGZZ, is a Bonnel spring mattress that is
                          super comfortable and cosy. It comes with an addit..
                        </p>
                        <div class="price">
                          {" "}
                          <span class="price-new">Rs 7,779.00</span>{" "}
                          <span class="price-old">Rs 7,779.00</span>
                          <span class="price-tax">
                            Ex Tax: Rs 7,779.00
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <script>
			  jQuery(document).ready(function($){
			  $(".item-countdown").each(function(){
			  var date = $(this).data('date');
			  $(this).lofCountDown({
				  TargetDate:date,
				  DisplayFormat:"<div>%%D%% <span>text_days</span></div><div>%%H%% <span>text_hours</span></div><div>%%M%% <span>text_minutes</span></div><div>%%S%% <span>text_seconds</span></div>"
				});
			  });
			  });   
		</script> */}
              </div>
            </div>
            {/* <!-- Category products END -->
      <!-- Category pagination START --> */}
            <div class="category-pagination">
              <div class="col-xs-6 text-left">
                Showing 1 to 5 of 5 (1 Pages)
              </div>
              <div class="col-xs-6 text-right"></div>
            </div>
            {/* <!-- Category pagination END --> */}
          </div>
        </div>
      </div>
      {/* Body content start  */}
      <div
        id="goToTop"
        title="Top"
        class="goToTop ttbox-img"
        style={{ display: "block" }}
      ></div>
    </>
  );
}
