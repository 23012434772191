import React from 'react'
import { Col, Form } from 'react-bootstrap'

export default function CheckBox({ optionDetails = [], option, isRequired, handleInput }) {
    const handleCheckbox = (e) => {
        const optionChanges = optionDetails.find(opt => opt.id === +e.target.value)
        optionChanges.isChecked = e.target.checked
        handleInput(e.target.checked, optionChanges, option)
    }

    return (
        <>
            <Col sm="auto" xs="12" className="mb-3 me-1">
                <p className="fw-bold form-p">{option.name}</p>
                <div className="pt-1">
                    <div className="mb-3">
                        <Form.Check type="checkbox" inline required={isRequired} >
                            {optionDetails.map((detail) => {
                                if (detail.isDeleted) return null
                                return (
                                    <>
                                        <Form.Check.Input className='me-2' onChange={handleCheckbox} key={detail.id} type="checkbox" required={isRequired} name={option.name} value={detail.id} />
                                        <Form.Check.Label className='me-2' >{detail.optionValue}</Form.Check.Label>
                                    </>
                                )
                            })}
                            {/* <Form.Control.Feedback type="invalid">
                                Please select one option
                            </Form.Control.Feedback> */}
                            <div class="invalid-feedback">Please select {option.name}</div>
                        </Form.Check>
                    </div>
                    {/* {optionDetails.map(detail => <Form.Check key={detail.id} type="checkbox" label={detail.optionValue} inline required={isRequired} />)} */}
                </div>
            </Col>
        </>
    )
}
