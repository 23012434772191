import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel2'

export default function HomeSlider() {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const images = [
        'assets/images/one.jpg',
        'assets/images/two.jpg',
        'assets/images/three.jpg',
        'assets/images/five.jpg'
    ];
    const nextSlide = () => {
        const newIndex = (currentImageIndex + 1) % images.length;
        setCurrentImageIndex(newIndex);
    };
    const prevSlide = () => {
        const newIndex = (currentImageIndex - 1 + images.length) % images.length;
        setCurrentImageIndex(newIndex);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            nextSlide();
        }, 5000); // Change slide every 3 seconds (adjust as needed)

        return () => clearInterval(interval);
    }, [currentImageIndex]);

    const options = {
        items: 1,
        rewind: true,
        autoplay: true,
        dots: false,
        autoplayTimeout:5000,
        nav:true,
        navText: [<button type="button" role="presentation" class="owl-next">Next<span aria-label="Next">›</span></button>, <button type="button" role="presentation" class="owl-prev disabled">Prev<span aria-label="Previous">‹</span></button>,]
    };
    return (
        <div className="slider">
            <div className="slides-container">
                <OwlCarousel options={options} className="additional-images">
                    {images.map((image, index) => (
                        <div>
                            <img src={image} alt={`Slide ${index + 1}`} />
                        </div>
                    ))}
                </OwlCarousel>

            </div>
        </div>
    )
}
