import React from 'react'
import TitleBar from '../../components/TitleBar'

export default function OrderDetails() {
    return (
        <>
            <TitleBar />
            <div id="account-order" class="container">

                <div class="row">
                    <div id="content" class="col-sm-12 Cwidth" style={{ width: "100%" }}>

                        <table class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <td class="text-left" colspan="2">Order Details</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-left" style={{ width: "50%" }}> <b>Invoice No.:</b> INV-2021-001<br />
                                        <b>Order ID: #%s</b> #1<br />
                                        <b>Date Added:</b> 18/10/2022</td>
                                    <td class="text-left" style={{ width: "50%" }}> <b>Payment Method:</b> Pay by Razorpay <br /> <a href="https://www.razorpay.com" target="_blank"><img src="https://cdn.razorpay.com/static/assets/logo/pa<br />
                         <b>Shipping Method:</b> Free Shipping </td>
      </tr>
    </tbody>
  </table>
  <table class=" table="" table-bordered="" />
                                    </a></td></tr></tbody><thead>
                                <tr>
                                    <td class="text-left" style={{ width: "50%", verticalAlign: "top" }}>Payment Address</td>
                                    <td class="text-left" style={{ width: "50%", verticalAlign: "top" }} >Shipping Address</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-left">asfa masfa<br />sadga<br />bangalore<br />Goa<br />India</td>
                                    <td class="text-left">asfa masfa<br />sadga<br />bangalore<br />Goa<br />India</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="table-responsive">
                            <table class="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <td class="text-left">Product Name</td>
                                        <td class="text-left">Model</td>
                                        <td class="text-right">Quantity</td>
                                        <td class="text-right">Price</td>
                                        <td class="text-right">Total</td>
                                        <td style={{ width: "20%" }}></td>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td class="text-left">Foam Lite
                                            <br />
                                            &nbsp;<small> - SIZE: 72 x 30 x 5 Inch</small> </td>
                                        <td class="text-left">PC002</td>
                                        <td class="text-right">1</td>
                                        <td class="text-right">Rs 7,262.00</td>
                                        <td class="text-right">Rs 7,262.00</td>
                                        <td class="text-right" style={{ whiteSpace: "nowrap" }}> <a href="https://restosleep.in/index.php?route=account/order/reorder&amp;order_id=1&amp;order_product_id=1" data-toggle="tooltip" title="" class="btn btn-primary" data-original-title="Reorder"><i class="fa fa-shopping-cart"></i></a>  <a href="https://restosleep.in/index.php?route=account/return/add&amp;order_id=1&amp;product_id=51" data-toggle="tooltip" title="" class="btn btn-danger" data-original-title="Return"><i class="fa fa-reply"></i></a></td>
                                    </tr>
                                </tbody>

                                <tfoot>

                                    <tr>
                                        <td colspan="3"></td>
                                        <td class="text-right"><b>Sub-Total</b></td>
                                        <td class="text-right">Rs 7,262.00</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td colspan="3"></td>
                                        <td class="text-right"><b>Free Shipping</b></td>
                                        <td class="text-right">Rs 0.00</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td colspan="3"></td>
                                        <td class="text-right"><b>Total</b></td>
                                        <td class="text-right">Rs 7,262.00</td>
                                        <td></td>
                                    </tr>
                                </tfoot>

                            </table>
                        </div>
                        <h3>Order History</h3>
                        <table class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <td class="text-left">Date Added</td>
                                    <td class="text-left">Status</td>
                                    <td class="text-left">Comment</td>
                                </tr>
                            </thead>
                            <tbody>

                                <tr>
                                    <td class="text-left">19/10/2022</td>
                                    <td class="text-left">Processed</td>
                                    <td class="text-left"></td>
                                </tr>
                            </tbody>

                        </table>
                        <div class="buttons clearfix">
                            <div class="pull-right"><a href="https://restosleep.in/index.php?route=account/order" class="btn btn-primary">Continue</a></div>
                        </div>
                        <div class="panel panel-default account-list">
                            <div class="panel-heading">Account</div>
                            <div class="list-group">
                                <a href="https://restosleep.in/index.php?route=account/account" class="list-group-item">My Account</a>
                                <a href="https://restosleep.in/index.php?route=account/edit" class="list-group-item">Edit Account</a> <a href="https://restosleep.in/index.php?route=account/password" class="list-group-item">Password</a>
                                <a href="https://restosleep.in/index.php?route=account/address" class="list-group-item">Address Book</a> <a href="https://restosleep.in/index.php?route=account/wishlist" class="list-group-item">Wish List</a> <a href="https://restosleep.in/index.php?route=account/order" class="list-group-item">Order History</a> <a href="https://restosleep.in/index.php?route=account/download" class="list-group-item">Downloads</a><a href="https://restosleep.in/index.php?route=account/recurring" class="list-group-item">Recurring payments</a> <a href="https://restosleep.in/index.php?route=account/reward" class="list-group-item">Reward Points</a> <a href="https://restosleep.in/index.php?route=account/return" class="list-group-item">Returns</a> <a href="https://restosleep.in/index.php?route=account/transaction" class="list-group-item">Transactions</a> <a href="https://restosleep.in/index.php?route=account/newsletter" class="list-group-item">Newsletter</a>
                                <a href="https://restosleep.in/index.php?route=account/logout" class="list-group-item">Logout</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
