import React, { Suspense, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import RouteApp from './RouteApp'
import { useDispatch } from 'react-redux'
import StoresApi from '../assets/api/StoreApis'
import axios from '../assets/axios/axios'
import { addStore } from '../slice/storeFront'


export default function RouteIdentifier() {
    const dispatch = useDispatch()
    const getStore = () => {
        axios.get(`${StoresApi.StoreUrl}/GetStoreByCode/${"RestOsleep"}`)
            // axios.get(`${StoresApi.StoreUrl}/GetStoreFront/2`)
            .then(response => {
                //  
                dispatch(addStore(response.data.data))
                // getData(response.data.data.id)
            })
            .catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        getStore()
    }, [])

    return (
        <Suspense>
            <Routes>
                {/* <Route path='/' element={<Home />} /> */}
                <Route path={`/*`} element={<RouteApp />} />
            </Routes>
        </Suspense>
    )
}
