import React from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import AccountSection from "../components/AccountSection";

function Return() {
  const navigate = useNavigate();

  const handleHome = () => {
    navigate("/");
  };

  return (
    <>
      <div class="header-content-title">
        <div class="page-title-wrapper">
          <div class="container">
            <div class="breadcrub">
              <h1 class="page-title">Account</h1>
              <ul class="breadcrumb">
                <li>
                  <a
                    onClick={handleHome}
                    style={{ cursor: "pointer" }}
                    //   href="https://restosleep.in/index.php?route=common/home"
                  >
                    {" "}
                    <i class="fa fa-home"></i>
                  </a>
                </li>
                <li>
                  <a
                    style={{ cursor: "pointer" }}
                    //   href="https://restosleep.in/index.php?route=account/account"
                  >
                    {" "}
                    Account
                  </a>
                </li>
                <li>
                  <a class="last-breadcrumb"> Product Returns</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div id="account-return" class="container">
        <div class="row">
          <div id="content" class="col-sm-12 Cwidth" style={{width: "100%"}}>
            <p>Please complete the form below to request an RMA number.</p>
            <form
              action="https://restosleep.in/index.php?route=account/return/add"
              method="post"
              enctype="multipart/form-data"
              class="form-horizontal"
            >
              <fieldset>
                <legend>Order History</legend>
                <div class="form-group required">
                  <label class="col-sm-2 control-label" for="input-firstname">
                    First Name
                  </label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      name="firstname"
                      value=""
                      placeholder="First Name"
                      id="input-firstname"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="form-group required">
                  <label class="col-sm-2 control-label" for="input-lastname">
                    Last Name
                  </label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      name="lastname"
                      value=""
                      placeholder="Last Name"
                      id="input-lastname"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="form-group required">
                  <label class="col-sm-2 control-label" for="input-email">
                    Your email address
                  </label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      name="email"
                      value=""
                      placeholder="Your email address"
                      id="input-email"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="form-group required">
                  <label class="col-sm-2 control-label" for="input-telephone">
                    Telephone
                  </label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      name="telephone"
                      value=""
                      placeholder="Telephone"
                      id="input-telephone"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="form-group required">
                  <label class="col-sm-2 control-label" for="input-order-id">
                    Order ID
                  </label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      name="order_id"
                      value=""
                      placeholder="Order ID"
                      id="input-order-id"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label
                    class="col-sm-2 control-label"
                    for="input-date-ordered"
                  >
                    Order Date
                  </label>
                  <div class="col-sm-3">
                    <div class="input-group date">
                      <input
                        type="text"
                        name="date_ordered"
                        value=""
                        placeholder="Order Date"
                        data-date-format="YYYY-MM-DD"
                        id="input-date-ordered"
                        class="form-control"
                      />
                      <span class="input-group-btn">
                        <button type="button" class="btn btn-default">
                          <i class="fa fa-calendar"></i>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend>Product Information</legend>
                <div class="form-group required">
                  <label class="col-sm-2 control-label" for="input-product">
                    Product Name
                  </label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      name="product"
                      value=""
                      placeholder="Product Name"
                      id="input-product"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="form-group required">
                  <label class="col-sm-2 control-label" for="input-model">
                    Product Code
                  </label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      name="model"
                      value=""
                      placeholder="Product Code"
                      id="input-model"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-sm-2 control-label" for="input-quantity">
                    Quantity
                  </label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      name="quantity"
                      value="1"
                      placeholder="Quantity"
                      id="input-quantity"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="form-group required">
                  <label class="col-sm-2 control-label">
                    Reason for Return
                  </label>
                  <div class="col-sm-10">
                    {" "}
                    <div class="radio">
                      <label>
                        <span class="radio-wrapper">
                          <input
                            type="radio"
                            name="return_reason_id"
                            value="1"
                            class="radioid"
                          />
                        </span>
                        Dead On Arrival
                      </label>
                    </div>
                    <div class="radio">
                      <label>
                        <span class="radio-wrapper">
                          <input
                            type="radio"
                            name="return_reason_id"
                            value="4"
                            class="radioid"
                          />
                        </span>
                        Faulty, please supply details
                      </label>
                    </div>
                    <div class="radio">
                      <label>
                        <span class="radio-wrapper">
                          <input
                            type="radio"
                            name="return_reason_id"
                            value="3"
                            class="radioid"
                          />
                        </span>
                        Order Error
                      </label>
                    </div>
                    <div class="radio">
                      <label>
                        <span class="radio-wrapper">
                          <input
                            type="radio"
                            name="return_reason_id"
                            value="5"
                            class="radioid"
                          />
                        </span>
                        Other, please supply details
                      </label>
                    </div>
                    <div class="radio">
                      <label>
                        <span class="radio-wrapper">
                          <input
                            type="radio"
                            name="return_reason_id"
                            value="2"
                            class="radioid"
                          />
                        </span>
                        Received Wrong Item
                      </label>
                    </div>
                  </div>
                </div>
                <div class="form-group required">
                  <label class="col-sm-2 control-label">
                    Product is opened
                  </label>
                  <div class="col-sm-10">
                    <label class="radio-inline">
                      {" "}
                      <span class="radio-wrapper">
                        <input
                          type="radio"
                          name="opened"
                          value="1"
                          class="radioid"
                        />
                      </span>
                      Yes
                    </label>
                    <label class="radio-inline">
                      {" "}
                      <span class="radio-wrapper">
                        <input
                          type="radio"
                          name="opened"
                          value="0"
                          checked="checked"
                          class="radioid"
                        />
                      </span>
                      No
                    </label>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-sm-2 control-label" for="input-comment">
                    Faulty or other details
                  </label>
                  <div class="col-sm-10">
                    <textarea
                      name="comment"
                      rows="10"
                      placeholder="Faulty or other details"
                      id="input-comment"
                      class="form-control"
                    ></textarea>
                  </div>
                </div>
              </fieldset>
              <div class="buttons clearfix">
                <div class="pull-left">
                  <a
                    href="https://restosleep.in/index.php?route=account/account"
                    class="btn btn-danger"
                  >
                    Back
                  </a>
                </div>
                <div class="pull-right">
                  I have read and agree to the{" "}
                  <a
                    href="https://restosleep.in/index.php?route=information/information/agree&amp;information_id=5"
                    class="agree"
                  >
                    <b>Terms &amp; Conditions</b>
                  </a>
                  <span class="checkbox-wrapper">
                    <input
                      type="checkbox"
                      name="agree"
                      value="1"
                      class="checkboxid"
                    />
                  </span>
                  <input type="submit" value="Submit" class="btn btn-primary" />
                </div>
              </div>
            </form>
           <AccountSection/>
          </div>
        </div>
      </div>
      <div id="goToTop" title="Top" class="goToTop ttbox-img" style={{display: "block"}}></div>
    </>
  );
}

export default Return;
