import React from "react";
import { useNavigate } from "react-router";

export default function SiteMap() {
  const navigate = useNavigate();

  const handleAboutUs = () => {
    navigate("/about");
  };

  const handleHome = () => {
    
    navigate("/");
  };
  return (
    <>
      <div class="header-content-title">
        <div class="page-title-wrapper">
          <div class="container">
            <div class="breadcrub">
              <h1 class="page-title">Account</h1>
              <ul class="breadcrumb">
                <li>
                  <a onClick={handleHome} style={{cursor:'pointer'}}>
                    <i  class="fa  fa-home"></i>
                  </a>
                </li>
                <li>
                  <a class="last-breadcrumb">Site Map</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div id="information-sitemap" class="container">
        <div class="row">
          <div id="content" class="col-sm-12 Cwidth" style={{ width: "100%" }}>
            <div class="row">
              <div class="col-sm-6">
                <ul>
                  <li>
                    <a href="https://restosleep.in/index.php?route=product/category&amp;path=66">
                      Mattresses
                    </a>
                  </li>
                  <li>
                    <a href="https://restosleep.in/index.php?route=product/category&amp;path=67">
                      Accessories
                    </a>
                  </li>
                  <li>
                    <a href="https://restosleep.in/index.php?route=product/category&amp;path=128">
                      about us
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-sm-6">
                <ul>
                  <li>
                    <a href="https://restosleep.in/index.php?route=product/special">
                      Specials
                    </a>
                  </li>
                  <li>
                    <a href="https://restosleep.in/index.php?route=account/account">
                      Account
                    </a>
                    <ul>
                      <li>
                        <a href="https://restosleep.in/index.php?route=account/edit">
                          Edit Account
                        </a>
                      </li>
                      <li>
                        <a href="https://restosleep.in/index.php?route=account/password">
                          Password
                        </a>
                      </li>
                      <li>
                        <a href="https://restosleep.in/index.php?route=account/address">
                          Address Book
                        </a>
                      </li>
                      <li>
                        <a href="https://restosleep.in/index.php?route=account/order">
                          Order History
                        </a>
                      </li>
                      <li>
                        <a href="https://restosleep.in/index.php?route=account/download">
                          Downloads
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="https://restosleep.in/index.php?route=account/order">
                      View Cart
                    </a>
                  </li>
                  <li>
                    <a href="https://restosleep.in/index.php?route=checkout/checkout">
                      Checkout
                    </a>
                  </li>
                  <li>
                    <a href="https://restosleep.in/index.php?route=product/search">
                      Search
                    </a>
                  </li>
                  <li>
                    Information
                    <ul>
                      <li>
                        <a
                          // href="https://restosleep.in/index.php?route=information/information&amp;information_id=4"
                          onClick={handleAboutUs}
                        >
                          About Us
                        </a>
                      </li>
                      <li>
                        <a href="https://restosleep.in/index.php?route=information/information&amp;information_id=3">
                          Privacy Policy
                        </a>
                      </li>
                      <li>
                        <a href="https://restosleep.in/index.php?route=information/information&amp;information_id=5">
                          Terms &amp; Conditions
                        </a>
                      </li>
                      <li>
                        <a href="https://restosleep.in/index.php?route=information/contact">
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div class="panel panel-default account-list">
              <div class="panel-heading">Account</div>
              <div class="list-group">
                <a
                  href="https://restosleep.in/index.php?route=account/login"
                  class="list-group-item"
                >
                  Login
                </a>{" "}
                <a
                  href="https://restosleep.in/index.php?route=account/register"
                  class="list-group-item"
                >
                  Register
                </a>{" "}
                <a
                  href="https://restosleep.in/index.php?route=account/forgotten"
                  class="list-group-item"
                >
                  Forgotten Password
                </a>
                <a
                  href="https://restosleep.in/index.php?route=account/account"
                  class="list-group-item"
                >
                  My Account
                </a>
                <a
                  href="https://restosleep.in/index.php?route=account/address"
                  class="list-group-item"
                >
                  Address Book
                </a>{" "}
                <a
                  href="https://restosleep.in/index.php?route=account/wishlist"
                  class="list-group-item"
                >
                  Wish List
                </a>{" "}
                <a
                  href="https://restosleep.in/index.php?route=account/order"
                  class="list-group-item"
                >
                  Order History
                </a>{" "}
                <a
                  href="https://restosleep.in/index.php?route=account/download"
                  class="list-group-item"
                >
                  Downloads
                </a>
                <a
                  href="https://restosleep.in/index.php?route=account/recurring"
                  class="list-group-item"
                >
                  Recurring payments
                </a>{" "}
                <a
                  href="https://restosleep.in/index.php?route=account/reward"
                  class="list-group-item"
                >
                  Reward Points
                </a>{" "}
                <a
                  href="https://restosleep.in/index.php?route=account/return"
                  class="list-group-item"
                >
                  Returns
                </a>{" "}
                <a
                  href="https://restosleep.in/index.php?route=account/transaction"
                  class="list-group-item"
                >
                  Transactions
                </a>{" "}
                <a
                  href="https://restosleep.in/index.php?route=account/newsletter"
                  class="list-group-item"
                >
                  Newsletter
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="goToTop" title="Top" class="goToTop ttbox-img" style={{display: "block"}}></div>
    </>
  );
}
