import { Component } from "react";
import AboutUs from "../pages/AboutUs";
import Accounts from "../pages/account/Accounts";
import OrderDetails from "../pages/account/OrderDetails";
import Orders from "../pages/account/Orders";
import Cart from "../pages/Cart";
import CategoryProducts from "../pages/CategoryProducts";
import Product from "../pages/Product";
import TermsConditions from "../pages/TermsConditions";
import ContactUs from "../pages/ContactUs";
import SiteMap from "../pages/SiteMap";
import Return from "../pages/Return";
import Mattresses from "../pages/Mattresses";
import Accessories from "../pages/Accessories";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import LogIn from "../pages/LogIn";
import ForgotPassword from "../pages/LogInSubPages/ForgotPassword";
import Register from "../pages/Register";
import FoamLite from "../pages/FoamLite";
import Restolite from "../pages/RestoLite";
import HomeTwo from "../pages/HomeTwo";
import Home from "../pages/home/Home";
import CustomizeProduct from "../pages/customizeProduct/CustomizeProduct";

export const routes = [
  // {
  //     path: '/product-details',
  //     component: <ProductDetails />
  // },
  {
    path: "/",
    component: <Home />,
  },
  {
    path: "/categories/:id",
    component: <CategoryProducts />,
  },
  {
    path: "/details/:id",
    component: <Product />,
  },
  {
    path: "/customize/:id",
    component: <CustomizeProduct />,
  },
  {
    path: "/cart",
    component: <Cart />,
  },
  {
    path: "/termCondition",
    component: <TermsConditions />,
  },
  {
    path: "/about",
    component: <AboutUs />,
  },
  {
    path: "/contactUs",
    component: <ContactUs />,
  },
  {
    path: "/sitemap",
    component: <SiteMap />,
  },
  {
    path: "/return",
    component: <Return />,
  },
  {
    path: "/privacy",
    component: <PrivacyPolicy />,
  },
  {
    path: "/mattresses",
    component: <Mattresses />,
  },
  {
    path: "/accessories",
    component: <Accessories />,
  },
  {
    path: "/login",
    component: <LogIn />,
  },
  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/register", component: <Register /> },
  { path: "/foam-lite", component: <FoamLite /> },
  { path: "/retro-lite", component: <Restolite /> },
  { path: "/home", component: <HomeTwo /> },

  // {
  //     path: '/login',
  //     component: <Login />,
  // },
  // {
  //     path: '/signup',
  //     component: <Signup />,
  // },
  // {
  //     path: '/reset',
  //     component: <ForgotPassword />,
  // },
  { path: "/accounts", component: <Accounts /> },
  { path: "/orders", component: <Orders /> },
  { path: "/orderDetails", component: <OrderDetails /> },
];

export const mainMenuRoutes = [];

export const privateRoutes = [
  // { path: '/accounts', component: <Accounts /> },
  // { path: '/checkout', component: <Checkout /> }
];
