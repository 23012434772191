import React from "react";
import { Link } from "react-router-dom";

const FoamLite = () => {
  return (
    <>
      <div class="header-content-title">
        <div class="page-title-wrapper">
          <div class="container">
            <div class="breadcrub">
              <h1 class="page-title">Foam Lite </h1>
              <ul class="breadcrumb">
                <li>
                  <Link to="/">
                    <i class="fa fa-home"></i>
                  </Link>
                </li>
                <li>
                  <Link to="/mattresses">Mattresses</Link>
                </li>
                <li>
                  <a class="last-breadcrumb">Foam Lite</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div id="product-product" class="container product-product">
        <div class="row">
          <div id="content" class="col-sm-12 Cwidth" style={{ width: "100%" }}>
            {/* <!-- Product row START --> */}
            <div class="row">
              <div class="col-xs-12 col-sm-5 col-md-5 product-images">
                {/* <!-- Product Image thumbnails START --> */}
                <div class="thumbnails">
                  <div class="product-image">
                    <a class="thumbnail">
                      <img
                        src="/assets/four.jpg"
                        title="Foam Lite"
                        data-zoom-image="https://restosleep.in/image/cache/catalog/Products/Form%20Lite/FoamLite%201-800x1040.jpg"
                        alt="Foam Lite"
                      />
                    </a>
                  </div>

                  <div class="additional-images-container">
                    <div class="additional-images owl-loaded owl-carousel owl-drag">
                      <div class="owl-stage-outer">
                        <div
                          class="owl-stage"
                          style={{
                            transform: "translate3d(-89px, 0px, 0px)",
                            transition: "all 0.25s ease 0s",
                            width: "356px",
                          }}
                        >
                          <div class="owl-item" style={{ width: "89px" }}>
                            <div class="image-additional">
                              <img
                                src="/assets/four.jpg"
                                title="Foam Lite"
                                data-image="https://restosleep.in/image/cache/catalog/Products/Form%20Lite/FoamLite%201-800x1040.jpg"
                                data-zoom-image="https://restosleep.in/image/cache/catalog/Products/Form%20Lite/FoamLite%201-800x1040.jpg"
                                alt="Foam Lite"
                              />
                            </div>
                          </div>
                          <div
                            class="owl-item active"
                            style={{ width: "89px" }}
                          >
                            <div class="image-additional">
                              <img
                                src="/assets/two.jpg"
                                title="Foam Lite"
                                data-image="https://restosleep.in/image/cache/catalog/Products/Form%20Lite/FoamLite%204-800x1040.jpg"
                                data-zoom-image="https://restosleep.in/image/cache/catalog/Products/Form%20Lite/FoamLite%204-800x1040.jpg"
                                alt="Foam Lite"
                                class=""
                              />
                            </div>
                          </div>
                          <div
                            class="owl-item active"
                            style={{ width: "89px" }}
                          >
                            <div class="image-additional">
                              <img
                                src="/assets/three.jpg"
                                title="Foam Lite"
                                data-image="https://restosleep.in/image/cache/catalog/Products/Form%20Lite/FoamLite%203-800x1040.jpg"
                                data-zoom-image="https://restosleep.in/image/cache/catalog/Products/Form%20Lite/FoamLite%203-800x1040.jpg"
                                alt="Foam Lite"
                                class=""
                              />
                            </div>
                          </div>
                          <div
                            class="owl-item active"
                            style={{ width: "89px" }}
                          >
                            <div class="image-additional">
                              <img
                                src="/assets/three.jpg"
                                title="Foam Lite"
                                data-image="https://restosleep.in/image/cache/catalog/Products/Form%20Lite/FoamLite%202-800x1040.jpg"
                                data-zoom-image="https://restosleep.in/image/cache/catalog/Products/Form%20Lite/FoamLite%202-800x1040.jpg"
                                alt="Foam Lite"
                                class="selected"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="owl-nav">
                        <button
                          type="button"
                          role="presentation"
                          class="owl-prev"
                        >
                          Prev<span aria-label="Previous">‹</span>
                        </button>
                        <button
                          type="button"
                          role="presentation"
                          class="owl-next disabled"
                        >
                          Next<span aria-label="Next">›</span>
                        </button>
                      </div>
                      <div class="owl-dots disabled"></div>
                    </div>
                  </div>
                  {/* <!-- Product Image thumbnails END --> */}
                </div>
              </div>
              =
              <div class="col-xs-12 col-sm-7 col-md-7 product-details">
                <h1 class="product-name">Foam Lite</h1>

                <table class="product-info">
                  <tbody>
                    <tr>
                      <td>Brands</td>
                      <td class="product-info-value">
                        <a href="https://restosleep.in/index.php?route=product/manufacturer/info&amp;manufacturer_id=8">
                          Rest O Sleep
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Product Code:</td>
                      <td class="product-info-value">PC002</td>
                    </tr>
                    <tr>
                      <td>Availability:</td>
                      <td class="product-info-value">In Stock</td>
                    </tr>
                  </tbody>
                </table>

                {/* <!-- Product Rating START --> */}
                <div class="rating">
                  <div class="product-rating">
                    <span class="fa fa-stack">
                      <i class="fa fa-star fa-stack-1x"></i>
                    </span>
                    <span class="fa fa-stack">
                      <i class="fa fa-star fa-stack-1x"></i>
                    </span>
                    <span class="fa fa-stack">
                      <i class="fa fa-star fa-stack-1x"></i>
                    </span>
                    <span class="fa fa-stack">
                      <i class="fa fa-star fa-stack-1x"></i>
                    </span>
                    <span class="fa fa-stack">
                      <i class="fa fa-star off fa-stack-1x"></i>
                    </span>
                  </div>
                  <a
                    class="product-total-review"
                    href="https://restosleep.in/"
                    onclick="$(&#39;a[href=\&#39;#tab-review\&#39;]&#39;).trigger(&#39;click&#39;); return false;"
                  >
                    <i class="fa fa-comment"></i>1 reviews
                  </a>
                  <a
                    class="product-write-review"
                    href="https://restosleep.in/"
                    onclick="$(&#39;a[href=\&#39;#tab-review\&#39;]&#39;).trigger(&#39;click&#39;); return false;"
                  >
                    <i class="fa fa-pencil"></i>Write a review
                  </a>
                </div>
                {/* <!-- Product Rating END --> */}

                <ul class="list-unstyled product-price">
                  <li>
                    <h2 class="holder_price_reg">Rs 4,834.00</h2>
                  </li>
                  <li class="product-tax">Ex Tax: Rs 4,834.00</li>
                </ul>

                {/* <!-- Product Options START --> */}
                <div id="product" class="product-options">
                  <h3>Available Options</h3>
                  <div class="form-group required">
                    <label class="control-label" for="input-option240">
                      SIZE
                    </label>
                    <div class="select-wrapper">
                      <select
                        name="option[240]"
                        id="input-option240"
                        class="form-control"
                      >
                        <option value="">--- Please Select ---</option>
                        <option value="206">72 x 30 x 4 Inch</option>
                        <option value="268">72 x 30 x 6 Inch</option>
                        <option value="237">72 x 30 x 5 Inch</option>
                        <option value="207">72 x 36 x 4 Inch</option>
                        <option value="269">72 x 36 x 6 Inch</option>
                        <option value="238">72 x 36 x 5 Inch</option>
                        <option value="208">72 x 42 x 4 Inch</option>
                        <option value="270">72 x 42 x 6 Inch</option>
                        <option value="239">72 x 42 x 5 Inch</option>
                        <option value="240">72 x 48 x 5 Inch</option>
                        <option value="209">72 x 48 x 4 Inch</option>
                        <option value="271">72 x 48 x 6 Inch</option>
                        <option value="241">72 x 54 x 5 Inch</option>
                        <option value="272">72 x 54 x 6 Inch</option>
                        <option value="210">72 x 54 x 4 Inch</option>
                        <option value="199">72 x 60 x 5 Inch</option>
                        <option value="198">72 x 60 x 4 Inch</option>
                        <option value="273">72 x 60 x 6 Inch</option>
                        <option value="274">72 x 66 x 6 Inch</option>
                        <option value="242">72 x 66 x 5 Inch</option>
                        <option value="211">72 x 66 x 4 Inch</option>
                        <option value="275">72 x 72 x 6 Inch</option>
                        <option value="212">72 x 72 x 4 Inch</option>
                        <option value="243">72 x 72 x 5 Inch</option>
                        <option value="213">75 x 30 x 4 Inch</option>
                        <option value="276">75 x 30 x 6 Inch</option>
                        <option value="244">75 x 30 x 5 Inch</option>
                        <option value="214">75 x 36 x 4 Inch</option>
                        <option value="245">75 x 36 x 5 Inch</option>
                        <option value="277">75 x 36 x 6 Inch</option>
                        <option value="246">75x 42 x 5 Inch</option>
                        <option value="215">75x 42 x 4 Inch</option>
                        <option value="278">75x 42 x 6 Inch</option>
                        <option value="279">75 x 48 x 6 Inch</option>
                        <option value="216">75 x 48 x 4 Inch</option>
                        <option value="247">75 x 48 x 5 Inch</option>
                        <option value="248">75 x 54 x 5 Inch</option>
                        <option value="280">75 x 54 x 6 Inch</option>
                        <option value="217">75 x 54 x 4 Inch</option>
                        <option value="249">75 x 60 x 5 Inch</option>
                        <option value="218">75 x 60 x 4 Inch</option>
                        <option value="281">75 x 60 x 6 Inch</option>
                        <option value="250">75 x 66 x 5 Inch</option>
                        <option value="282">75 x 66 x 6 Inch</option>
                        <option value="219">75 x 66 x 4 Inch</option>
                        <option value="220">75 x 72 x 4 Inch</option>
                        <option value="251">75 x 72 x 5 Inch</option>
                        <option value="283">75 x 72 x 6 Inch</option>
                        <option value="221">78 x 30 x 4 Inch</option>
                        <option value="252">78 x 30 x 5 Inch</option>
                        <option value="284">78 x 30 x 6 Inch</option>
                        <option value="222">78 x 36 x 4 Inch</option>
                        <option value="253">78 x 36 x 5 Inch</option>
                        <option value="285">78 x 36 x 6 Inch</option>
                        <option value="286">78 x 42 x 6 Inch</option>
                        <option value="223">78 x 42 x 4 Inch</option>
                        <option value="254">78 x 42 x 5 Inch</option>
                        <option value="255">78 x 48 x 5 Inch</option>
                        <option value="287">78 x 48 x 6 Inch</option>
                        <option value="224">78 x 48 x 4 Inch</option>
                        <option value="256">78 x 54 x 5 Inch</option>
                        <option value="288">78 x 54 x 6 Inch</option>
                        <option value="225">78 x 54 x 4 Inch</option>
                        <option value="257">78 x 60 x 5 Inch</option>
                        <option value="289">78 x 60 x 6 Inch</option>
                        <option value="226">78 x 60 x 4 Inch</option>
                        <option value="227">78 x 66 x 4 Inch</option>
                        <option value="258">78 x 66 x 5 Inch</option>
                        <option value="290">78 x 66 x 6 Inch</option>
                        <option value="228">78 x 72 x 4 Inch</option>
                        <option value="259">78 x 72 x 5 Inch</option>
                        <option value="291">78 x 72 x 6 Inch</option>
                        <option value="292">84 x 30 x 6 Inch</option>
                        <option value="229">84 x 30 x 4 Inch</option>
                        <option value="260">84 x 30 x 5 Inch</option>
                        <option value="261">84 x 36 x 5 Inch</option>
                        <option value="293">84 x 36 x 6 Inch</option>
                        <option value="230">84 x 36 x 4 Inch</option>
                        <option value="262">84 x 42 x 5 Inch</option>
                        <option value="294">84 x 42 x 6 Inch</option>
                        <option value="231">84 x 42 x 4 Inch</option>
                        <option value="263">84 x 48 x 5 Inch</option>
                        <option value="295">84 x 48 x 6 Inch</option>
                        <option value="232">84 x 48 x 4 Inch</option>
                        <option value="233">84 x 54 x 4 Inch</option>
                        <option value="264">84 x 54 x 5 Inch</option>
                        <option value="296">84 x 54 x 6 Inch</option>
                        <option value="234">84 x 60 x 4 Inch</option>
                        <option value="265">84 x 60 x 5 Inch</option>
                        <option value="297">84 x 60 x 6 Inch</option>
                        <option value="235">84 x 66 x 4 Inch</option>
                        <option value="266">84 x 66 x 5 Inch</option>
                        <option value="298">84 x 66 x 6 Inch</option>
                        <option value="299">84 x 72 x 6 Inch</option>
                        <option value="236">84 x 72 x 4 Inch</option>
                        <option value="267">84 x 72 x 5 Inch</option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group product-quantity">
                    <label class="control-label" for="input-quantity">
                      Qty
                    </label>
                    <input
                      type="text"
                      name="quantity"
                      value="1"
                      size="2"
                      id="input-quantity"
                      class="form-control"
                    />
                    <input type="hidden" name="product_id" value="51" />
                    <button
                      type="button"
                      id="button-cart"
                      data-loading-text="Loading..."
                      class="btn btn-primary btn-lg btn-block"
                    >
                      Add to Cart
                    </button>
                  </div>
                </div>
                {/* <!-- Product Options END --> */}

                {/* <!-- Product Wishlist Compare START --> */}
                <div class="btn-group">
                  <button
                    class="btn btn-default product-btn-wishlist"
                    type="button"
                    title="Add to Wish List"
                    onclick="wishlist.add(&#39;51&#39;);"
                  >
                    <i class="fa fa-heart"></i>
                    Add to Wish List
                  </button>
                  <button
                    class="btn btn-default product-btn-compare"
                    type="button"
                    title="Compare this Product"
                    onclick="compare.add(&#39;51&#39;);"
                  >
                    <i class="fa fa-exchange"></i>
                    Compare this Product
                  </button>
                </div>
                {/* <!-- Product Wishlist Compare END --> */}

                {/* <!-- AddThis Button BEGIN --> */}
                <div
                  class="addthis_toolbox addthis_default_style"
                  data-url="https://restosleep.in/index.php?route=product/product&amp;product_id=51"
                >
                  <a class="addthis_button_tweet"></a>
                  <a class="addthis_button_pinterest_pinit"></a>
                  <a class="addthis_counter addthis_pill_style"></a>
                </div>
                <script src="./Foam Lite_files/addthis_widget.js.download"></script>
                {/* <!-- AddThis Button END --> */}
              </div>
              {/* <!-- Product option details END --> */}
            </div>
            {/* <!-- Product row END --> */}
          </div>
          {/* <!-- id content END --> */}

          {/* <!-- Product nav Tabs START --> */}
          <div class="col-sm-12 product-tabs">
            <ul class="nav nav-tabs">
              <li class="">
                <a data-toggle="tab" aria-expanded="false">
                  Description
                </a>
              </li>
              <li class="li-tab-review active">
                <a data-toggle="tab" aria-expanded="true">
                  Reviews (1)
                </a>
              </li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane" id="tab-description">
                <p>
                  <span style={{ fontSize: "14px" }}>
                    Foam lite&nbsp; with High density PU foam aided with
                    generous thick PU foam comfort layer quilt on top with a
                    unique design &amp; Floral tapestry. Experience the
                    comfortable sleep.
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: "14px" }}>
                    <br />
                  </span>
                  <span style={{ fontSize: "14px" }}>
                    Homogeneous thickness PU foam core layer - Medium- firm
                    support to the body.
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: "14px" }}>
                    <br />
                  </span>
                  <span style={{ fontSize: "14px" }}>
                    Mattress Crafted to perfection - Cotton tapestry tailored
                    with thread locked by web tape finish.
                  </span>
                </p>
                <p>
                  <br />
                </p>
                <p>
                  <span style={{ fontSize: "14px" }}>
                    <b>Product Features</b>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: "14px" }}>
                    <br />
                  </span>
                </p>
                <ol>
                  <li>
                    <span style={{ fontSize: "14px" }}>Foam Mattress</span>
                  </li>
                  <li>
                    <span style={{ fontSize: "14px" }}>Soft Eco foam</span>
                  </li>
                  <li>
                    <span style={{ fontSize: "14px" }}>
                      Additional quilt layer
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: "14px" }}>cotton fabric</span>
                  </li>
                  <li>
                    <span style={{ fontSize: "14px" }}>
                      Reversible mattress&nbsp;
                    </span>
                  </li>
                </ol>
                <p>
                  <br />
                </p>
                <p>
                  <span style={{ fontSize: "14px" }}>
                    <b>Warranty - 5 Years</b>
                  </span>
                </p>
              </div>
              <div class="tab-pane active" id="tab-review">
                <form class="form-horizontal" id="form-review">
                  <div id="review">
                    <table class="table table-striped table-bordered">
                      <tbody>
                        <tr>
                          <td style={{ width: "50%" }}>
                            <strong>Asad&nbsp;Ahmed</strong>
                          </td>
                          <td class="text-right">07/09/2021</td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            <p>
                              The product is super comfortable and worth the
                              price. Completely satisfied with the product
                            </p>
                            <span class="fa fa-stack">
                              <i class="fa fa-star fa-stack-2x"></i>
                            </span>
                            <span class="fa fa-stack">
                              <i class="fa fa-star fa-stack-2x"></i>
                            </span>
                            <span class="fa fa-stack">
                              <i class="fa fa-star fa-stack-2x"></i>
                            </span>
                            <span class="fa fa-stack">
                              <i class="fa fa-star fa-stack-2x"></i>
                            </span>

                            <span class="fa fa-stack">
                              <i class="fa fa-star off fa-stack-2x"></i>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="text-right"></div>
                  </div>
                  <h2>Write a review</h2>
                  Please
                  <a href="https://restosleep.in/index.php?route=account/login">
                    login
                  </a>
                  or
                  <a href="https://restosleep.in/index.php?route=account/register">
                    register
                  </a>
                  to review
                </form>
              </div>
            </div>
          </div>
          {/* <!-- Product tab END -->

          <!-- Related products START -->
          <!-- Related products END --> */}
          {/* <script>
            // Carousel Counter
            colsCarousel = $("#column-right, #column-left").length;
            if (colsCarousel == 2) {
              ci = 4;
            } else if (colsCarousel == 1) {
              ci = 4;
            } else {
              ci = 5;
            }

            var ttrelatedcontent = $(".related-items").owlCarousel({
              items: 4, //1 items above 1000px browser width
              nav: true,
              dots: false,
              loop: false,
              autoplay: false,
              rtl: false,
              responsive: {
                0: {
                  items: 1,
                },
                481: {
                  items: 2,
                },
                768: {
                  items: 3,
                },
                992: {
                  items: 4,
                },
                1400: {
                  items: 4,
                },
              },
            });
          </script> */}
        </div>
      </div>
    </>
  );
};

export default FoamLite;
