import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react";
import { persistedStore, store } from "./store/store";
import { BrowserRouter as Router } from "react-router-dom"
import RouteIdentifier from "./routes/RouteIdentifier";

// import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistedStore}>
          <Router>
            <RouteIdentifier />
          </Router>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
