import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'
import Layout from '../layout/layout/Layout'
import { mainMenuRoutes, privateRoutes, routes } from './routes'

export default function RouteApp() {
  const user = useSelector(state => state.auth.isLogedIn)
  const currency = useSelector(state => state.currency)

  const dispatch = useDispatch()

  // const getStorePrefrences = () => {
  //   axios.get(`${storeApi.prefrences}/GetStorePreferenceByStoreId/1`)
  //     .then(response => {
  //       dispatch({ type: ADD_PREFRENCES, payload: response.data.data })
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     })
  // }

  // useEffect(() => {
  //   getStorePrefrences()
  //   if (!currency.id) {
  //     getDefaultCurrency()
  //   }
  // }, [])

  const allRoutes = [...mainMenuRoutes, ...routes]
  return (
    <Routes>
      {privateRoutes.map((route, i) => {
        if (!user) {
          return <Route exact
            path={`*`}
            element={<Navigate to={`/`} />}
          />
        }
        return <Route element={<Layout />} key={i} >
          <Route path={route.path} element={route.component} />
        </Route>
      })}

      {allRoutes.map((route, i) => {
        return <Route element={<Layout />} key={i}>
          <Route path={route.path} element={route.component} />
        </Route>
      })}
    </Routes>
  )

}
