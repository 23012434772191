import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLogin: false,
  currentUser: {},
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUser(state, action) {
      state.currentUser = action.payload;
      state.isLogin = true;
    },
    logoutCurrentUser(state, action) {
      state.currentUser = {}
      state.isLogin = false
    },
    updateUser(state, action) {
      const user = state.currentUser
      user.customers = action.payload
      state.currentUser = user
    }
  },
});

export const { setCurrentUser, logoutCurrentUser, updateUser } = authSlice.actions;
const authReducer = authSlice.reducer;

export default authReducer;
