import React, { useEffect, useState } from "react";
import ProductBlock from "../../components/ProductBlock";
import TitleBar from "../../components/TitleBar";
import "./Slider.css";
import Modules from "../../components/modules/Modules";
import StoresApi from "../../assets/api/StoreApis";
import axios from '../../assets/axios/axios'
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { addStore } from "../../slice/storeFront";
import HomeSlider from "../../components/slider/HomeSlider";

export default function Home() {

  const { code } = useParams()
  const dispatch = useDispatch()
  const storeCode = window.location.pathname.split("/")[2]

  const store = useSelector(state => state.storeFront)
  const [isOpenCategoriesModal, setIsOpenCategoriesModal] = useState(false);
  const [products, setProducts] = useState([])

  const getData = (storeId) => {
    axios.get(`${StoresApi.PriceListUrl}/GetStoreFrontProducts/${storeId}`)
      .then(response => {

        const activeProducts = response.data.data.filter(product => product.product.status)
        setProducts(activeProducts)
        // dispatch(addProducts(response.data.data))
      })
      .catch(error => {
        console.log(error.response);
      })
  }

  const getStore = (storeId) => {
    axios.get(`${StoresApi.StoreUrl}/GetStoreFront/${storeId}`)
      .then(response => {
        getData(response.data.data.id)
        dispatch(addStore(response.data.data))
      })
      .catch(error => {
        console.log(error.response);
      })

  }

  useEffect(() => {
    axios.get(`${StoresApi.StoreUrl}/GetStoreByCode/${"RestOsleep"}`)
      .then(response => {
        dispatch(addStore(response.data.data))
        // getData(response.data.data.id)
      })
      .catch(error => {
        console.log(error);
      })
    // getStore(3)
  }, [])




  return (
    <>
      <div class="content-top">
        <HomeSlider />

        <div className="tt-home">
          <div className="col-sm-12">
            {/* about us content start  */}
            <div class="html-content">
              {" "}
              <div class="box-content">
                <div id="ttcmsaboutus">
                  <div class="ttaboutus">
                    <div class="container">
                      <div class="row">
                        <div class="about-left about-block col-xs-12 col-xs-12">
                          <div class="about-content col-xs-12 col-xs-12 right-to-left hb-animate-element hb-in-viewport">
                            <div class="box-heading">
                              <h3
                                style={{
                                  color: "#111",
                                  fontWeight: 500,
                                  fontSize: "40px",
                                  lineHeight: "44px",
                                }}
                              >
                                About Us
                              </h3>
                            </div>
                            <div class="img-desc">
                              <p style={{ fontSize: "larger", lineHeight: 2 }}>
                                {" "}
                                Rest O Sleep is an ingeniously built product
                                from a reputed mattress manufacturing company
                                since early 90's. Our product is backed by the
                                state of art manufacturing technology,
                                scientific research and cutting-edge innovation
                                to ensure your body receives the right support
                                and comfort to enjoy optimum sleep benefits.
                              </p>
                              <p style={{ fontSize: "larger", lineHeight: 2 }}>
                                {" "}
                                The mattress is indigenously crafted in our own
                                manufacturing units with high-quality control
                                measures at every stage with better raw
                                materials, to offer you a highly competitive
                                product that meets the top brand standard and
                                offers the best value for money. This direct
                                factory sale product helps save nearly 50% of
                                the cost when compared to some top-notch brands
                                in the market.
                              </p>
                              <p style={{ fontSize: "larger", lineHeight: 2 }}>
                                {" "}
                                Rest O Sleep range of products ensures relief
                                measures too, especially for the neck, spine and
                                back. Our diversified offering includes
                                mattresses, pillows and mats across different
                                materials that cater to specific needs. Our
                                mattresses are available in PU foam, Orthopaedic
                                Memory Foam Dual Comfort Mattress, Hybrid Bonnel
                                spring, Rubberized Coir, High-Density Foam to
                                meet your every need.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* about us content end  */}

            <div className="row">
              <Modules />
            </div>
            {/* <div class="html-content">
              {" "}
              <div class="box-content">
                <div id="ttcmsservices">
                  <div class="container">
                    <div class="ttcmsservices owl-loaded owl-carousel owl-drag">
                      <div class="owl-stage-outer">
                        <div
                          class="owl-stage"
                          style={{
                            transform: "translate3d(0px, 0px, 0px)",
                            transition: " all 0s ease 0s",
                            width: "1230px",
                          }}
                        >
                          <div
                            class="owl-item active"
                            style={{ width: "307.5px" }}
                          >
                            <div class="ttservice col-xs-12 col-sm-6 col-md-3">
                              <div class="icon">
                                <div class="ttgift_img service-icon"></div>
                              </div>
                              <div class="service-content">
                                <div class="service-title">Free Shipping</div>

                                <div class="service-desc">
                                  Send a Gift Products
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="owl-item active"
                            style={{ width: "307.5px" }}
                          >
                            <div class="ttservice col-xs-12 col-sm-6 col-md-3">
                              <div class="icon">
                                <div class="ttdisc_img service-icon"></div>
                              </div>
                              <div class="service-content">
                                <div class="service-title">Easy Finance</div>
                                <div class="service-desc">
                                  Discounts Everyday
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="owl-item active"
                            style={{ width: "307.5px" }}
                          >
                            <div class="ttservice col-xs-12 col-sm-6 col-md-3">
                              <div class="icon">
                                <div class="ttshipping_img service-icon"></div>
                              </div>
                              <div class="service-content">
                                <div class="service-title">24*7 Support</div>
                                <div class="service-desc">On Order Of $99</div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="owl-item active"
                            style={{ width: "307.5px" }}
                          >
                            <div class="ttservice col-xs-12 col-sm-6 col-md-3">
                              <div class="icon">
                                <div class="ttsupport_img service-icon"></div>
                              </div>
                              <div class="service-content">
                                <div class="service-title">Gift Vouchers</div>
                                <div class="service-desc">
                                  On Demand Support
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                     
                      <div class="owl-dots disabled"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>  */}
            {/* <div className="centered-content mobile-only">
              <center>
                <div className="tab-content">
                  <div className="tab-pane active">
                    <div className="products-carousel owl-loaded owl-carousel owl-drag">
                      <div className="owl-nav custom-nav">
                        <button
                          type="button"
                          role="presentation"
                          className="owl-prev"
                        >
                          Prev<span aria-label="Previous">‹</span>
                        </button>
                        <button
                          type="button"
                          role="presentation"
                          className="owl-next"
                        >
                          Next<span aria-label="Next">›</span>
                        </button>
                      </div>
                      <div className="owl-dots custom-dots disabled"></div>
                    </div>
                  </div>
                </div>
              </center>
            </div> */}
            <div className="html-content">
              <div className="box-content">
                <div id="ttcmsservices">
                  <div className="container">
                    <div className="ttcmsservices owl-loaded owl-carousel owl-drag">
                      <div className="owl-stage-outer">
                        {/* <center> */}
                        <div className="owl-stage row ">
                          <div
                            className="owl-item active"
                            style={{ width: "25%" }}
                          >
                            <div className="ttservice col-xs-12 col-sm-6 col-md-6">
                              <div className="icon">
                                <div className="ttgift_img service-icon"></div>
                              </div>
                              <div className="service-content">
                                <div className="service-title">
                                  Free Shipping
                                </div>
                                <div className="service-desc">
                                  Send a Gift Products
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Repeat this structure for other items */}

                          <div class="owl-item active" style={{ width: "25%" }}>
                            <div class="ttservice col-xs-12 col-sm-6 col-md-6">
                              <div class="icon">
                                <div class="ttdisc_img service-icon"></div>
                              </div>
                              <div class="service-content">
                                <div class="service-title">Easy Finance</div>
                                <div class="service-desc">
                                  Discounts Everyday
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="owl-item active" style={{ width: "25%" }}>
                            <div class="ttservice col-xs-12 col-sm-6 col-md-6">
                              <div class="icon">
                                <div class="ttsupport_img service-icon"></div>
                              </div>
                              <div class="service-content">
                                <div class="service-title">Gift Vouchers</div>
                                <div class="service-desc">
                                  On Demand Support
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="owl-item active" style={{ width: "25%" }}>
                            <div class="ttservice col-xs-12 col-sm-6 col-md-6">
                              <div class="icon">
                                <div class="ttshipping_img service-icon"></div>
                              </div>
                              <div class="service-content">
                                <div class="service-title">24*7 Support</div>
                                <div class="service-desc">On Order Of $99</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* </center> */}
                      </div>
                      <div className="owl-dots disabled"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="html-content">
              {" "}
              <div className="box-content">
                <p>
                  <img
                    src="assets/images/GIF.jpg"
                    style={{ width: "100%" }}
                  />
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="goToTop"
        title="Top"
        class="goToTop ttbox-img"
        style={{ display: "block" }}
      ></div>
    </>
  );
}
