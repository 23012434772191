import React from "react";
import { useNavigate } from "react-router";

export default function ContactUs() {
  const navigate = useNavigate();

  const handleHome = () => {
    navigate("/");
  };
  return (
    <>
      <div class="header-content-title">
        <div class="page-title-wrapper">
          <div class="container">
            <div class="breadcrub">
              <h1 class="page-title">Contact Us</h1>
              <ul class="breadcrumb">
                <li>
                  <a onClick={handleHome} style={{ cursor: "pointer" }}>
                    <i class="fa fa-home"></i>
                  </a>
                </li>
                <li>
                  <a class="last-breadcrumb">Contact Us</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div id="information-contact" class="container">
        <div class="row">
          <div id="content" class="col-sm-12 Cwidth" style={{ width: "100%" }}>
            <h3>Our Location</h3>
            {/* <div class="panel panel-default contact-location">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-3 store-image">
                    <img
                      src="/191x60px Logo-01-571x380.jpg"
                      alt="Rest O sleep"
                      title="Rest O sleep"
                      class="img-thumbnail"
                    />
                  </div>

                  <div class="col-sm-3 store-address">
                    <div class="location-title">
                      <i class="fa fa-home"></i> Rest O sleep{" "}
                    </div>
                  
                  </div>

                  <div class="col-sm-3 store-contact">
                    <div class="location-title">
                      <i class="fa fa-phone"></i> Telephone{" "}
                    </div>
                    <div class="location-detail"> 08023551022 </div>
                  </div>

                  <div class="col-sm-3 store-status"></div>
                </div>
              </div>
            </div> */}
            <div class="panel panel-default contact-location">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-3 store-image">
                    <img
                      src="/191x60px Logo-01-571x380.jpg"
                      alt="Rest O sleep"
                      title="Rest O sleep"
                      class="img-thumbnail"
                    />
                  </div>

                  <div class="col-sm-3 store-address">
                    <div class="location-title">
                      <i class="fa fa-home"></i> Rest O sleep{" "}
                    </div>
                    <div class="location-detail">
                      Restosleep coir &amp; foam products. <br />
                      5th cross, Muneshwara nagar
                      <br />
                      Bangalore 560045
                      <br />
                      India
                      <br />
                      <br />
                      Experience center : L-4 10th main Jeevan bhima nagar{" "}
                      <br />
                      Bangalore 56075.
                    </div>
                  </div>

                  <div class="col-sm-3 store-contact">
                    <div class="location-title">
                      <i class="fa fa-phone"></i> Telephone{" "}
                    </div>
                    <div class="location-detail"> 08023551022 </div>
                  </div>

                  <div class="col-sm-3 store-status"></div>
                </div>
              </div>
            </div>
            <form
              action="https://restosleep.in/index.php?route=information/contact"
              method="post"
              enctype="multipart/form-data"
              class="form-horizontal"
            >
              <fieldset>
                <legend>Contact Us</legend>
                <div class="form-group required">
                  <label class="col-sm-2 control-label" for="input-name">
                    Name
                  </label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      name="name"
                      value=""
                      id="input-name"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="form-group required">
                  <label class="col-sm-2 control-label" for="input-email">
                    Your email address
                  </label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      name="email"
                      value=""
                      id="input-email"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="form-group required">
                  <label class="col-sm-2 control-label" for="input-enquiry">
                    Enquiry
                  </label>
                  <div class="col-sm-10">
                    <textarea
                      name="enquiry"
                      rows="10"
                      id="input-enquiry"
                      class="form-control"
                    ></textarea>
                  </div>
                </div>
              </fieldset>
              <div class="buttons">
                <div class="pull-right">
                  <input class="btn btn-primary" type="submit" value="Submit" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        id="goToTop"
        title="Top"
        class="goToTop ttbox-img"
        style={{ display: "block" }}
      ></div>
    </>
  );
}
