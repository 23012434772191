import React from "react";
import { Link } from "react-router-dom";
import TitleBar from "../../components/TitleBar";
import AccountSection from "../../components/AccountSection";

export default function Accounts() {
  return (
    <>
      <TitleBar />
      <div id="account-account" class="container">
        <div class="row">
          <div id="content" class="col-sm-12 Cwidth" style={{ width: "100%" }}>
            <div class="a-link-list">
              <div class="a-link-heading">
                <h2>My Account</h2>
              </div>
              <div class="a-link-content">
                <ul class="list-unstyled">
                  <li>
                    <a 
                    href="https://restosleep.in/index.php?route=account/edit"
                    >
                      Edit Account
                    </a>
                  </li>
                  <li>
                    <a
                     href="https://restosleep.in/index.php?route=account/password"
                     >
                      Password
                    </a>
                  </li>
                  <li>
                    <a 
                    href="https://restosleep.in/index.php?route=account/address"
                    >
                      Address Book
                    </a>
                  </li>
                  <li>
                    <a
                     href="https://restosleep.in/index.php?route=account/wishlist"
                     >
                      Modify your wish list
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="a-link-list">
              <div class="a-link-heading">
                <h2>My Orders</h2>
              </div>
              <div class="a-link-content">
                <ul class="list-unstyled">
                  <li>
                    <Link to="/orders">Order History</Link>
                  </li>
                  <li>
                    <a 
                    href="https://restosleep.in/index.php?route=account/download"
                    >
                      Downloads
                    </a>
                  </li>
                  <li>
                    <a 
                    href="https://restosleep.in/index.php?route=account/reward"
                    >
                      Reward Points
                    </a>
                  </li>
                  <li>
                    <a href="https://restosleep.in/index.php?route=account/return">
                      Returns
                    </a>
                  </li>
                  <li>
                    <a href="https://restosleep.in/index.php?route=account/transaction">
                      Transactions
                    </a>
                  </li>
                  <li>
                    <a href="https://restosleep.in/index.php?route=account/recurring">
                      Payment Profile
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="a-link-list">
              <div class="a-link-heading">
                <h2>My Affiliate Account</h2>
              </div>
              <div class="a-link-content">
                <ul class="list-unstyled">
                  <li>
                    <a href="https://restosleep.in/index.php?route=account/affiliate/add">
                      Register for an affiliate account
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="a-link-list">
              <div class="a-link-heading">
                <h2>Newsletter</h2>
              </div>
              <div class="a-link-content">
                <ul class="list-unstyled">
                  <li>
                    <a href="https://restosleep.in/index.php?route=account/newsletter">
                      Newsletter
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <AccountSection/>
          </div>
        </div>
      </div>
    </>
  );
}
