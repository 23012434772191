// import React from "react";

// const PrivacyPolicy = () => {
//   return (
//     <>
//       <div id="information-information" class="container">
//         <div class="row">
//           <div id="content" class="col-sm-12 Cwidth" style="width: 100%;">
//             <p
//               class="MsoNormal"
//               align="center"
//               style={{
//                 marginBottom: "13.5pt",
//                 textAlign: "center",
//                 lineHeight: "25.5pt",
//                 backgroundImage: "initial",
//                 backgroundPosition: "initial",
//                 backgroundSize: "initial",
//                 backgroundRepeat: "initial",
//                 backgroundAttachment: "initial",
//                 backgroundOrigin: "initial",
//                 backgroundClip: "initial",
//               }}
//             >
//               <font color="#00008a" face="Arial, sans-serif">
//                 <span style={{ fontSize: "24px" }}>
//                   <b>Privacy Policy</b>
//                 </span>
//               </font>
//             </p>

//             <p
//               class="MsoNormal"
//               align="center"
//               style={{
//                 marginBottom: "27pt",
//                 textAlign: "center",
//                 lineHeight: "19.5pt",
//                 backgroundImage: "initial",
//                 backgroundPosition: "initial",
//                 backgroundSize: "initial",
//                 backgroundRepeat: "initial",
//                 backgroundAttachment: "initial",
//                 backgroundOrigin: "initial",
//                 backgroundClip: "initial",
//               }}
//             >
//               <font color="#455a64" face="Arial, sans-serif">
//                 <span style={{ fontSize: "16px" }}>
//                   We, at Restosleep, work hard to earn your trust and strive to
//                   protect your privacy at all times.&nbsp;
//                 </span>
//               </font>
//             </p>
//             <p
//               class="MsoNormal"
//               align="center"
//               style={{
//                 marginBottom: "27pt",
//                 textAlign: "center",
//                 lineHeight: "19.5pt",
//                 backgroundImage: "initial",
//                 backgroundPosition: "initial",
//                 backgroundSize: "initial",
//                 backgroundRepeat: "initial",
//                 backgroundAttachment: "initial",
//                 backgroundOrigin: "initial",
//                 backgroundClip: "initial",
//               }}
//             >
//               <font color="#00008a" face="Arial, sans-serif">
//                 <span style={{ fontSize: "24px" }}>Personal information</span>
//               </font>
//               <br />
//             </p>

//             <p
//               class="MsoNormal"
//               align="center"
//               style={{
//                 textAlign: "start",
//                 marginBottom: "27pt",
//                 lineHeight: "19.5pt",
//                 backgroundImage: "initial",
//                 backgroundPosition: "initial",
//                 backgroundSize: "initial",
//                 backgroundRepeat: "initial",
//                 backgroundAttachment: "initial",
//                 backgroundOrigin: "initial",
//                 backgroundClip: "initial",
//               }}
//             >
//               <font color="#455a64" face="Arial, sans-serif">
//                 <span style={{ fontSize: "16px" }}>
//                   We collect personal information (Name, Email id, Phone number,
//                   Address etc.,) from you during the course of transaction or at
//                   the time of account creation. We use this information from
//                   time to time to contact you for offers unless you explicitly
//                   opt out of such offers.&nbsp;
//                 </span>
//               </font>
//               <br />
//             </p>
//             <p
//               class="MsoNormal"
//               align="center"
//               style="margin-bottom: 27pt; text-align: center; line-height: 19.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"
//             >
//               <font color="#00008a" face="Arial, sans-serif">
//                 <span style={{ fontSize: "24px" }}>
//                   Use of demographic and profile data
//                 </span>
//               </font>
//               <br />
//             </p>

//             <ul type="disc"></ul>
//             <p style="margin-bottom: 6pt; line-height: 18pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">
//               <font color="#455a64" face="Arial, sans-serif">
//                 <span style={{ fontSize: "16px" }}>
//                   In our efforts to continually improve our product and service
//                   offerings, we collect and analyze&nbsp;demographic and profile
//                   data about our users' activity on our website. We identify and
//                   use your IP address to help diagnose problems with our server,
//                   and to administer our website. Your IP address is also used to
//                   help identify you and to gather broad demographic information.
//                   We will occasionally ask you to complete optional online
//                   surveys. These surveys may ask you for contact information and
//                   demographic information (like pin code, age, or income level).
//                   We use this data to tailor your experience at our site,
//                   providing you with content that we think you might be
//                   interested in--and to display content according to your
//                   preferences.
//                 </span>
//               </font>
//             </p>
//             <p
//               class="MsoNormal"
//               align="center"
//               style="margin-bottom: 27pt; text-align: center; line-height: 19.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"
//             >
//               <font color="#00008a" face="Arial, sans-serif">
//                 <span style={{ fontSize: "24px" }}>Security precautions</span>
//               </font>
//               <br />
//             </p>

//             <p
//               class="MsoNormal"
//               style="line-height: 18pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"
//             >
//               <font color="#455a64" face="Arial, sans-serif">
//                 <span style={{ fontSize: "16px" }}>
//                   Our site has stringent security measures in place to protect
//                   the loss, misuse, and alteration of the information under our
//                   control. Whenever you change or access your account
//                   information, we offer the use of a secure server. Once your
//                   information is in our possession we adhere to strict security
//                   guidelines, protecting it against unauthorized access
//                 </span>
//               </font>
//               <br />
//             </p>

//             <p
//               class="MsoNormal"
//               align="center"
//               style="margin-bottom: 27pt; text-align: center; line-height: 19.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"
//             >
//               <span
//                 style='font-size:18.0pt;font-family:"Arial","sans-serif";mso-fareast-font-family:
// "Times New Roman";color:#00008A'
//               >
//                 <br />
//               </span>
//             </p>
//             <p
//               class="MsoNormal"
//               align="center"
//               style="margin-bottom: 27pt; text-align: center; line-height: 19.5pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"
//             >
//               <font color="#00008a" face="Arial, sans-serif">
//                 <span style={{ fontSize: "24px" }}>
//                   Digital advertising &amp; cookie policy&nbsp;
//                 </span>
//               </font>
//               <br />
//             </p>

//             <p
//               class="MsoNormal"
//               style="line-height: 18pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"
//             >
//               <font color="#455a64" face="Arial, sans-serif">
//                 <span style={{ fontSize: "16px" }}>
//                   When visitors come to our website, third parties may place
//                   cookies on visitors' browsers for targeted advertising
//                   purposes&nbsp;
//                 </span>
//               </font>
//             </p>
//             <p
//               class="MsoNormal"
//               style="line-height: 18pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"
//             >
//               <font color="#455a64" face="Arial, sans-serif">
//                 <span style={{ fontSize: "16px" }}>
//                   We collect the following categories of information
//                 </span>
//               </font>
//             </p>
//             <ol>
//               <li style="line-height: 18pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">
//                 <font color="#455a64" face="Arial, sans-serif">
//                   <span style={{ fontSize: "16px" }}>
//                     Activity on Advertisers’ Digital Properties: This is data
//                     about your browsing activity on the Advertiser's website or
//                     app. For example, which pages you visited and when, what
//                     items were clicked on a page, how much time was spent on a
//                     page, whether you downloaded a white paper on a business to
//                     business website, what items you placed into your online
//                     shopping cart, what products were purchased and how much was
//                     paid.&nbsp;
//                   </span>
//                 </font>
//               </li>
//               <li style="line-height: 18pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">
//                 <font color="#455a64" face="Arial, sans-serif">
//                   <span style={{ fontSize: "16px" }}>
//                     Device and browser information: This is technical
//                     information about the device or browser you use to access
//                     the Advertiser's website. For example, your device's IP
//                     address, cookie string data, operating system, and (in the
//                     case of mobile devices) your device type and mobile device's
//                     unique identifier such as the Apple IDFA or Android
//                     Advertising ID.
//                   </span>
//                 </font>
//               </li>
//               <li style="line-height: 18pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">
//                 <font color="#455a64" face="Arial, sans-serif">
//                   <span style={{ fontSize: "16px" }}>
//                     Ad data: This is data about the online ads we have served
//                     (or attempted to serve) to you. It includes things like how
//                     many times an ad has been served to you, what page the ad
//                     appeared on, and whether you clicked on or otherwise
//                     interacted with the ad.
//                   </span>
//                 </font>
//               </li>
//             </ol>
//             <p style={{lineHeight: "18pt", backgroundImage: "initial", backgroundPosition: "initial", backgroundSize: "initial", backgroundRepeat: "initial", backgroundAttachment: "initial", backgroundOrigin: "initial", backgroundClip: "initial"}}>
//               <span style={{color: "rgb(69, 90, 100)", fontFamily: "Arial", fontSize: "16px"}}>
//                 &nbsp;
//               </span>
//               <font face="Arial">
//                 <span style="font-size: 14px;">
//                   In case, you would like to opt-out, please follow the
//                   directions below:
//                 </span>
//                 <br />
//               </font>
//             </p>
//             <p
//               class="MsoNormal"
//               style="margin-top:0cm;margin-right:0cm;margin-bottom:17.1pt;
// margin-left:-.25pt;line-height:107%"
//             >
//               <font face="Arial">
//                 <o:p></o:p>
//               </font>
//             </p>
//             <span style="font-size: 14px;"></span>
//             <p
//               class="MsoNormal"
//               style="margin-top:0cm;margin-right:0cm;margin-bottom:13.55pt;
// margin-left:-.25pt"
//             >
//               <font face="Arial">
//                 <span style="font-size: 14px;">
//                   We recognize how important your online privacy is to you, so
//                   we offer the following options for controlling the targeted
//                   ads you receive and how we use your data:
//                 </span>
//               </font>
//             </p>
//             <ul>
//               <li style="margin: 0cm 0cm 13.55pt -0.25pt;">
//                 <font face="Arial" style="text-indent: -18pt;">
//                   <span style="font-size: 14px;">
//                     You can opt out of receiving personalized ads served by us
//                     or on our behalf by clicking on the blue icon that typically
//                     appears in the corner of the ads we serve and following the
//                     instructions provided or by clicking
//                   </span>
//                   <a href="https://app.adroll.com/optout/safari">
//                     <span style="color: rgb(69, 90, 100); font-size: 14px;">
//                       {" "}
//                     </span>
//                   </a>
//                   <a href="https://app.adroll.com/optout/safari">
//                     <b>
//                       <span style="color: rgb(245, 0, 87); font-size: 14px;">
//                         here
//                       </span>
//                     </b>
//                   </a>
//                   <a href="https://app.adroll.com/optout/safari">
//                     <span style="color: rgb(69, 90, 100); font-size: 14px;">
//                       .
//                     </span>
//                   </a>
//                   <span style="font-size: 14px;"> Please note that this</span>
//                   &nbsp;
//                 </font>
//                 <span style="text-indent: -18pt; font-size: 14px; font-family: Arial; text-align: right;">
//                   “Opt out” function is browser-specific and relies on an “opt
//                   out cookie”: thus, if you&nbsp;
//                 </span>
//                 <span style="text-indent: -18pt; font-size: 14px; font-family: Arial;">
//                   Delete your cookies or upgrade your browser after having opted
//                   out, you will need to opt out again.
//                 </span>
//               </li>
//               <li style="margin: 0cm 0cm 13.55pt -0.25pt;">
//                 <font face="Arial">
//                   <span style="font-size: 14px;">
//                     In some cases we may link multiple browsers or devices to
//                     you. If you opt out of on a browser or device and we have
//                     more linked to you, we will extend your opt out decision to
//                     the other linked browsers and devices. Since we only link
//                     users across browsers on devices in some conditions, there
//                     could be cases where you are still being tracked in a
//                     different browser or device we have not linked, and where we
//                     are treating you as a different user.
//                   </span>
//                 </font>
//               </li>
//               <li style="margin: 0cm 0cm 13.55pt -0.25pt;">
//                 <font face="Arial">
//                   <span style="font-size: 14px;">
//                     We adhere to the NAI Codes of Conduct. You may use the NAI
//                     opt out tool
//                   </span>
//                   <a
//                     href="http://www.networkadvertising.org/choices/"
//                     style="text-indent: -18pt; background-color: rgb(255, 255, 255);"
//                   >
//                     <span style="color: rgb(69, 90, 100); font-size: 14px;">
//                       {" "}
//                     </span>
//                   </a>
//                   <a
//                     href="http://www.networkadvertising.org/choices/"
//                     style="text-indent: -18pt; background-color: rgb(255, 255, 255);"
//                   >
//                     <b>
//                       <span style="color: rgb(245, 0, 87); font-size: 14px;">
//                         here
//                       </span>
//                     </b>
//                   </a>
//                   <a
//                     href="http://www.networkadvertising.org/choices/"
//                     style="text-indent: -18pt; background-color: rgb(255, 255, 255);"
//                   >
//                     <span style="color: rgb(69, 90, 100); font-size: 14px;">
//                       ,
//                     </span>
//                   </a>
//                   <span style="text-indent: -18pt; font-size: 14px;">
//                     {" "}
//                     which will allow you to opt out of seeing personalized ads
//                     from us and from other NAI approved member companies.
//                   </span>
//                 </font>
//               </li>
//               <li style="margin: 0cm 0cm 13.55pt -0.25pt;">
//                 <span style="text-indent: -18pt;">
//                   <font face="Arial">
//                     <span style="font-size: 14px;">
//                       We also comply with the Self-Regulatory Principles for
//                       Online Behavioral&nbsp;Advertising as managed by the
//                       Digital Advertising Alliance (DAA). You may opt out of
//                       receiving personalized ads from other companies that
//                       perform ad targeting services, including some that we may
//                       work with as Advertising Partners via the DAA website
//                     </span>
//                   </font>
//                 </span>
//                 <a
//                   href="http://www.aboutads.info/choices/"
//                   style="font-family: Arial; text-indent: -18pt; background-color: rgb(255, 255, 255);"
//                 >
//                   <span style="color: rgb(69, 90, 100); font-size: 14px;">
//                     {" "}
//                   </span>
//                 </a>
//                 <a
//                   href="http://www.aboutads.info/choices/"
//                   style="font-family: Arial; text-indent: -18pt; background-color: rgb(255, 255, 255);"
//                 >
//                   <b>
//                     <span style="color: rgb(245, 0, 87); font-size: 14px;">
//                       here
//                     </span>
//                   </b>
//                 </a>
//                 <a
//                   href="http://www.aboutads.info/choices/"
//                   style="font-family: Arial; text-indent: -18pt; background-color: rgb(255, 255, 255);"
//                 >
//                   <span style="color: rgb(69, 90, 100); font-size: 14px;">
//                     .
//                   </span>
//                 </a>
//               </li>
//               <li style="margin: 0cm 0cm 13.55pt -0.25pt;">
//                 <font face="Arial">
//                   <span style="font-size: 14px;">
//                     We also comply with the Canadian Self-regulatory Principles
//                     for Online Behavioral Advertising as managed by the Digital
//                     Advertising Alliance of Canada (DAAC). You may opt out of
//                     receiving personalized ads from other companies that perform
//                     ad targeting services, including some that we may work with
//                     as Advertising Partners via the DAAC website
//                   </span>
//                   <a
//                     href="http://youradchoices.ca/choices/"
//                     style="background-color: rgb(255, 255, 255);"
//                   >
//                     <span style="color: rgb(69, 90, 100); font-size: 14px;">
//                       {" "}
//                     </span>
//                   </a>
//                   <a
//                     href="http://youradchoices.ca/choices/"
//                     style="background-color: rgb(255, 255, 255);"
//                   >
//                     <b>
//                       <span style="color: rgb(245, 0, 87); font-size: 14px;">
//                         here
//                       </span>
//                     </b>
//                   </a>
//                   <a
//                     href="http://youradchoices.ca/choices/"
//                     style="background-color: rgb(255, 255, 255);"
//                   >
//                     <span style="color: rgb(69, 90, 100); font-size: 14px;">
//                       .
//                     </span>
//                   </a>
//                 </font>
//               </li>
//               <li style="margin: 0cm 0cm 13.55pt -0.25pt;">
//                 <font face="Arial">
//                   <span style="font-size: 14px;">
//                     We also adhere to the European Interactive Advertising
//                     Digital Alliance (EDAA) guidelines for online advertising
//                     and you may opt out via their
//                   </span>
//                   <a
//                     href="http://www.youronlinechoices.com/"
//                     style="text-indent: -18pt; background-color: rgb(255, 255, 255);"
//                   >
//                     <span style="color: rgb(69, 90, 100); font-size: 14px;">
//                       {" "}
//                     </span>
//                   </a>
//                   <a
//                     href="http://www.youronlinechoices.com/"
//                     style="text-indent: -18pt; background-color: rgb(255, 255, 255);"
//                   >
//                     <b>
//                       <span style="color: rgb(245, 0, 87); font-size: 14px;">
//                         Your Online{" "}
//                       </span>
//                     </b>
//                   </a>
//                   <a
//                     href="http://www.youronlinechoices.com/"
//                     style="text-indent: -18pt; background-color: rgb(255, 255, 255);"
//                   >
//                     <b>
//                       <span style="color: rgb(245, 0, 87); font-size: 14px;">
//                         Choices
//                       </span>
//                     </b>
//                   </a>
//                   <a
//                     href="http://www.youronlinechoices.com/"
//                     style="text-indent: -18pt; background-color: rgb(255, 255, 255);"
//                   >
//                     <span style="color: rgb(69, 90, 100); font-size: 14px;">
//                       {" "}
//                     </span>
//                   </a>
//                   <span style="text-indent: -18pt; font-size: 14px;">
//                     website.
//                   </span>
//                 </font>
//               </li>
//               <li style="margin: 0cm 0cm 13.55pt -0.25pt;">
//                 <font face="Arial">
//                   <span style="font-size: 14px;">
//                     Please note that when using the ad industry opt-out tools
//                     described above:
//                   </span>
//                 </font>
//               </li>
//             </ul>
//             <span style="font-size: 14px;"></span>
//             <p
//               class="MsoNormal"
//               style="margin-left:72.05pt;text-indent:-18.0pt;mso-list:
// l0 level2 lfo1"
//             >
//               <font face="Arial">
//                 <span style="font-size: 10pt; line-height: 134%;">
//                   <span style="font-size: 14px;">o</span>
//                   <span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 14px; line-height: normal;">
//                     &nbsp;&nbsp;&nbsp;
//                   </span>
//                 </span>
//                 <span style="font-size: 14px;">
//                   If you opt-out your browser may still send us data, for
//                   example your IP address. However, we isolate this data and do
//                   not use it other than for accounting and, in some cases, for
//                   fraud prevention. If you have opted-out on that browser, we do
//                   not use this data to personalize ads or to track you.{" "}
//                 </span>
//                 <span style="font-size: 14px; line-height: 134%;">o</span>
//                 <span style="font-size: 14px; line-height: 134%;"> </span>
//                 <span style="font-size: 14px;">
//                   If you use multiple browsers or devices we will additionally
//                   opt out those we have linked to you. Since we may not have all
//                   your browsers or
//                 </span>
//                 &nbsp;
//               </font>
//               <span style="font-size: 14px; font-family: Arial;">
//                 Devices connected back to your user, you may need to execute
//                 this opt out on each browser or device.
//               </span>
//             </p>
//             <span style="font-size: 14px;"></span>
//             <p
//               class="MsoNormal"
//               style="margin-top:0cm;margin-right:0cm;margin-bottom:9.6pt;
// margin-left:72.05pt;text-indent:-18.0pt;line-height:107%;mso-list:l0 level2 lfo1"
//             >
//               <font face="Arial">
//                 <span style="font-size: 10pt; line-height: 107%;">
//                   <span style="font-size: 14px;">o</span>
//                   <span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 14px; line-height: normal;">
//                     &nbsp;&nbsp;&nbsp;
//                   </span>
//                 </span>
//                 <span style="font-size: 14px;">
//                   Other ad companies’ opt-outs may function differently than our
//                   opt-out.{" "}
//                 </span>
//                 <o:p></o:p>
//               </font>
//             </p>
//             <span style="font-size: 14px;"></span>
//             <p
//               class="MsoNormal"
//               style="margin-left:36.05pt;text-indent:-18.0pt;mso-list:
// l0 level1 lfo1"
//             >
//               <font face="Arial">
//                 <span
//                   style="font-size:10.0pt;line-height:
// 134%"
//                 >
//                   <span style="font-size: 14px;">•</span>
//                   <span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 14px; line-height: normal;">
//                     &nbsp;&nbsp;
//                   </span>
//                 </span>
//                 <span style="font-size: 14px;">
//                   To opt out of receiving targeted ads that are based on your
//                   behaviour across different mobile applications follow the
//                   below instructions, for iOS and Android devices:{" "}
//                 </span>
//                 <o:p></o:p>
//               </font>
//             </p>
//             <span style="font-size: 14px;"></span>
//             <p
//               class="MsoNormal"
//               style="margin-top:0cm;margin-right:0cm;margin-bottom:5.65pt;
// margin-left:72.05pt;text-indent:-18.0pt;line-height:107%;mso-list:l0 level2 lfo1"
//             >
//               <font face="Arial">
//                 <span style="font-size: 10pt; line-height: 107%;">
//                   <span style="font-size: 14px;">o</span>
//                   <span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 14px; line-height: normal;">
//                     &nbsp;&nbsp;&nbsp;
//                   </span>
//                 </span>
//                 <i>
//                   <span style="font-size: 14px;">iOS 7 or Higher:</span>
//                 </i>
//                 <span style="font-size: 14px;">
//                   {" "}
//                   Go to your Settings &gt; Select Privacy &gt; Select
//                   Advertising{" "}
//                 </span>
//                 <o:p></o:p>
//               </font>
//             </p>
//             <span style="font-size: 14px;"></span>
//             <p
//               class="MsoNormal"
//               style="margin-top:0cm;margin-right:0cm;margin-bottom:9.2pt;
// margin-left:72.55pt;line-height:107%"
//             >
//               <font face="Arial">
//                 <span style="font-size: 14px;">
//                   &gt; Enable the “Limit Ad Tracking” setting{" "}
//                 </span>
//                 <o:p></o:p>
//               </font>
//             </p>
//             <span style="font-size: 14px;"></span>
//             <p
//               class="MsoNormal"
//               style="margin-top:0cm;margin-right:0cm;margin-bottom:1.55pt;
// margin-left:72.05pt;text-indent:-18.0pt;line-height:134%;mso-list:l0 level2 lfo1"
//             >
//               <font face="Arial">
//                 <span style="font-size: 10pt; line-height: 134%;">
//                   <span style="font-size: 14px;">o</span>
//                   <span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 14px; line-height: normal;">
//                     &nbsp;&nbsp;&nbsp;
//                   </span>
//                 </span>
//                 <i>
//                   <span style="font-size: 14px;">
//                     For Android devices with OS 2.2 or higher and Google Play
//                     Services version 4.0 or higher:
//                   </span>
//                 </i>
//                 <span style="font-size: 14px;">
//                   {" "}
//                   Open your Google Settings app &gt; Ads &gt; Enable{" "}
//                 </span>
//                 <o:p></o:p>
//               </font>
//             </p>
//             <span style="font-size: 14px;"></span>
//             <p
//               class="MsoNormal"
//               style="margin-top:0cm;margin-right:0cm;margin-bottom:10.6pt;
// margin-left:72.55pt;line-height:107%"
//             >
//               <font face="Arial">
//                 <span style="font-size: 14px;">
//                   “Opt out of interest-based advertising”{" "}
//                 </span>
//                 <o:p></o:p>
//               </font>
//             </p>
//             <p
//               class="MsoNormal"
//               style="margin-top:0cm;margin-right:0cm;margin-bottom:13.5pt;
// margin-left:-.25pt"
//             >
//               <span style="font-family: Arial; text-indent: -24px; font-size: 14px;">
//                 •
//               </span>
//               <span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 14px; line-height: normal; font-family: Arial; text-indent: -24px;">
//                 &nbsp;&nbsp;
//               </span>
//               <font face="Arial">
//                 <span style="font-size: 14px;">
//                   Opting out will not prevent you from seeing ads, but those ads
//                   will likely be less relevant because they won’t be tailored to
//                   your interests. The ads might, for instance, be randomly
//                   generated or based on the web page you are visiting.{" "}
//                 </span>
//                 <o:p></o:p>
//               </font>
//             </p>
//             <span style="font-size: 14px;"></span>
//             <p
//               class="MsoNormal"
//               style="margin-top:0cm;margin-right:0cm;margin-bottom:13.5pt;
// margin-left:-.25pt"
//             >
//               <font face="Arial">
//                 <span style="font-size: 14px;">
//                   Some internet browsers allow users to send a "Do Not Track"
//                   signal to websites they visit. We do not respond to this
//                   signal at the present time.{" "}
//                 </span>
//                 <o:p></o:p>
//               </font>
//             </p>
//             <span style="font-size: 14px;"></span>
//             <p
//               class="MsoNormal"
//               style="margin-top:0cm;margin-right:0cm;margin-bottom:13.5pt;
// margin-left:-.25pt"
//             >
//               <font face="Arial">
//                 <span style="font-size: 14px;">
//                   In addition, if you are located in a European Territory you
//                   will also have additional data protection rights. These are
//                   described under the heading "Additional data protection rights
//                   for European Territory residents" below.{" "}
//                 </span>
//                 <o:p></o:p>
//               </font>
//             </p>
//             <span style="font-size: 14px;"></span>
//             <p class="MsoNormal" style="margin-left:-.25pt;line-height:107%">
//               <font face="Arial">
//                 <span style="font-size: 14px;">
//                   This policy is subject to change without notice.&nbsp;
//                 </span>
//               </font>
//               <o:p></o:p>
//             </p>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default PrivacyPolicy;

import React from "react";
import { useNavigate } from "react-router";

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  const handleHome = () => {
    navigate("/");
  };

  return (
    <>
      <div class="header-content-title">
        <div class="page-title-wrapper">
          <div class="container">
            <div class="breadcrub">
              <h1 class="page-title">Privacy Policy</h1>
              <ul class="breadcrumb">
                <li>
                  <a onClick={handleHome} style={{cursor:'pointer'}}>
                    <i class="fa fa-home"></i>
                  </a>
                </li>
                <li>
                  <a class="last-breadcrumb">Privacy Policy</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div id="information-information" class="container">
        <div class="row">
          <div id="content" class="col-sm-12 Cwidth" style={{ width: "100%" }}>
            <p
              class="MsoNormal"
              align="center"
              style={{
                marginBottom: "13.5pt",
                textAlign: "center",
                lineHeight: "25.5pt",
                backgroundImage: "initial",
                backgroundPosition: "initial",
                backgroundSize: "initial",
                backgroundRepeat: "initial",
                backgroundAttachment: "initial",
                backgroundOrigin: "initial",
                backgroundClip: "initial",
              }}
            >
              <font color="#00008a" face="Arial, sans-serif">
                <span style={{ fontSize: "24px" }}>
                  <b>Privacy Policy</b>
                </span>
              </font>
            </p>

            <p
              class="MsoNormal"
              align="center"
              style={{
                marginBottom: "27pt",
                textAlign: "center",
                lineHeight: "19.5pt",
                backgroundImage: "initial",
                backgroundPosition: "initial",
                backgroundSize: "initial",
                backgroundRepeat: "initial",
                backgroundAttachment: "initial",
                backgroundOrigin: "initial",
                backgroundClip: "initial",
              }}
            >
              <font color="#455a64" face="Arial, sans-serif">
                <span style={{ fontSize: "16px" }}>
                  We, at Restosleep, work hard to earn your trust and strive to
                  protect your privacy at all times.&nbsp;
                </span>
              </font>
            </p>
            <p
              class="MsoNormal"
              align="center"
              style={{
                marginBottom: "27pt",
                textAlign: "center",
                lineHeight: "19.5pt",
                backgroundImage: "initial",
                backgroundPosition: "initial",
                backgroundSize: "initial",
                backgroundRepeat: "initial",
                backgroundAttachment: "initial",
                backgroundOrigin: "initial",
                backgroundClip: "initial",
              }}
            >
              <font color="#00008a" face="Arial, sans-serif">
                <span style={{ fontSize: "24px" }}>Personal information</span>
              </font>
              <br />
            </p>

            <p
              class="MsoNormal"
              align="center"
              style={{
                textAlign: "start",
                marginBottom: "27pt",
                lineHeight: "19.5pt",
                backgroundImage: "initial",
                backgroundPosition: "initial",
                backgroundSize: "initial",
                backgroundRepeat: "initial",
                backgroundAttachment: "initial",
                backgroundOrigin: "initial",
                backgroundClip: "initial",
              }}
            >
              <font color="#455a64" face="Arial, sans-serif">
                <span style={{ fontSize: "16px" }}>
                  We collect personal information (Name, Email id, Phone number,
                  Address etc.,) from you during the course of transaction or at
                  the time of account creation. We use this information from
                  time to time to contact you for offers unless you explicitly
                  opt out of such offers.&nbsp;
                </span>
              </font>
              <br />
            </p>
            <p
              class="MsoNormal"
              align="center"
              style={{
                marginBottom: "27pt",
                textAlign: "center",
                lineHeight: "19.5pt",
                backgroundImage: "initial",
                backgroundPosition: "initial",
                backgroundSize: "initial",
                backgroundRepeat: "initial",
                backgroundAttachment: "initial",
                backgroundOrigin: "initial",
                backgroundClip: "initial",
              }}
            >
              <font color="#00008a" face="Arial, sans-serif">
                <span style={{ fontSize: "24px" }}>
                  Use of demographic and profile data
                </span>
              </font>
              <br />
            </p>

            <ul type="disc"></ul>
            <p
              style={{
                marginBottom: "6pt",
                lineHeight: "18pt",
                backgroundImage: "initial",
                backgroundPosition: "initial",
                backgroundSize: "initial",
                backgroundRepeat: "initial",
                backgroundAttachment: "initial",
                backgroundOrigin: "initial",
                backgroundClip: "initial",
              }}
            >
              <font color="#455a64" face="Arial, sans-serif">
                <span style={{ fontSize: "16px" }}>
                  In our efforts to continually improve our product and service
                  offerings, we collect and analyze&nbsp;demographic and profile
                  data about our users' activity on our website. We identify and
                  use your IP address to help diagnose problems with our server,
                  and to administer our website. Your IP address is also used to
                  help identify you and to gather broad demographic information.
                  We will occasionally ask you to complete optional online
                  surveys. These surveys may ask you for contact information and
                  demographic information (like pin code, age, or income level).
                  We use this data to tailor your experience at our site,
                  providing you with content that we think you might be
                  interested in--and to display content according to your
                  preferences.
                </span>
              </font>
            </p>
            <p
              class="MsoNormal"
              align="center"
              style={{
                marginBottom: "27pt",
                textAlign: "center",
                lineHeight: "19.5pt",
                backgroundImage: "initial",
                backgroundPosition: "initial",
                backgroundSize: "initial",
                backgroundRepeat: "initial",
                backgroundAttachment: "initial",
                backgroundOrigin: "initial",
                backgroundClip: "initial",
              }}
            >
              <font color="#00008a" face="Arial, sans-serif">
                <span style={{ fontSize: "24px" }}>Security precautions</span>
              </font>
              <br />
            </p>

            <p
              class="MsoNormal"
              style={{
                lineHeight: "18pt",
                backgroundImage: "initial",
                backgroundPosition: "initial",
                backgroundSize: "initial",
                backgroundRepeat: "initial",
                backgroundAttachment: "initial",
                backgroundOrigin: "initial",
                backgroundClip: "initial",
              }}
            >
              <font color="#455a64" face="Arial, sans-serif">
                <span style={{ fontSize: "16px" }}>
                  Our site has stringent security measures in place to protect
                  the loss, misuse, and alteration of the information under our
                  control. Whenever you change or access your account
                  information, we offer the use of a secure server. Once your
                  information is in our possession we adhere to strict security
                  guidelines, protecting it against unauthorized access
                </span>
              </font>
              <br />
            </p>

            <p
              class="MsoNormal"
              align="center"
              style={{
                marginBottom: "27pt",
                textAlign: "center",
                lineHeight: "19.5pt",
                backgroundImage: "initial",
                backgroundPosition: "initial",
                backgroundSize: "initial",
                backgroundRepeat: "initial",
                backgroundAttachment: "initial",
                backgroundOrigin: "initial",
                backgroundClip: "initial",
              }}
            >
              <span
                style={{
                  fontSize: "18.0pt",
                  fontFamily: "Arial,sans-serif",
                  msoFareastFontFamily: "Times New Roman",
                  color: "#00008A",
                }}
              >
                <br />
              </span>
            </p>
            <p
              class="MsoNormal"
              align="center"
              style={{
                marginBottom: "27pt",
                textAlign: "center",
                lineHeight: "19.5pt",
                backgroundImage: "initial",
                backgroundPosition: "initial",
                backgroundSize: "initial",
                backgroundRepeat: "initial",
                backgroundAttachment: "initial",
                backgroundOrigin: "initial",
                backgroundClip: "initial",
              }}
            >
              <font color="#00008a" face="Arial, sans-serif">
                <span style={{ fontSize: "24px" }}>
                  Digital advertising &amp; cookie policy&nbsp;
                </span>
              </font>
              <br />
            </p>

            <p
              class="MsoNormal"
              style={{
                lineHeight: "18pt",
                backgroundImage: "initial",
                backgroundPosition: "initial",
                backgroundSize: "initial",
                backgroundRepeat: "initial",
                backgroundAttachment: "initial",
                backgroundOrigin: "initial",
                backgroundClip: "initial",
              }}
            >
              <font color="#455a64" face="Arial, sans-serif">
                <span style={{ fontSize: "16px" }}>
                  When visitors come to our website, third parties may place
                  cookies on visitors' browsers for targeted advertising
                  purposes&nbsp;
                </span>
              </font>
            </p>
            <p
              class="MsoNormal"
              style={{
                lineHeight: "18pt",
                backgroundImage: "initial",
                backgroundPosition: "initial",
                backgroundSize: "initial",
                backgroundRepeat: "initial",
                backgroundAttachment: "initial",
                backgroundOrigin: "initial",
                backgroundClip: "initial",
              }}
            >
              <font color="#455a64" face="Arial, sans-serif">
                <span style={{ fontSize: "16px" }}>
                  We collect the following categories of information
                </span>
              </font>
            </p>
            <ol>
              <li
                style={{
                  lineHeight: "18pt",
                  backgroundImage: "initial",
                  backgroundPosition: "initial",
                  backgroundSize: "initial",
                  backgroundRepeat: "initial",
                  backgroundAttachment: "initial",
                  backgroundOrigin: "initial",
                  backgroundClip: "initial",
                }}
              >
                <font color="#455a64" face="Arial, sans-serif">
                  <span style={{ fontSize: "16px" }}>
                    Activity on Advertisers Digital Properties: This is data
                    about your browsing activity on the Advertiser's website or
                    app. For example, which pages you visited and when, what
                    items were clicked on a page, how much time was spent on a
                    page, whether you downloaded a white paper on a business to
                    business website, what items you placed into your online
                    shopping cart, what products were purchased and how much was
                    paid.&nbsp;
                  </span>
                </font>
              </li>
              <li
                style={{
                  lineHeight: "18pt",
                  backgroundImage: "initial",
                  backgroundPosition: "initial",
                  backgroundSize: "initial",
                  backgroundRepeat: "initial",
                  backgroundAttachment: "initial",
                  backgroundOrigin: "initial",
                  backgroundClip: "initial",
                }}
              >
                <font color="#455a64" face="Arial, sans-serif">
                  <span style={{ fontSize: "16px" }}>
                    Device and browser information: This is technical
                    information about the device or browser you use to access
                    the Advertiser's website. For example, your device's IP
                    address, cookie string data, operating system, and (in the
                    case of mobile devices) your device type and mobile device's
                    unique identifier such as the Apple IDFA or Android
                    Advertising ID.
                  </span>
                </font>
              </li>
              <li
                style={{
                  lineHeight: "18pt",
                  backgroundImage: "initial",
                  backgroundPosition: "initial",
                  backgroundSize: "initial",
                  backgroundRepeat: "initial",
                  backgroundAttachment: "initial",
                  backgroundOrigin: "initial",
                  backgroundClip: "initial",
                }}
              >
                <font color="#455a64" face="Arial, sans-serif">
                  <span style={{ fontSize: "16px" }}>
                    Ad data: This is data about the online ads we have served
                    (or attempted to serve) to you. It includes things like how
                    many times an ad has been served to you, what page the ad
                    appeared on, and whether you clicked on or otherwise
                    interacted with the ad.
                  </span>
                </font>
              </li>
            </ol>
            <p
              style={{
                lineHeight: "18pt",
                backgroundImage: "initial",
                backgroundPosition: "initial",
                backgroundSize: "initial",
                backgroundRepeat: "initial",
                backgroundAttachment: "initial",
                backgroundOrigin: "initial",
                backgroundClip: "initial",
              }}
            >
              <span
                style={{
                  color: "rgb(69, 90, 100)",
                  fontFamily: "Arial",
                  fontSize: "16px",
                }}
              >
                &nbsp;
              </span>
              <font face="Arial">
                <span style={{ fontSize: "14px" }}>
                  In case, you would like to opt-out, please follow the
                  directions below:
                </span>
                <br />
              </font>
            </p>
            <p
              class="MsoNormal"
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "17.1pt",
                marginLeft: "-.25pt",
                lineHeight: "107%",
              }}
            >
              <font face="Arial">{/* <o:p></o:p> */}</font>
            </p>
            <span style={{ fontSize: "14px" }}></span>
            <p
              class="MsoNormal"
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "13.55pt",
                marginLeft: "-.25pt",
              }}
            >
              <font face="Arial">
                <span style={{ fontSize: "14px" }}>
                  We recognize how important your online privacy is to you, so
                  we offer the following options for controlling the targeted
                  ads you receive and how we use your data:
                </span>
              </font>
            </p>
            <ul>
              <li style={{ margin: "0cm 0cm 13.55pt -0.25pt" }}>
                <font face="Arial" style={{ textIndent: "-18pt" }}>
                  <span style={{ fontSize: "14px" }}>
                    You can opt out of receiving personalized ads served by us
                    or on our behalf by clicking on the blue icon that typically
                    appears in the corner of the ads we serve and following the
                    instructions provided or by clicking
                  </span>
                  <a href="https://app.adroll.com/optout/safari">
                    <span
                      style={{ color: "rgb(69, 90, 100)", fontSize: "14px" }}
                    >
                      {" "}
                    </span>
                  </a>
                  <a href="https://app.adroll.com/optout/safari">
                    <b>
                      <span
                        style={{ color: "rgb(245, 0, 87)", fontSize: "14px" }}
                      >
                        here
                      </span>
                    </b>
                  </a>
                  <a href="https://app.adroll.com/optout/safari">
                    <span
                      style={{ color: "rgb(69, 90, 100)", fontSize: "14px" }}
                    >
                      .
                    </span>
                  </a>
                  <span style={{ fontSize: "14px" }}>
                    {" "}
                    Please note that this
                  </span>
                  &nbsp;
                </font>
                <span
                  style={{
                    textIndent: "18pt",
                    fontSize: "14px",
                    fontFamily: "Arial",
                    textAlign: "right",
                  }}
                >
                  “Opt out” function is browser-specific and relies on an “opt
                  out cookie”: thus, if you&nbsp;
                </span>
                <span
                  style={{
                    textIndent: -"18pt",
                    fontSize: "14px",
                    fontFamily: "Arial",
                  }}
                >
                  Delete your cookies or upgrade your browser after having opted
                  out, you will need to opt out again.
                </span>
              </li>
              <li style={{ margin: "0cm 0cm 13.55pt -0.25pt" }}>
                <font face="Arial">
                  <span style={{ fontSize: "14px" }}>
                    In some cases we may link multiple browsers or devices to
                    you. If you opt out of on a browser or device and we have
                    more linked to you, we will extend your opt out decision to
                    the other linked browsers and devices. Since we only link
                    users across browsers on devices in some conditions, there
                    could be cases where you are still being tracked in a
                    different browser or device we have not linked, and where we
                    are treating you as a different user.
                  </span>
                </font>
              </li>
              <li style={{ margin: "0cm 0cm 13.55pt -0.25pt" }}>
                <font face="Arial">
                  <span style={{ fontSize: "14px" }}>
                    We adhere to the NAI Codes of Conduct. You may use the NAI
                    opt out tool
                  </span>
                  <a
                    href="http://www.networkadvertising.org/choices/"
                    style={{
                      textIndent: -"18pt",
                      backgroundColor: "rgb(255, 255, 255)",
                    }}
                  >
                    <span
                      style={{ color: "rgb(69, 90, 100)", fontSize: "14px" }}
                    >
                      {" "}
                    </span>
                  </a>
                  <a
                    href="http://www.networkadvertising.org/choices/"
                    style={{
                      textIndent: -"18pt",
                      backgroundColor: "rgb(255, 255, 255)",
                    }}
                  >
                    <b>
                      <span
                        style={{ color: "rgb(245, 0, 87)", fontSize: "14px" }}
                      >
                        here
                      </span>
                    </b>
                  </a>
                  <a
                    href="http://www.networkadvertising.org/choices/"
                    style={{
                      textIndent: -"18pt",
                      backgroundColor: "rgb(255, 255, 255)",
                    }}
                  >
                    <span
                      style={{ color: "rgb(69, 90, 100)", fontSize: "14px" }}
                    >
                      ,
                    </span>
                  </a>
                  <span style={{ textIndent: -"18pt", fontSize: "14px" }}>
                    {" "}
                    which will allow you to opt out of seeing personalized ads
                    from us and from other NAI approved member companies.
                  </span>
                </font>
              </li>
              <li style={{ margin: "0cm 0cm 13.55pt -0.25pt" }}>
                <span style={{ textIndent: -"18pt" }}>
                  <font face="Arial">
                    <span style={{ fontSize: "14px" }}>
                      We also comply with the Self-Regulatory Principles for
                      Online Behavioral&nbsp;Advertising as managed by the
                      Digital Advertising Alliance (DAA). You may opt out of
                      receiving personalized ads from other companies that
                      perform ad targeting services, including some that we may
                      work with as Advertising Partners via the DAA website
                    </span>
                  </font>
                </span>
                <a
                  href="http://www.aboutads.info/choices/"
                  style={{
                    fontFamily: "Arial",
                    textIndent: -"18pt",
                    backgroundColor: "rgb(255, 255, 255)",
                  }}
                >
                  <span style={{ color: "rgb(69, 90, 100)", fontSize: "14px" }}>
                    {" "}
                  </span>
                </a>
                <a
                  href="http://www.aboutads.info/choices/"
                  style={{
                    fontFamily: "Arial",
                    textIndent: -"18pt",
                    backgroundColor: "rgb(255, 255, 255)",
                  }}
                >
                  <b>
                    <span
                      style={{ color: "rgb(245, 0, 87)", fontSize: "14px" }}
                    >
                      here
                    </span>
                  </b>
                </a>
                <a
                  href="http://www.aboutads.info/choices/"
                  style={{
                    fontFamily: "Arial",
                    textIndent: -"18pt",
                    backgroundColor: "rgb(255, 255, 255)",
                  }}
                >
                  <span style={{ color: "rgb(69, 90, 100)", fontSize: "14px" }}>
                    .
                  </span>
                </a>
              </li>
              <li style={{ margin: "0cm 0cm 13.55pt -0.25pt" }}>
                <font face="Arial">
                  <span style={{ fontSize: "14px" }}>
                    We also comply with the Canadian Self-regulatory Principles
                    for Online Behavioral Advertising as managed by the Digital
                    Advertising Alliance of Canada (DAAC). You may opt out of
                    receiving personalized ads from other companies that perform
                    ad targeting services, including some that we may work with
                    as Advertising Partners via the DAAC website
                  </span>
                  <a
                    href="http://youradchoices.ca/choices/"
                    style={{ backgroundColor: "rgb(255, 255, 255)" }}
                  >
                    <span
                      style={{ color: "rgb(69, 90, 100)", fontSize: "14px" }}
                    >
                      {" "}
                    </span>
                  </a>
                  <a
                    href="http://youradchoices.ca/choices/"
                    style={{ backgroundColor: "rgb(255, 255, 255)" }}
                  >
                    <b>
                      <span
                        style={{ color: "rgb(245, 0, 87)", fontSize: "14px" }}
                      >
                        here
                      </span>
                    </b>
                  </a>
                  <a
                    href="http://youradchoices.ca/choices/"
                    style={{ backgroundColor: "rgb(255, 255, 255)" }}
                  >
                    <span
                      style={{ color: "rgb(69, 90, 100)", fontSize: "14px" }}
                    >
                      .
                    </span>
                  </a>
                </font>
              </li>
              <li style={{ margin: "0cm 0cm 13.55pt -0.25pt" }}>
                <font face="Arial">
                  <span style={{ fontSize: "14px" }}>
                    We also adhere to the European Interactive Advertising
                    Digital Alliance (EDAA) guidelines for online advertising
                    and you may opt out via their
                  </span>
                  <a
                    href="http://www.youronlinechoices.com/"
                    style={{
                      textIndent: -"18pt",
                      backgroundColor: "rgb(255, 255, 255)",
                    }}
                  >
                    <span
                      style={{ color: "rgb(69, 90, 100)", fontSize: "14px" }}
                    >
                      {" "}
                    </span>
                  </a>
                  <a
                    href="http://www.youronlinechoices.com/"
                    style={{
                      textIndent: -"18pt",
                      backgroundColor: "rgb(255, 255, 255)",
                    }}
                  >
                    <b>
                      <span
                        style={{ color: "rgb(245, 0, 87)", fontSize: "14px" }}
                      >
                        Your Online{" "}
                      </span>
                    </b>
                  </a>
                  <a
                    href="http://www.youronlinechoices.com/"
                    style={{
                      textIndent: -"18pt",
                      backgroundColor: "rgb(255, 255, 255)",
                    }}
                  >
                    <b>
                      <span
                        style={{ color: "rgb(245, 0, 87)", fontSize: "14px" }}
                      >
                        Choices
                      </span>
                    </b>
                  </a>
                  <a
                    href="http://www.youronlinechoices.com/"
                    style={{
                      textIndent: -"18pt",
                      backgroundColor: "rgb(255, 255, 255)",
                    }}
                  >
                    <span
                      style={{ color: "rgb(69, 90, 100)", fontSize: "14px" }}
                    >
                      {" "}
                    </span>
                  </a>
                  <span style={{ textIndent: -"18pt", fontSize: "14px" }}>
                    website.
                  </span>
                </font>
              </li>
              <li style={{ margin: "0cm 0cm 13.55pt -0.25pt" }}>
                <font face="Arial">
                  <span style={{ fontSize: "14px" }}>
                    Please note that when using the ad industry opt-out tools
                    described above:
                  </span>
                </font>
              </li>
            </ul>
            <span style={{ fontSize: "14px" }}></span>
            <p
              class="MsoNormal"
              style={{
                marginLeft: "72.05pt",
                textIndent: "-18.0pt",
                msoList: "l0 level2 lfo1",
              }}
            >
              <font face="Arial">
                <span style={{ fontSize: "10pt", lineHeight: "134%" }}>
                  <span style={{ fontSize: "14px" }}>o</span>
                  <span
                    style={{
                      fontVariantNumeric: "normal",
                      fontVariantEastAsian: "normal",
                      fontStretch: "normal",
                      fontSize: "14px",
                      lineHeight: "normal",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span style={{ fontSize: "14px" }}>
                  If you opt-out your browser may still send us data, for
                  example your IP address. However, we isolate this data and do
                  not use it other than for accounting and, in some cases, for
                  fraud prevention. If you have opted-out on that browser, we do
                  not use this data to personalize ads or to track you.{" "}
                </span>
                <span style={{ fontSize: "14px", lineHeight: "134%" }}></span>
                <span style={{ fontSize: "14px", lineHeight: "134%" }}> </span>
                <span style={{ fontSize: "14px" }}>
                  If you use multiple browsers or devices we will additionally
                  opt out those we have linked to you. Since we may not have all
                  your browsers or
                </span>
                &nbsp;
              </font>
              <span style={{ fontSize: "14px", fontFamily: "Arial" }}>
                Devices connected back to your user, you may need to execute
                this opt out on each browser or device.
              </span>
            </p>
            <span style={{ fontSize: "14px" }}></span>
            <p
              class="MsoNormal"
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "9.6pt",
                marginLeft: "72.05pt",
                textIndent: "-18.0pt",
                lineHeight: "107%",
                msoList: "l0 level2 lfo1",
              }}
            >
              <font face="Arial">
                <span style={{ fontSize: "10pt", lineHeight: "107%" }}>
                  <span style={{ fontSize: "14px" }}>o</span>
                  <span
                    style={{
                      fontVariantNumeric: "normal",
                      fontVariantEastAsian: "normal",
                      fontStretch: "normal",
                      fontSize: "14px",
                      lineHeight: "normal",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span style={{ fontSize: "14px" }}>
                  Other ad companies’ opt-outs may function differently than our
                  opt-out.{" "}
                </span>
                {/* <o:p></o:p> */}
              </font>
            </p>
            <span style={{ fontSize: "14px" }}></span>
            <p
              class="MsoNormal"
              style={{
                marginLeft: "36.05pt",
                textIndent: "-18.0pt",
                msoList: "l0 level1 lfo1",
              }}
            >
              <font face="Arial">
                <span style={{ fontSize: "10.0pt", lineHeight: "134%" }}>
                  <span style={{ fontSize: "14px" }}>•</span>
                  <span
                    style={{
                      fontVariantNumeric: "normal",
                      fontVariantEastAsian: "normal",
                      fontStretch: "normal",
                      fontSize: "14px",
                      lineHeight: "normal",
                    }}
                  >
                    &nbsp;&nbsp;
                  </span>
                </span>
                <span style={{ fontSize: "14px" }}>
                  To opt out of receiving targeted ads that are based on your
                  behaviour across different mobile applications follow the
                  below instructions, for iOS and Android devices:{" "}
                </span>
                {/* <o:p></o:p> */}
              </font>
            </p>
            <span style={{ fontSize: "14px" }}></span>
            <p
              class="MsoNormal"
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "5.65pt",
                marginLeft: "72.05pt",
                textIndent: "-18.0pt",
                lineHeight: "107%",
                msoList: "l0 level2 lfo1",
              }}
            >
              <font face="Arial">
                <span style={{ fontSize: "10pt", lineHeight: "107%" }}>
                  <span style={{ fontSize: "14px" }}>o</span>
                  <span
                    style={{
                      fontVariantNumeric: "normal",
                      fontVariantEastAsian: "normal",
                      fontStretch: "normal",
                      fontSize: "14px",
                      lineHeight: "normal",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <i>
                  <span style={{ fontSize: "14px" }}>iOS 7 or Higher:</span>
                </i>
                <span style={{ fontSize: "14px" }}>
                  {" "}
                  Go to your Settings &gt; Select Privacy &gt; Select
                  Advertising{" "}
                </span>
                {/* <o:p></o:p> */}
              </font>
            </p>
            <span style={{ fontSize: "14px" }}></span>
            <p
              class="MsoNormal"
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "9.2pt",
                marginLeft: "72.55pt",
                lineHeight: "107%",
              }}
            >
              <font face="Arial">
                <span style={{ fontSize: "14px" }}>
                  &gt; Enable the “Limit Ad Tracking” setting{" "}
                </span>
                {/* <o:p></o:p> */}
              </font>
            </p>
            <span style={{ fontSize: "14px" }}></span>
            <p
              class="MsoNormal"
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "1.55pt",
                marginLeft: "72.05pt",
                textIndent: "-18.0pt",
                lineHeight: "134%",
                msoList: "l0 level2 lfo1",
              }}
            >
              <font face="Arial">
                <span style={{ fontSize: "10pt", lineHeight: "134%" }}>
                  <span style={{ fontSize: "14px" }}>o</span>
                  <span
                    style={{
                      fontVariantNumeric: "normal",
                      fontVariantEastAsian: "normal",
                      fontStretch: "normal",
                      fontSize: "14px",
                      lineHeight: "normal",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <i>
                  <span style={{ fontSize: "14px" }}>
                    For Android devices with OS 2.2 or higher and Google Play
                    Services version 4.0 or higher:
                  </span>
                </i>
                <span style={{ fontSize: "14px" }}>
                  {" "}
                  Open your Google Settings app &gt; Ads &gt; Enable{" "}
                </span>
                {/* <o:p></o:p> */}
              </font>
            </p>
            <span style={{ fontSize: "14px" }}></span>
            <p
              class="MsoNormal"
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "10.6pt",
                marginLeft: "72.55pt",
                lineHeight: "107%",
              }}
            >
              <font face="Arial">
                <span style={{ fontSize: "14px" }}>
                  “Opt out of interest-based advertising”{" "}
                </span>
                {/* <o:p></o:p> */}
              </font>
            </p>
            <p
              class="MsoNormal"
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "13.5pt",
                marginLeft: "-.25pt",
              }}
            >
              <span
                style={{
                  fontFamily: "Arial",
                  textIndent: "-24px",
                  fontSize: "14px",
                }}
              >
                •
              </span>
              <span
                style={{
                  fontVariantNumeric: "normal",
                  fontVariantEastAsian: "normal",
                  fontStretch: "normal",
                  fontSize: "14px",
                  lineHeight: "normal",
                  fontFamily: "Arial",
                  textIndent: "-24px",
                }}
              >
                &nbsp;&nbsp;
              </span>
              <font face="Arial">
                <span style={{ fontSize: "14px" }}>
                  Opting out will not prevent you from seeing ads, but those ads
                  will likely be less relevant because they won’t be tailored to
                  your interests. The ads might, for instance, be randomly
                  generated or based on the web page you are visiting.{" "}
                </span>
                {/* <o:p></o:p> */}
              </font>
            </p>
            <span style={{ fontSize: "14px" }}></span>
            <p
              class="MsoNormal"
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "13.5pt",
                marginLeft: "-.25pt",
              }}
            >
              <font face="Arial">
                <span style={{ fontSize: "14px" }}>
                  Some internet browsers allow users to send a "Do Not Track"
                  signal to websites they visit. We do not respond to this
                  signal at the present time.{" "}
                </span>
                {/* <o:p></o:p> */}
              </font>
            </p>
            <span style={{ fontSize: "14px" }}></span>
            <p
              class="MsoNormal"
              style={{
                marginTop: "0cm",
                marginRight: "0cm",
                marginBottom: "13.5pt",
                marginLeft: "-.25pt",
              }}
            >
              <font face="Arial">
                <span style={{ fontSize: "14px" }}>
                  In addition, if you are located in a European Territory you
                  will also have additional data protection rights. These are
                  described under the heading "Additional data protection rights
                  for European Territory residents" below.{" "}
                </span>
                {/* <o:p></o:p> */}
              </font>
            </p>
            <span style={{ fontSize: "14px" }}></span>
            <p
              class="MsoNormal"
              style={{ marginLeft: "-.25pt", lineHeight: "107%" }}
            >
              <font face="Arial">
                <span style={{ fontSize: "14px" }}>
                  This policy is subject to change without notice.&nbsp;
                </span>
              </font>
              {/* <o:p></o:p> */}
            </p>
          </div>
        </div>
      </div>
      <div id="goToTop" title="Top" class="goToTop ttbox-img" style={{display: "block"}}></div>
    </>
  );
};

export default PrivacyPolicy;
