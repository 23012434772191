import React, { useEffect, useState } from 'react'
import TitleBar from '../../components/TitleBar'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import StoresApi from '../../assets/api/StoreApis'
import { addToCart, buyNow } from '../../slice/cartSlice'
import axios, { webBaseUrl } from '../../assets/axios/axios'
import slugify from '../../utilities/urlCreatore'
import { toast } from 'react-toastify'
import { Col, Form, Row } from 'react-bootstrap'
import Draggable from 'react-draggable'
import './customize.css'
export default function CustomizeProduct() {
    const [searchParams] = useSearchParams()
    const cart = useSelector(state => state.cart.items)
    const store = useSelector(state => state.storeFront.store)
    const productId = searchParams.get("pid")

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [product, setProduct] = useState({})
    const [tab, setTab] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [quantity, setQuantity] = useState('1')
    const [imageUrl, setImageUrl] = useState('')
    const [objects, setObjects] = useState([])
    const [text, setText] = useState('')

    const getProductDetails = () => {
        axios.get(`${StoresApi.ProductsUrl}/GetProduct/${productId}/${store.id}`)
            .then(response => {
                console.log(response);
                const productDetails = response.data.data
                productDetails.inclTax = productDetails.tax > 0 ? ((productDetails.sellingPrice * productDetails.tax) / 100) + productDetails.sellingPrice : productDetails.sellingPrice
                productDetails.displayPrice = productDetails.inclTax
                setProduct(productDetails)
                // groupAttributes(productDetails.productAttributes)
                // setAttributeDetails(productDetails.productAttributes)
                // setSimilarProducts(productDetails.relatedProducts)
                // setProductOptions(productDetails.productOptions)
                // setQuantity(productDetails.minimumQuantity)
                // setProductImages(productDetails.productImages)
                setIsLoading(false)
                if (productDetails.path) {
                    setImageUrl(`${webBaseUrl}${productDetails.path}`)
                } else if (productDetails.path === null && productDetails.productImages.length > 0) {
                    setImageUrl(`${webBaseUrl}${productDetails.productImages[0].path}`)
                }
                window.history.replaceState(null, '', `/customize/${slugify(productDetails.name)}?pid=${productDetails.id}`)
            })
            .catch(error => {
                setIsLoading(false)
                setIsError(true)
                console.log(error);
            })
    }

    useEffect(() => {
        if (productId) {
            setIsLoading(true)
            getProductDetails()
        } else {
            setIsError(true)
        }
        window.scrollTo(0, 0);
    }, [productId, store])

    const [activeDrags, setActiveDrags] = useState(0)
    const [deltaPosition, setDeltaPosition] = useState({ x: 0, y: 0 })
    const [controlledPosition, setControlledPosition] = useState({ x: -400, y: 200 })

    const handleDrag = (e, ui) => {
        const { x, y } = deltaPosition;
        setDeltaPosition({
            x: x + ui.deltaX,
            y: y + ui.deltaY,
        })
    };

    const onStart = () => {
        setActiveDrags((prev) => ++prev);
    };

    const onStop = () => {
        setActiveDrags((prev) => --prev);
    };
    const onDrop = (e) => {
        setActiveDrags((prev) => --prev);
        if (e.target.classList.contains("drop-target")) {
            alert("Dropped!");
            e.target.classList.remove('hovered');
        }
    };
    const onDropAreaMouseEnter = (e) => {
        if (activeDrags) {
            e.target.classList.add('hovered');
        }
    }
    const onDropAreaMouseLeave = (e) => {
        e.target.classList.remove('hovered');
    }

    // For controlled component
    const adjustXPos = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { x, y } = controlledPosition;
        this.setState({ controlledPosition: { x: x - 10, y } });
    };

    const adjustYPos = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { controlledPosition } = this.state;
        const { x, y } = controlledPosition;
        this.setState({ controlledPosition: { x, y: y - 10 } });
    };

    const onControlledDrag = (e, position) => {
        const { x, y } = position;
        this.setState({ controlledPosition: { x, y } });
    };

    const onControlledDragStop = (e, position) => {
        this.onControlledDrag(e, position);
        this.onStop();
    };
    const dragHandlers = { onStart: onStart, onStop: onStop };

    const handleImage = (e) => {
        const File = e.target.files
        const arrValidExtensions = ['jpg', 'jpeg', 'png', 'gif'];
        const Size = Math.round(File[0].size / (1024 * 1024));// Max 2 MB
        if (File.length > 0) {
            if (!arrValidExtensions.includes(File[0].name.toLowerCase().split('.').reverse()[0])) {
                toast.error("Invalid file type.", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    hideProgressBar: true,
                    autoClose: 3000
                })
                e.target.value = null
            } else if (Size > 2) {
                toast.error("File size should be less than or equal to 2 MB.", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    hideProgressBar: true,
                    autoClose: 3000
                })
                e.target.value = null
            }
            else {
                const fileSource = URL.createObjectURL(File[0])
                const arrObjects = [...objects]
                arrObjects.push({
                    type: 1,
                    style: { width: '200px' },
                    src: fileSource,
                })
                setObjects(arrObjects)
            }
        }
    }

    const removeObject = (i) => {
        const newData = [...objects]
        newData.splice(i, 1)
        setObjects(newData)
    }

    const addText = () => {
        const arrObjects = [...objects]
        arrObjects.push({
            type: 2,
            style: {
                backgroundColor: 'transparent',
                fontSize: '4em',
                fontWeight: '500'
            },
            text: text,
        })
        setObjects(arrObjects)
        setText('')
    }
    return (
        <>
            <Row>
                <Col sm='12' md="4" lg='4' >
                    <div className='side-bar'>
                        <ul className='side-option'>
                            <li onClick={() => setTab(0)}><i className="fa fa-image fa-xl"></i></li>
                            <li onClick={() => setTab(1)}><i className="fa fa-font"></i></li>
                        </ul>
                    </div>
                    <div style={{ display: 'inline-block', width: '80%' }}>
                        {tab == 0 &&
                            (<div>
                                <div>
                                    <input type="file" name="file" id="file" class="inputfile" onChange={handleImage} />
                                    <label className='btn-custom' for="file"><i className="fa fa-upload fa-xl"></i> Choose a file</label>
                                </div>
                                <div>
                                    <Row>
                                        {objects.map((obj, i) => {
                                            if (obj.type === 1) {
                                                return (<Col sm='6' md="6" lg='6' className='text-center'>
                                                    <img style={{ width: '100px' }} src={obj.src} alt="" />
                                                    <p><i className="fa fa-trash" onClick={() => removeObject(i)}></i></p>
                                                </Col>)
                                            }
                                        })}
                                    </Row>
                                </div>
                            </div>)
                        }
                        {tab == 1 && (<div>
                            <div >
                                <label style={{ fontSize: 'medium' }}><i className="fa fa-pencil fa-xl"></i> write text</label>
                                <div className='option-text'>
                                    <input type="text" onChange={(e) => setText(e.target.value)} value={text} /><i className="fa fa-check fa-xl" onClick={addText}></i>
                                </div>
                            </div>
                            <div>
                                {objects.map((obj, i) => {
                                    if (obj.type === 2) {
                                        return (<div className='text-container'>
                                            <h3>{obj.text}</h3>
                                            <i className="fa fa-trash" onClick={() => removeObject(i)}></i>
                                        </div>)
                                    }
                                })}
                            </div>
                        </div>)}
                    </div>
                </Col>
                <Col sm='12' md='8' lg='8' className='text-center p-0'>
                    <div className="thumbnails">
                        <div className="main-image" style={{ background: `url(${imageUrl ? imageUrl : "/assets/img/brand/logo2.jpg"})` }}>
                            {/* <img className='product-image-main' src= alt="" /> */}
                            {objects.map(obj => {
                                if (obj.type == 1) {
                                    return (<Draggable bounds="parent" {...dragHandlers} defaultPosition={{ x: 50, y: 50 }}>
                                        <div className="box">
                                            <img style={{ ...obj.style }} src={obj.src} />
                                        </div>
                                    </Draggable>)
                                } else if (obj.type == 2) {
                                    return (<Draggable bounds="parent" {...dragHandlers}>
                                        <div className="box">
                                            <p style={{ ...obj.style }}>{obj.text}</p>
                                        </div>
                                    </Draggable>)
                                }
                            })}
                        </div>
                    </div>
                </Col>
                {/* <div className="box" style={{ height: '500px', width: '500px', position: 'relative', overflow: 'auto', padding: '0' }}>
                    <div style={{ height: '1000px', width: '1000px', padding: '10px' }}>
                        <Draggable bounds="parent" {...dragHandlers}>
                            <div className="box">
                                I can only be moved within my offsetParent.<br /><br />
                                Both parent padding and child margin work properly.
                            </div>
                        </Draggable>
                        <Draggable bounds="parent" {...dragHandlers}>
                            <div className="box">
                                I also can only be moved within my offsetParent.<br /><br />
                                Both parent padding and child margin work properly.
                            </div>
                        </Draggable>
                    </div>
                </div> */}
            </Row>
        </>
    )
}
