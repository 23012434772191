import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useWindowScroll } from "../../assets/useWindowsScroll";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../assets/axios/axios";
import StoresApi from "../../assets/api/StoreApis";
import slugify from "../../utilities/urlCreatore";

export default function Header() {
  const store = useSelector(state => state.storeFront.store)
  const dispatch = useDispatch()

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [categories, setCategories] = useState([])
  const [width, setWidth] = useState(window.innerWidth);

  const menuItem = useMemo(() => {
    return [
      // {
      //   label: "Home",
      //   icon: "home",
      //   path: `/home`
      // },
      // {
      //   label: 'Categories',
      //   icon: 'list',
      //   path: `/categories`,
      //   subs: categories,
      //   subs: [
      //     {
      //       label: 'Electronics',
      //       path: `/electronics`,
      //     },
      //     {
      //       label: 'Mobiles',
      //       path: `/mobiles`,
      //     },
      //     {
      //       label: 'Laptops',
      //       path: `/laptops`,
      //     },
      //   ]
      // },
      // {
      //   path: `/Stores/${storeId}/collections`,
      //   label: 'Collections',
      //   icon: 'layout-5'
      // },
      // {
      //   label: 'Pages',
      //   icon: 'content',
      //   path: `/pages`,
      // },
      // {
      //   label: "Products",
      //   icon: "list",
      //   path: `/products`
      // },
      ...categories
    ]
  }, [categories])

  const sortData = (data) => {
    data = data.filter(category => category.status)
    const parentCategories = data.filter(category => category.parentCategory === null)
    const childCategories = data.filter(category => category.parentCategory !== null)
    let reserved = [...childCategories]

    const arrParents = parentCategories.map(parent => {
      return {
        id: parent.id,
        label: parent.categoryName,
        path: `/categories/${parent.categoryName}`,
        subs: []
      }
    })

    const itemMapper = (item, index) => {
      arrParents.map((parent, ind) => {
        if (item.parentCategory.id === parent.id) {
          arrParents[ind].subs.push({
            id: item.id,
            label: item.categoryName,
            path: `/${item.categoryName}`,
            subs: []
          })

          reserved = reserved.filter(x => x.id !== item.id)
        } else if (parent.subs.length > 0) {
          parent.subs.map((sub, i) => {
            if (item.parentCategory.id === sub.id) {
              arrParents[ind].subs[i].subs.push({
                id: item.id,
                label: item.categoryName,
                path: `/${item.categoryName}`,
                subs: []
              })
              reserved = reserved.filter(x => x.id !== item.id)
            }
            return null;
          })
        }
        return null;
      })
    }

    childCategories.map(itemMapper)
    if (reserved.length > 0) {
      reserved.map(itemMapper)
    }
    return arrParents
  }

  const getCategories = async () => {
    try {
      const response = await axios.get(`${StoresApi.ProductsCategoryUrl}/GetProductCategoriesByStore/${store.id}`)
      // const parentCategories = response.data.data.filter(category => category.parentCategory === null)
      // const childCategories = response.data.data.filter(category => category.parentCategory !== null)
      // const arrParents = parentCategories.map(parent => {
      //   return {
      //     id: parent.id,
      //     label: parent.categoryName,
      //     path: `/categories/${parent.categoryName}`,
      //     subs: []
      //   }
      // })
      // childCategories.map((child, i) => {
      //   arrParents.map((parent, ind) => {
      //     if (child.parentCategory.id === parent.id) {
      //       arrParents[ind].subs.push({
      //         id: child.id,
      //         label: child.categoryName,
      //         path: `/${child.categoryName}`,
      //         subs: []
      //       })
      //     }
      //     return null;
      //   })
      //   return null;
      // })

      // const categoriesArr = response.data.data.map(catg => {
      //   return {
      //     id: catg.id,
      //     label: catg.categoryName,
      //     path: `/${catg.categoryName}`,
      //   }
      // })
      // sortData(response.data.data)
      // menuItem.push(...categoriesArr)
      const final = sortData(response.data.data)

      // final.map((category, ind) => {
      //     if (category.subs.length > 0) {
      //         category.subs.map((catg, i) => {
      //             if (catg.subs.length > 0) {
      //                 final[ind].subs[i].subs.unshift({
      //                     id: catg.id,
      //                     label: "All",
      //                     path: `/`,
      //                 })
      //             }
      //             return null;
      //         })
      //         final[ind].subs.unshift({
      //             id: final[ind].id,
      //             label: "All",
      //             path: `/`,
      //         })
      //     }
      //     return null;
      // })
      setCategories(final);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getCategories()
  }, [store])

  // const setCategoryId = (catg) => {
  //     if (catg.id) {
  //         dispatch(addCategoryId(catg.id))
  //     }
  // }


  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  const scrolled = useWindowScroll();

  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const navigate = useNavigate();

  const handleAboutUs = () => {
    navigate("/about");
  };

  const handleMattresses = () => {
    navigate("/mattresses");
  };
  const handleAccessories = () => {
    navigate("/accessories");
  };
  const handleHome = () => {
    navigate("/");
  };
  return (
    <>
      <header>
        <div className={`full-header ${scrolled ? "fixed" : ""}`}>
          <div className="container">
            <div className="header-left">
              <div id="logo">
                <a

                  onClick={handleHome}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src="/170x65px - White logo-01-01.png"
                    title="Your Store"
                    alt="Your Store"
                    className="img-responsive"
                  />
                </a>{" "}
              </div>
            </div>
            <div className="header-top-right">
              <div id="top-links" className="nav pull-right">
                <ul className="list-inline">
                  <li className="ttsearch">
                    <div id="search" className="input-group">
                      <span
                        className="ttsearch_button"
                        onClick={toggleVisibility}
                      >
                        <i className="fa fa-search"></i>
                      </span>
                      <div
                        className="ttsearchtoggle"
                        style={{ display: isVisible ? "block" : "none" }}
                      >
                        <input
                          type="text"
                          name="search"
                          value=""
                          placeholder="Search"
                          className="form-control input-lg"
                          autoFocus
                        />
                        <span className="input-group-btn">
                          <button
                            type="button"
                            className="btn btn-default btn-lg"
                          >
                            <i className="fa fa-search"></i>
                          </button>
                        </span>
                      </div>
                    </div>
                  </li>
                  <li className="account-nav dropdown header_user_info">
                    <Link
                      to="#"
                      title="Account"
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      onClick={() => setShowAccountMenu(!showAccountMenu)}
                    >
                      <i className="far fa-user"></i>
                      <span className="ttuserheading">Account</span>
                      <i className="fa fa-caret-down"></i>
                    </Link>
                    <ul
                      className="dropdown-menu dropdown-menu-right account-link-toggle"
                      style={{ display: showAccountMenu ? "block" : "none" }}
                    >
                      <li>
                        <Link to="/register">
                          <i className="fa fa-user"></i> Register
                        </Link>
                      </li>
                      <li>
                        <Link to="/login">
                          <i className="fa fa-sign-in"></i> Login
                        </Link>
                      </li>
                      <li>
                        <a
                          href="https://opencart.templatetrip.com/OPC04/OPC096_styleloft/OPC01/index.php?route=account/wishlist"
                          id="wishlist-total"
                          title="Wish List (0)"
                        >
                          <i className="fa fa-heart"></i>{" "}
                          <span className="hidden-sm hidden-md">
                            Wish List (0)
                          </span>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="ttcart">
                    <div id="cart" className="btn-group">
                      <button
                        type="button"
                        data-toggle="dropdown"
                        data-loading-text="Loading..."
                        className="btn btn-inverse btn-block btn-lg dropdown-toggle"
                      >
                        <i className="fa fa-shopping-cart"></i>
                        <span id="cart-total">0</span>
                      </button>
                      <ul className="dropdown-menu pull-right header-cart-toggle">
                        <li>
                          <p className="text-center">
                            Your shopping cart is empty!
                          </p>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="header-top-left">
              <div className="header-left-cms">
                <aside id="header-left">
                  <div
                    className="main-category-list left-main-menu"
                    style={{ display: "inline-block" }}
                  >
                    <div className="cat-menu">
                      <div className="TT-panel-heading">
                        <span>menu</span>
                      </div>

                      <div class="header-top-left">
                        <div class="header-left-cms">
                          <aside id="header-left">
                            <div
                              class="main-category-list left-main-menu"
                              style={{ display: "inline-block" }}
                            >
                              <div class="cat-menu">
                                {/* <div class="TT-panel-heading">
                                <span>menu</span>
                              </div> */}
                                <div class="menu-category">
                                  <ul class="dropmenu">
                                    {categories.map(catg => {
                                      if (catg.subs.length > 0) {
                                        return <li className="TT-Sub-List"> <Link to={`/categories/${slugify(catg.label)}?cid=${catg.id}`}>{catg.label}</Link>
                                          <ul className="main-dropdown-menu">
                                            {catg.subs.map(subCatg => {
                                              return <li> <Link to={`/categories/${slugify(subCatg.label)}?cid=${subCatg.id}`}>{subCatg.label}</Link> </li>
                                            })}
                                          </ul>
                                        </li>
                                      }

                                      return <li className="TT-Sub-List"> <Link to={`/categories/${slugify(catg.label)}?cid=${catg.id}`}>{catg.label}</Link></li>
                                    })}
                                    {/* <li class="TT-Sub-List">
                                      <a

                                        onClick={handleMattresses}
                                      >
                                        Mattresses
                                      </a>
                                    </li>
                                    <li class="TT-Sub-List">
                                      <a
                                        onClick={handleAccessories}
                                      // href="https://restosleep.in/index.php?route=product/category&amp;path=67"
                                      >
                                        Accessories
                                      </a>
                                    </li> */}
                                    <li class="TT-Sub-List">
                                      <a
                                        // href="https://restosleep.in/index.php?route=product/category&amp;path=128"
                                        onClick={handleAboutUs}
                                      >
                                        about us
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </aside>
                        </div>
                      </div>
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </header>


      {/* <header>
        <div class="full-header">
          <div class="header-left">
            <div id="logo">
              <a onClick={handleHome} style={{ cursor: "pointer" }}>
                <img
                  src="/170x65px - White logo-01-01.png"
                  title="Rest O sleep"
                  alt="Rest O sleep"
                  class="img-responsive"
                />
              </a>{" "}
            </div>
          </div>

          <div class="header-top-right">
            <div id="top-links" class="nav pull-right">
              <ul class="list-inline">
                <li class="ttsearch">
                  <div id="search" class="input-group">
                    <span class="ttsearch_button">
                      <i class="fa fa-search"></i>
                    </span>
                    <div class="ttsearchtoggle">
                      <input
                        type="text"
                        name="search"
                        value=""
                        placeholder="Search"
                        class="form-control input-lg"
                      />
                      <span class="input-group-btn">
                        <button type="button" class="btn btn-default btn-lg">
                          <i class="fa fa-search"></i>
                        </button>
                      </span>
                    </div>
                  </div>
                </li>
                <li class="account-nav dropdown header_user_info">
                  <a
                    href="https://restosleep.in/index.php?route=account/account"
                    title="Account"
                    class="dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    <i class="far fa-user"></i>
                    <span class="ttuserheading">Account</span>
                    <i class="fa fa-caret-down"></i>
                  </a>

                  <ul class="dropmenu">
                    <li class="TT-Sub-List">
                      <a
                      
                        onClick={handleMattresses}
                      >
                        Mattresses
                      </a>
                    </li>
                    <li class="TT-Sub-List">
                      <a
                        onClick={handleAccessories}
                        
                      >
                        Accessories
                      </a>
                    </li>
                    <li class="TT-Sub-List">
                      <a
                       
                        onClick={handleAboutUs}
                      >
                        about us
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="ttcart">
                  <div id="cart" class="btn-group">
                    <button
                      type="button"
                      data-toggle="dropdown"
                      data-loading-text="Loading..."
                      class="btn btn-inverse btn-block btn-lg dropdown-toggle"
                    >
                      <i class="fa fa-shopping-cart"></i>
                      <span id="cart-total">0</span>
                    </button>
                    <ul class="dropdown-menu pull-right header-cart-toggle">
                      {" "}
                      <li>
                        <p class="text-center">Your shopping cart is empty!</p>
                      </li>{" "}
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="header-top-left">
            {" "}
            <div class="header-left-cms">
              <aside id="header-left">
                <div
                  class="main-category-list left-main-menu"
                  style={{ display: "inline-block" }}
                >
                  <div class="cat-menu TTactive">
                    <div class="TT-panel-heading">
                      <span>menu</span>
                    </div>
                    <div class="menu-category">
                      <ul class="dropmenu" style={{ display: "block" }}>
                        {" "}
                        <li class="TT-Sub-List">
                          <a href="https://restosleep.in/index.php?route=product/category&amp;path=66">
                            Mattresses
                          </a>{" "}
                        </li>{" "}
                        <li class="TT-Sub-List">
                          <a href="https://restosleep.in/index.php?route=product/category&amp;path=67">
                            Accessories
                          </a>{" "}
                        </li>{" "}
                        <li class="TT-Sub-List">
                          <a href="https://restosleep.in/index.php?route=product/category&amp;path=128">
                            about us
                          </a>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </aside>
            </div>{" "}
          </div>
        </div>
      </header> */}
    </>
  );
}
