import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import ProductBlock from "../components/ProductBlock";
import TitleBar from "../components/TitleBar";
import StoresApi from "../assets/api/StoreApis";
import { useSelector } from "react-redux";
import axios from "../assets/axios/axios";

export default function CategoryProducts() {
  const [searchParams] = useSearchParams()
  // const cart = useSelector(state => state.cart.items)
  const store = useSelector(state => state.storeFront.store)
  const categoryId = searchParams.get("cid")

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [products, setProducts] = useState([])
  const [viewMode, setViewMode] = useState(0)

  const getCategory = () => {
    axios.get(`${StoresApi.ProductsCategoryUrl}/GetProductCategory/${categoryId}`)
      .then(response => {
        console.log(response.data.data);
      })
      .catch(error => {
        console.log(error);
      })
  }

  const getData = () => {
    axios.get(`${StoresApi.PriceListUrl}/getCategoryWiseProducts/${store.id}/${categoryId}`)
      .then(response => {
        const activeProducts = response.data.data.filter(product => product.status)
        setProducts(activeProducts)
        // dispatch(addProducts(response.data.data))
      })
      .catch(error => {
        console.log(error.response);
        setIsError(true)
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    setIsLoading(true)
    // if (categoryId) {
    //     getCategory()
    // }
    getData()
  }, [categoryId, store])

  return (
    <>
      <TitleBar />
      <div id="product-category" className="container product-category">
        <div className="content_wrap">
          <div
            id="content"
            className="col-sm-12 Cwidth"
            style={{ width: "100%" }}
          >
            <div className="category-description">
              <div className="row"></div>
            </div>
            <div className="category-filter">
              <div className="col-md-2 filter-grid-list">
                <div className="btn-group">
                  <button
                    type="button"
                    id="grid-view"
                    className="btn btn-default active"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Grid"
                  >
                    <i className="fa fa-th"></i>
                  </button>
                  <button
                    type="button"
                    id="list-view"
                    className="btn btn-default"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="List"
                  >
                    <i className="fa fa-th-list"></i>
                  </button>
                </div>
              </div>
              <div className="filter-show">
                <div className="col-md-4 text-right filter-text">
                  <label
                    className="input-group-addon control-label"
                    for="input-limit"
                  >
                    Show:
                  </label>
                </div>
                <div className="col-md-8 text-right filter-selection">
                  <div className="select-wrapper">
                    <select
                      id="input-limit"
                      className="form-control"
                      onchange="location = this.value;"
                    >
                      <option
                        value="https://restosleep.in/index.php?route=product/category&amp;path=66&amp;limit=15"
                        selected="selected"
                      >
                        15
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=66&amp;limit=25">
                        25
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=66&amp;limit=50">
                        50
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=66&amp;limit=75">
                        75
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=66&amp;limit=100">
                        100
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="filter-sort-by">
                <div className="col-md-3 text-right filter-text">
                  <label
                    className="input-group-addon control-label"
                    for="input-sort"
                  >
                    Sort By:
                  </label>
                </div>
                <div className="col-md-9 text-right filter-selection">
                  <div className="select-wrapper">
                    <select
                      id="input-sort"
                      className="form-control"
                      onchange="location = this.value;"
                    >
                      <option
                        value="https://restosleep.in/index.php?route=product/category&amp;path=66&amp;sort=p.sort_order&amp;order=ASC"
                        selected="selected"
                      >
                        Default
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=66&amp;sort=pd.name&amp;order=ASC">
                        Name (A - Z)
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=66&amp;sort=pd.name&amp;order=DESC">
                        Name (Z - A)
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=66&amp;sort=p.price&amp;order=ASC">
                        Price (Low &gt; High)
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=66&amp;sort=p.price&amp;order=DESC">
                        Price (High &gt; Low)
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=66&amp;sort=rating&amp;order=DESC">
                        Rating (Highest)
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=66&amp;sort=rating&amp;order=ASC">
                        Rating (Lowest)
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=66&amp;sort=p.model&amp;order=ASC">
                        Model (A - Z)
                      </option>

                      <option value="https://restosleep.in/index.php?route=product/category&amp;path=66&amp;sort=p.model&amp;order=DESC">
                        Model (Z - A)
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="category-products">
              <div className="row">
                {/* <ProductBlock /> */}
                {products.map(prod => {
                  return (
                    <ProductBlock product={prod} />
                  )
                })}
              </div>
            </div>
            <div className="category-pagination">
              <div className="col-xs-6 text-left">
                Showing 1 to {products.length} of {products.length} (1 Pages)
              </div>
              <div className="col-xs-6 text-right"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
