import React from 'react'
import { Link } from 'react-router-dom'
import TitleBar from '../../components/TitleBar'

export default function Orders() {
    return (
        <>
            <TitleBar />
            <div id="account-order" class="container">

                <div class="row">
                    <div id="content" class="col-sm-12 Cwidth" style={{ width: "100%" }}>

                        <div class="table-responsive">
                            <table class="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <td class="text-right">Order ID</td>
                                        <td class="text-left">Customer</td>
                                        <td class="text-right">No. of Products</td>
                                        <td class="text-left">Status</td>
                                        <td class="text-right">Total</td>
                                        <td class="text-left">Date Added</td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-right">#1</td>
                                        <td class="text-left">abdulla musabuddi</td>
                                        <td class="text-right">1</td>
                                        <td class="text-left">Processed</td>
                                        <td class="text-right">Rs 7,262.00</td>
                                        <td class="text-left">18/10/2022</td>
                                        <td class="text-right"><Link to="/orderDetails" data-toggle="tooltip" title="" class="btn btn-info" data-original-title="View"><i class="fa fa-eye"></i></Link></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="row">
                            <div class="col-sm-6 text-left"></div>
                            <div class="col-sm-6 text-right">Showing 1 to 1 of 1 (1 Pages)</div>
                        </div>
                        <div class="buttons clearfix">
                            <div class="pull-right"><a href="https://restosleep.in/index.php?route=account/account" class="btn btn-primary">Continue</a></div>
                        </div>
                        <div class="panel panel-default account-list">
                            <div class="panel-heading">Account</div>
                            <div class="list-group">
                                <a href="https://restosleep.in/index.php?route=account/account" class="list-group-item">My Account</a>
                                <a href="https://restosleep.in/index.php?route=account/edit" class="list-group-item">Edit Account</a> <a href="https://restosleep.in/index.php?route=account/password" class="list-group-item">Password</a>
                                <a href="https://restosleep.in/index.php?route=account/address" class="list-group-item">Address Book</a> <a href="https://restosleep.in/index.php?route=account/wishlist" class="list-group-item">Wish List</a> <a href="https://restosleep.in/index.php?route=account/order" class="list-group-item">Order History</a> <a href="https://restosleep.in/index.php?route=account/download" class="list-group-item">Downloads</a><a href="https://restosleep.in/index.php?route=account/recurring" class="list-group-item">Recurring payments</a> <a href="https://restosleep.in/index.php?route=account/reward" class="list-group-item">Reward Points</a> <a href="https://restosleep.in/index.php?route=account/return" class="list-group-item">Returns</a> <a href="https://restosleep.in/index.php?route=account/transaction" class="list-group-item">Transactions</a> <a href="https://restosleep.in/index.php?route=account/newsletter" class="list-group-item">Newsletter</a>
                                <a href="https://restosleep.in/index.php?route=account/logout" class="list-group-item">Logout</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
